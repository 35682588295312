export const formatDate = (e: number) => {
  try {
    return new Date(+e).toLocaleString()
  } catch (error) {
    console.log({ e, error })
    return ""
  }
}

export const formatTime = (e: number) => {
  try {
    return new Date(+e).toTimeString().slice(0, 8) + "." + (e % 1000 / 10 | 0)
  } catch (error) {
    console.log({ e, error })
    return ""
  }
}

export const prettyNumber = (e: any, a = 0, hasSign = false) : string=> {
  const sign = e < 0 ? '-' : (hasSign ? '+' : '')
  e = Math.abs(e);
  const s = +e * (10 ** a)
  if (!isFinite(s))
    return '----'
  if (s >= 1e9) {
    return `${sign}${(s / 1e9).toFixed(2)}b`
  } else if (s >= 1e8) {
    return `${sign}${(s / 1e6).toFixed(0)}.m`
  } else if (s >= 1e7) {
    return `${sign}${(s / 1e6).toFixed(1)}m`
  } else if (s >= 1e6) {
    return `${sign}${(s / 1e6).toFixed(2)}m`
  } else if (s >= 100000) {
    return `${sign}${(s / 1e3).toFixed(0)}.k`
  } else if (s >= 10000) {
    return `${sign}${(s / 1e3).toFixed(1)}k`
  } else if (s >= 1000) {
    return `${sign}${(s / 1e3).toFixed(2)}k`
  } else if (s >= 100) {
    return `${sign}${s.toFixed(1)}`
  } else if (s >= 10) {
    return `${sign}${s.toFixed(2)}`
  } else if (s >= 0.1) {
    return `${sign}${s.toFixed(3)}`
  } else if(s >0){
    return `${sign}${s.toPrecision(3)}`
  } else {
    return `${sign}0.000`
  }
}