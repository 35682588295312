import { InputOutlined } from "@mui/icons-material"
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, FormHelperText, MenuItem, Paper, Select, Stack, TextField, Typography, useEventCallback } from "@mui/material"
import SelectInput from "@mui/material/Select/SelectInput"
import { ChangeEvent, FormEvent, memo, useCallback, useDebugValue, useEffect, useMemo, useState } from "react"
import ReactJson from 'react-json-view'
import { memoize } from "lodash"
import { useLoading } from "../../hook/useLoading"
import { LoadingButton } from "@mui/lab"
import { APIKeyCreate } from "../../apis/keys"
import { ExchangeSelect } from "../ExchangeSelect"
import { BotTypeSelect } from "../BotTypeSelect"
import { APIKeySelect } from "./APIKeySelect"
import { APIBOTCreate, APIBOTUpdate } from "../../apis/bots"
import { CalcHelperButton, CalcHelperButtonV2 } from "../botdashboard/BotActions"
import { APIExchangeBalance } from "../../apis/exchange"
import { useAsync, useMeasure } from "react-use"
import { calc } from "../botdashboard/CalcHelper"



export const getDefaultBotConfig = (type: string) => {
  return {
    "MM": {
      minPrice: 6000,
      maxPrice: 12000,
      maxAsset: 13400,
      maxFund: 113600000,
      maxFundDay: 113600000 / 5,
      intervalSecond: 5,
      minGapPercent: 2,
      sellSize: 3,
      buySize: 3,
    },
    "MM_FU_CLASSIC": {
      minPrice: 6000,
      maxPrice: 12000,
      maxAsset: 13400,
      maxFund: 113600000,
      maxFundDay: 113600000 / 5,
      intervalSecond: 5,
      minGapPercent: 2,
      sellSize: 3,
      buySize: 3,
      initPosition: 0,
      initPrice: 0,
      side: "BOTH",
    },
  }[type] ?? {}
}

export const BotConfigForm: React.FC<{ value: any, onChange: (e: any) => void }> = memo(
  ({ value, onChange }) => {

    console.log("BotConfigForm render")

    const onChangeFactory = useMemo(
      () => memoize(field => (newValue: any) => onChange((state: any) => ({ ...state, [field]: newValue }))),
      []
    )
    const eventWrapper = useMemo(
      () => memoize(cb => (e: ChangeEvent<HTMLInputElement>) => cb(e.target.value)),
      []
    )

    const JSONEnable = useCallback(
      ({ updated_src }: any) => (onChangeFactory('config')(updated_src), true),
      []
    )

    const showConfig = useMemo(() => ({
      ...getDefaultBotConfig(value?.type) ?? {},
      ...value?.config ?? {},
    }), [value?.config, value?.type])

    const { value: balances, error } = useAsync(
      () => value?.id
        ? APIExchangeBalance({ botId: value?.id }, true)()
        : (Promise.resolve([])),
      [value?.id]
    );

    const baseSymbol = useMemo(
      () => value?.pair?.trim()?.toUpperCase()?.split("_")?.[0] ?? "",
      [value?.pair]
    )

    const assetBalance = useMemo(
      () => balances?.find(e => baseSymbol == e.currency)?.total,
      [balances, baseSymbol]
    )

    const preState = useMemo(
      () => {
        const { kConstant } = calc({ min: showConfig?.minPrice, max: showConfig?.maxPrice, asset: showConfig?.maxAsset, });
        return ({
          prices: [
            showConfig?.minPrice,
            kConstant / ((Math.sqrt(kConstant / showConfig?.maxPrice) + +assetBalance) ** 2),
            showConfig?.maxPrice
          ],
          maxHoldAsset: [showConfig?.maxAsset],
          maxHoldFund: [showConfig?.maxFund],
          currentAsset: [""],
          currentVND: [""],
        })
      },
      [assetBalance, showConfig?.minPrice, showConfig?.maxPrice, showConfig?.maxAsset, showConfig?.maxFund]
    )

    const jsonConfig = useMemo(
      () => JSON.parse(JSON.stringify(showConfig)),
      [JSON.stringify(showConfig)]
    )
    // useEffect(() => console.log({ jsonConfig }), [jsonConfig])

    return <>
      <BotTypeSelect
        sx={{ flex: 1, minWidth: "8em" }} label="Type" value={value?.type ?? " "} onChange={eventWrapper(onChangeFactory("type"))} />
      <ExchangeSelect
        sx={{ flex: 1, minWidth: "8em" }} label="Exchange" value={value?.exchange ?? " "} onChange={eventWrapper(onChangeFactory("exchange"))} />
      <TextField sx={{ flex: 1, minWidth: "8em" }} label="Pair" value={value?.pair ?? " "} onChange={eventWrapper(onChangeFactory("pair"))} />
      <TextField sx={{ flex: 1, minWidth: "8em" }} label="Id" value={value?.id ?? " "} onChange={eventWrapper(onChangeFactory("id"))} />
      <APIKeySelect
        sx={{ flex: 1, minWidth: "8em" }} label="API Key"
        value={value?.account} onChange={onChangeFactory("account")}
      />

      {/* <TextField sx={{ width: "12em" }} label="subAccount" value={value?.subAccount ?? " "} onChange={eventWrapper(onChangeFactory("subAccount"))} /> */}
      <TextField select sx={{ width: "8em" }} label="Prod" value={value?.prod ?? false} onChange={eventWrapper(value => onChangeFactory("prod")(value == "true"))} >
        <MenuItem value="false">Testing</MenuItem>
        <MenuItem value="true">Prod</MenuItem>
      </TextField>
      <Stack direction="row" gap={1.5}>
        <CalcHelperButton preState={preState} onApply={onChangeFactory('config')}>Calc</CalcHelperButton>
        <CalcHelperButtonV2 preConfig={showConfig} onApply={onChangeFactory('config')}>Calc V2</CalcHelperButtonV2>
      </Stack>
      <Paper style={{ width: "100%", padding: "0.7em", backgroundColor: "rgba(0, 0, 0, 0.07)" }} elevation={1} key="json-config">
        <ReactJson
          src={jsonConfig}
          onEdit={JSONEnable} onDelete={JSONEnable} onAdd={JSONEnable}
          style={{ fontSize: "1em" }}
          displayDataTypes={false} quotesOnKeys={false}
          // name="CONFIG"
          enableClipboard={false}
        />
      </Paper>
    </>
  }
)

export const BotCreateDialog: React.FC<{ open: boolean, onClose: any, reload: any }> = ({ onClose, open, reload }) => {
  const [data, setData] = useState()

  const [onSubmit, { error, loading }] = useLoading(useEventCallback(async (e: FormEvent) => {
    e.preventDefault()
    e.stopPropagation()
    await APIBOTCreate(data as any)
    reload?.()
    onClose?.()
  }))

  return <Dialog open={open} onClose={onClose} fullWidth>
    <DialogTitle>New Bot</DialogTitle>
    <DialogContent>
      <br />
      <Stack direction="row" gap={2} flexWrap="wrap">
        <BotConfigForm value={data} onChange={setData} />
      </Stack>
      {error && <FormHelperText error>{error}</FormHelperText>}
    </DialogContent>
    <DialogActions>
      <Button onClick={onClose}>Cancel</Button>
      <LoadingButton loading={loading} onClick={onSubmit}>Submit</LoadingButton>
    </DialogActions>
  </Dialog>
}


export const BotUpdateDialog: React.FC<{ open: boolean, onClose: any, reload: any, preData }> = ({ onClose, open, reload, preData }) => {
  const [data, setData] = useState(() => preData)

  // useDebugValue({
  //   onClose, open, reload, preData
  // })

  useEffect(
    () => {
      console.log("UPDATE DATA")
      setData(preData)
    },
    [JSON.stringify(preData)]
  );

  const [onSubmit, { error, loading }] = useLoading(useEventCallback(async (e: FormEvent) => {
    e.preventDefault()
    e.stopPropagation()
    await APIBOTUpdate(data as any)
    reload?.()
    onClose?.()
  }))

  return <Dialog open={open} onClose={onClose} fullWidth>
    <DialogTitle>Bot [{preData?.id}]</DialogTitle>
    <DialogContent>
      <br />
      <Stack direction="row" gap={2} flexWrap="wrap">
        <BotConfigForm value={data} onChange={setData} />
      </Stack>
      {error && <FormHelperText error>{error}</FormHelperText>}
    </DialogContent>
    <DialogActions>
      <Button onClick={onClose}>Cancel</Button>
      <LoadingButton loading={loading} onClick={onSubmit}>Submit</LoadingButton>
    </DialogActions>
  </Dialog>
}