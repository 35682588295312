
declare global {
  interface Array<T> {
    sortBy(e: (f: T) => number): Array<T>;
    sortBy(e: (f: T) => number): Array<T>;
    sum(e: (f: T) => number): number;
    sum<T extends number>(): number;
    toObjectBy<E extends string | number>(e: (f: T) => E): Record<E, T>;
    groupBy<E extends string | number>(e: (f: T) => E): Record<E, T[]>;
    groupBy<E extends string | number, U>(e: (f: T) => E, e1?: (f: T) => U): Record<E, U[]>;
    head(): T | null;
    tail(): T | null;
    findLast(fn: (e:T) => boolean): T | undefined,
    unique(): Array<T>;
    toReversed(): Array<T>;
    uniqueBy(e: (f: T) => any): Array<T>;
  }
}

Object.defineProperty(Array.prototype, 'sortBy', {
  enumerable: false,
  value: function <T>(func: (f: T) => number) {
    return this
      .map((e, i) => ([func(e), i]))
      .sort((e, f) => e[0] - f[0])
      .map(e => this[e[1]])
  }
});

Object.defineProperty(Array.prototype, 'sum', {
  enumerable: false,
  value: function <T>(func?: (f: T) => number) {
    if (func)
      return this.map(func).reduce((e, f) => e + f, 0)
    return this.reduce((e, f) => e + f, 0)
  }
});

Object.defineProperty(Array.prototype, 'toObjectBy', {
  enumerable: false,
  value: function <T, E extends string | number>(func?: (f: T) => E): Record<E, T> {
    let obj: Record<E, T> = <any>{}
    for (let ob of this)
      obj[func(ob)] = ob
    return obj
  }
});

Object.defineProperty(Array.prototype, 'groupBy', {
  enumerable: false,
  value: function <T, E extends string | number>(
    func?: (f: T) => E,
    func2?: any,
  ): Record<E, T[]> {
    let obj: Record<E, T[]> = <any>{}
    for (let ob of this) {
      let key = func(ob);
      (obj[key] || (obj[key] = [])).push(func2 ? func2(ob) : ob)
    }
    return obj
  }
});


Object.defineProperty(Array.prototype, 'tail', {
  enumerable: false,
  value: function <T>(): T {
    return this[this.length - 1]
  }
});

Object.defineProperty(Array.prototype, 'head', {
  enumerable: false,
  value: function <T>(): T {
    return this[0]
  }
});

Object.defineProperty(Array.prototype, 'unique', {
  enumerable: false,
  value: function <T>(this: Array<T>): Array<T> {
    return [...new Set(this)]
  }
});


Object.defineProperty(Array.prototype, 'uniqueBy', {
  enumerable: false,
  value: function <T>(this: Array<T>, func?: (f: T) => any): Array<T> {
    let s = new Set()
    let newArr: Array<T> = []
    for (let e of this) {
      let k = func(e)
      if (!s.has(k)) {
        newArr.push(e)
        s.add(k)
      }
    }

    return newArr

  }
});


export { }
