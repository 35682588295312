import { useAsync } from "react-use";
import { CircularProgress, FormHelperText, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, useTheme } from "@mui/material";
import { APIExchangeOrderhistory } from "../../apis/exchange";
import { formatDate, prettyNumber } from "../../format/date";
import { useMemo } from "react";
import { useAsyncWithCached } from "../../hook/useAsyncWithCached";
import { StatusPanel } from "./StatusPanel";
import { RelativeTime } from "../shared/RelativeTime";
import "../../@polyfill/Array.ts"


const join = (...e: any[]) => <code>
  {e.map((f, i) => <>{i > 0 ? "/" : ""}{f}</>)}
</code>

const colors = (e) => <>
  <span style={{ color: +e > 0 ? "green" : (+e < 0 ? "red" : "gray") }}>{prettyNumber(e, 0, true)}</span>
</>

export const summaryList = (e: any[]) => {
  const allBUY = e.filter(e => e.side == "BUY")
  const allSELL = e.filter(e => e.side == "SELL")
  const TOTAL_BUY = allBUY.map(e => e.executed).reduce((e, f) => e + f, 0)
  const TOTAL_SELL = allSELL.map(e => e.executed).reduce((e, f) => e + f, 0)
  const TOTAL_BUY_VND = allBUY.map(e => e.executed * e.price).reduce((e, f) => e + f, 0)
  const TOTAL_SELL_VND = allSELL.map(e => e.executed * e.price).reduce((e, f) => e + f, 0)
  return {
    "BUY / SELL": [TOTAL_BUY, TOTAL_SELL].map(e => prettyNumber(e)).join(" / "),
    "BUY / SELL (CASH)": [TOTAL_BUY_VND, TOTAL_SELL_VND].map(e => prettyNumber(e)).join(" / "),
    "CHG / VOL": join(colors(TOTAL_BUY - TOTAL_SELL), prettyNumber(TOTAL_BUY + TOTAL_SELL)),
    "CHG / VOL (CASH)": join(colors(TOTAL_SELL_VND - TOTAL_BUY_VND), prettyNumber(TOTAL_SELL_VND + TOTAL_BUY_VND)),
  }
}

export const useBotSummaryState = (
  { exchange, account, token, pair }: { exchange: string, account: string, token: any, pair: string },
  groupByFunc = e => new Date(+e?.timestamp ?? 0).toLocaleDateString()
) => {

  const { value: history, error, loading, loadingCache } = useAsyncWithCached(
    (cached) => APIExchangeOrderhistory({ exchange, account, filter: { pair, status: ["FILLED", "PARTIAL_FILL", "OPEN"] } }, cached)(),
    [exchange, account, token, pair]
  );
  const filteredHistory = useMemo(
    () => history
      ?.filter(e => e.pair == pair && e.executed > 0)
      ?.filter(e => ["FILLED", "PARTIAL_FILL", "OPEN"].includes(e.status))
      ?.sortBy(e => e.timestamp ?? 0),
    [history, pair]
  )

  const grouppedData = useMemo(
    () => Object
      .entries((filteredHistory as any[])?.groupBy(groupByFunc) || []),
    [filteredHistory]
  )

  const summaryData = useMemo(
    () => [
      ...grouppedData,
      ["ALL", filteredHistory ?? []],
    ].map(([date, trades]) => ({
      date,
      ...summaryList(trades as any[]),
    })),
    [filteredHistory, grouppedData]
  )

  return { filteredHistory, summaryData, loading, loadingCache, error }
}

export const BotSummary: React.FC<{ account: string, exchange: string; token: any; reload: any; pair: string }> = ({ account, exchange, token, pair }) => {

  const { summaryData, loading, loadingCache, error } = useBotSummaryState({ account, exchange, token, pair })

  const fields = useMemo(
    () => Object.keys(summaryData?.[0] ?? {}),
    [summaryData?.[0]?.[1]]
  )

  return <>
    <TableContainer style={{ height: "100%", overflow: "auto", maxWidth: "100%", paddingBottom: "2em" }} >
      <Table  >
        <TableHead color="white" sx={{ whiteSpace: "nowrap" }}>
          {fields.map(f => <TableCell align="center">{f}</TableCell>)}
        </TableHead>
        <TableBody>
          {summaryData.map(data => <TableRow key={data?.date as string}>
            {fields.map(f => <TableCell align="center">{data?.[f]}</TableCell>)}
          </TableRow>)}
        </TableBody>
      </Table>
      <StatusPanel show={loading || loadingCache}>
        <CircularProgress size="0.9em" sx={{ px: "0.5em" }} />
        Fetching {loadingCache ? ' cached ' : ' latest '} data ...
      </StatusPanel>
    </TableContainer>
    {error && <FormHelperText error>{String(error)}</FormHelperText>}
  </>;
};


