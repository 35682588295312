import "../../@polyfill/Number"


const processStackFactory = (fee = 0.0005) => {
  let currentPos = 0
  const stack: { amount; price; }[] = [];

  return ({ amount, price }, debug = false) => {
    let left = -amount * price
    let cost = 0

    while (currentPos * amount < 0 && stack.length && Math.abs(currentPos) > 0.00001 && Math.abs(amount) > 0.00001) {
      let tail = stack.tail()
      let match = Math.abs(tail.amount) < Math.abs(amount) ? -tail.amount : amount
      debug && console.log(" <<<<  POP", { left, match, price, tailPrice: tail.price })
      amount -= match
      tail.amount += match
      currentPos += match
      left += match * tail.price
      cost += match * tail.price

      debug && console.log(" <<<<  POP tail", tail)

      if (Math.abs(tail.amount) <= 0.00001) {
        stack.pop()
      }
    }

    currentPos = currentPos.roundBy(8)
    amount = amount.roundBy(8)

    if (currentPos * amount >= 0) {

      stack.push({ amount, price });
      currentPos += amount
      left += amount * price
      cost += amount * price
      amount = 0
      return { currentPos, cost, pl: left.roundBy(8) }
    }

    return { currentPos, cost, pl: left.roundBy(8) }
  }
}


export function calcPL(filteredHistory: { timestamp: any, executed: number, amount: number, fill_avg: number, side: string, pair: string, price: string, status: string, id: string, }[], fee = 0.0005) {
  let tokenIn = 0, tokenOut = 0, cashIn = 0, cashOut = 0, cumPL = 0, cumCost = 0
  let maxLost = 0, maxProfit = 0, maxLong = 0, maxShort = 0
  let currentPrice = 0

  let getState = () => {

    let tmpPL = (tokenIn - tokenOut) * currentPrice - cumCost

    return {
      tokenIn, tokenOut, cashIn, cashOut, cumPL,
      cumToken: tokenIn - tokenOut,
      cumCash: cashIn - cashOut,
      cumCost,
      avgPrice: cumCost / (tokenIn - tokenOut),
      tmpPl: tmpPL,
      maxLong: maxLong = Math.max(tokenIn - tokenOut, maxLong),
      maxShort: maxShort = Math.max(tokenOut - tokenIn, maxShort),
      maxLost: maxLost = Math.min(tmpPL, maxLost),
      maxProfit: maxProfit = Math.max(tmpPL, maxProfit),
      plStat: cashIn - cashOut + (tokenIn - tokenOut) * currentPrice,
    }
  }
  let processStack = processStackFactory(fee)




  const long = ({ executed: amount, fill_avg: price }) => {
    let { cost, pl } = processStack({ amount, price })
    tokenIn += amount
    cashOut += price * amount * (1 + fee)
    cumPL += pl;
    cumCost += cost
    currentPrice = price
    return { ...getState(), pl }
  };

  const short = ({ executed: amount, fill_avg: price }) => {
    let { pl, cost } = processStack({ amount: -amount, price })
    tokenOut += amount
    cashIn += amount * price * (1 - fee)
    cumPL += pl;
    cumCost += cost
    currentPrice = price
    return { ...getState(), pl }
  };

  return filteredHistory
    ?.sortBy(e => e.timestamp)
    ?.map(e => ({
      ...e,
      ...e.side == "BUY" ? long(e) : short(e),
    })) ?? [];
}


