import HmacSHA256 from 'crypto-js/hmac-sha256';
import Base16 from 'crypto-js/enc-hex';
import { auth } from '../firebase';
import { APILogin } from './accounts';

let deltaTime = 0;
let rs, rj;
let promise: Promise<any> = new Promise((resolve, reject) => (rs = resolve, rj = reject));

setTimeout(async () => {
  try {
    const loginToken: any = JSON.parse(localStorage.getItem("token"))
    console.log({ loginToken });
  
    if (loginToken?.timestamp ?? 0 >= Date.now() + 3600000 * 8) {
      rs(loginToken);
    } else {
      
    }
  } catch (error) {
    
  }
})


auth.onAuthStateChanged(async (user) => {
  try {
    if (user?.email) {
      const token = await auth.currentUser.getIdToken(true);

      console.log({ user, email: user?.email });

      const loginResult = await APILogin({ firebaseToken: token, email: user?.email });

      console.log({ loginResult });

      rs(loginResult.token);

      localStorage.setItem("token", JSON.stringify(loginResult.token))

    } else {
      rj?.("Loged out")
      localStorage.setItem("token", JSON.stringify({}))
      promise = new Promise((resolve, reject) => (rs = resolve, rj = reject));
    }
  } catch (error) {
    rj(error);
  }

});


export const fetch: typeof window.fetch = async (input, init = {}) => {
  try {
    const token = await promise;

    let body = init?.method == "POST"
      ? init.body
      : (new URL(String(input))).search.slice(1);
    let url = new URL(String(input)).pathname;
    let nonce = Date.now() + deltaTime;
    let signData = `${body || ''}_${nonce}_${url}`;
    return await window.fetch(input, {
      ...init ?? {},
      headers: {
        ...init?.headers ?? {},
        nonce: String(Date.now()),
        token: token.id,
        signature: Base16.stringify(HmacSHA256(
          signData,
          token.private
        )),
      }
    });

  } catch (error) {
    console.log({ input, error })
    console.error(error)
    throw error
  }
};
