import { ListItemText, MenuItem, TextField, TextFieldProps, Typography } from "@mui/material"
import { useAsync } from "react-use"
import { APIKeyList } from "../../apis/keys"


export const APIKeySelect: React.FC<TextFieldProps & { value: string, onChange: (e: any) => void }> = ({ onChange, value, ...props }) => {
  const { value: list } = useAsync(
    () => APIKeyList(),
    []
  )
  return <TextField select {...props} value={value} onChange={e => onChange(e.target.value)}>
    {list?.map((e: any) => <MenuItem value={e.id} id={e.id}>[{e.exchange}]{e.id}</MenuItem>)}
  </TextField>
}