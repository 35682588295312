
// export const BASE_URL = "http://localhost:8082"
export const BASE_URL = "https://fbot-api.fdata.finance"

export const RESP = (e: any) => e
  .json()
  .then((e: any) => {
    if (e.status == "ok" || e.status == "success")
      return e.data ?? e
    else {
      console.error(new Error(e.message ?? e.msg ?? e))
      throw new Error(e.message ?? e.msg)
    }
  })
  

export const fetchConfig: RequestInit = {
  headers: {
    'Content-Type': 'application/json',
  },
  credentials: "omit",
  mode: "cors",
  cache: 'no-store',
}



