import { FormLabel, Stack } from "@mui/material";
import { memoize } from "lodash";
import { useMemo, useState } from "react";
import { BothTextAndSlider, calc, roundBy } from "./CalcHelper";

export const FormBlock: React.FC<ReturnType<typeof useCalcState>> = ({ computedState, onChangeFactory }) => {
  return <Stack gap={1} width="100%">
    {/* <Stack direction="row">
      <pre>{YAML.stringify(computedState)}</pre>
      <pre>{YAML.stringify(computedState)}</pre>
    </Stack> */}
    <div>
      <FormLabel className="title-hr" sx={{ my: 1 }}>Prices</FormLabel>
      <BothTextAndSlider
        step={25} min={1000} max={20000}
        values={computedState.prices}
        onChanges={onChangeFactory("prices")}
        titles={["Min Price", "Current", "Max Price"]} />
    </div>
    <div>
      <FormLabel className="title-hr" sx={{ my: 1 }}>Spends (at current price)</FormLabel>
      <Stack gap={1} direction="column" sx={{ my: 1 }}>
        <BothTextAndSlider gap={1} flex={1} titles={["Asset (stock)"]}
          values={computedState.currentAsset}
          onChanges={onChangeFactory("currentAsset")}
          step={100} min={100} max={300000} />
        <BothTextAndSlider gap={1} flex={1} titles={["Fund (vnd)"]}
          values={computedState.currentVND}
          onChanges={onChangeFactory("currentVND")}
          step={10000} min={100000} max={200000000} />
      </Stack>
    </div>
    <div>
      <FormLabel className="title-hr" sx={{ my: 1 }}>Max Holdings</FormLabel>
      <Stack gap={1} direction="column">
        <BothTextAndSlider gap={1} flex={1} titles={["Total Stock (at min price)"]}
          values={computedState.maxHoldAsset} onChanges={onChangeFactory("maxHoldAsset")}
          min={100} max={500000} step={100} />
        <BothTextAndSlider gap={1} flex={1} titles={["Total Fund (at max price)"]}
          values={computedState.maxHoldFund} onChanges={onChangeFactory("maxHoldFund")}
          min={100000} max={5000000000} step={100000} />
      </Stack>
    </div>

  </Stack>;
};
export function useCalcState(prestate = undefined, { minAmount = 100, priceStep = 100 } = undefined) {
  const fundStep = minAmount * priceStep
  const [state, setState] = useState(() => ({
    prices: ["1000", "3000", "6000"],
    currentAsset: ["20000"],
    currentVND: ["10000000"],
    maxHoldAsset: [""],
    maxHoldFund: [""],
    ...(prestate ?? {}) as {},
    // ...prestate ?? {},
  }));

  const [lastEdit, setLastEdit] = useState(
    () => Object.entries(prestate ?? {})
      .filter(([k, v]: [k: string, v: string[]]) => v.every(e => +e > 0))
      .map(([k, v]) => k) as string[]
  );

  const computedState: typeof state = useMemo(
    () => {
      const [min, current, max] = state.prices.map(e => +e);
      const [l1 = "", l2 = "", l3 = ""] = [...lastEdit].reverse();

      let kConstant = 0;
      let data = {
        l: { l1, l2, l3 }
      } as any;

      if (false
        || ["currentAsset", "currentVND"].includes(l1)
        || (["currentAsset", "currentVND"].includes(l2) && l1 == "prices")) {
        if (l1 == "currentAsset" && l2 == "currentVND") {
          const [asset] = state.currentAsset.map(e => +e);
          const [fund] = state.currentVND.map(e => +e);
          kConstant = calc({ min: current, max: max, asset: asset }).kConstant;
          const calcMinPrice = ((Math.sqrt(kConstant * current) - fund) ** 2) / kConstant;
          data.prices = [roundBy(calcMinPrice, priceStep), current, max];
        } else if (l2 == "currentAsset" && l1 == "currentVND") {
          const [asset] = state.currentAsset.map(e => +e);
          const [fund] = state.currentVND.map(e => +e);
          kConstant = calc({ min: min, max: current, fund }).kConstant;
          const calcMaxPrice = kConstant / ((Math.sqrt(kConstant / current) - asset) ** 2);
          data.prices = [min, current, roundBy(calcMaxPrice, priceStep)];
        } else if (l1 == "currentAsset" || (l1 == "prices" && l2 == "currentAsset")) {
          const [asset] = state.currentAsset.map(e => +e);

          kConstant = calc({ min: current, max: max, asset: asset }).kConstant;
          const calcFund = Math.sqrt(kConstant * current) - Math.sqrt(kConstant * min);
          data.currentVND = [String(roundBy(calcFund, fundStep))];
        } else if (l1 == "currentVND" || (l1 == "prices" && l2 == "currentVND")) {
          const [fund] = state.currentVND.map(e => +e);
          kConstant = calc({ min: min, max: current, fund: fund }).kConstant;
          const calcAsset = Math.sqrt(kConstant / current) - Math.sqrt(kConstant / max);
          data.currentAsset = [String(roundBy(calcAsset, minAmount))];
        }

        const maxHoldFund = Math.sqrt(kConstant * max) - Math.sqrt(kConstant * min);
        const maxHoldAsset = Math.sqrt(kConstant / min) - Math.sqrt(kConstant / max);

        data.maxHoldFund = [String(roundBy(maxHoldFund, fundStep))];
        data.maxHoldAsset = [String(roundBy(maxHoldAsset, minAmount))];

      } else if (false
        || ["maxHoldAsset", "maxHoldFund"].includes(l1)
        || (["maxHoldAsset", "maxHoldFund"].includes(l2) && l1 == "prices")) {
        if (l1 == "maxHoldAsset" || (l1 == "prices" && l2 == "maxHoldAsset")) {
          const [asset] = state.maxHoldAsset.map(e => +e);
          kConstant = calc({ min, max, asset: asset }).kConstant;
          const calcFund = Math.sqrt(kConstant * max) - Math.sqrt(kConstant * min);
          data.maxHoldFund = [String(roundBy(calcFund, fundStep))];
        } else if (l1 == "maxHoldFund" || (l1 == "prices" && l2 == "maxHoldFund")) {
          const [fund] = state.maxHoldFund.map(e => +e);

          kConstant = calc({ min: min, max: max, fund: fund }).kConstant;
          const calcAsset = Math.sqrt(kConstant / min) - Math.sqrt(kConstant / max);
          data.maxHoldAsset = [String(roundBy(calcAsset, minAmount))];
        }

        const currentVND = Math.sqrt(kConstant * current) - Math.sqrt(kConstant * min);
        const currentAsset = Math.sqrt(kConstant / current) - Math.sqrt(kConstant / max);

        data.currentVND = [String(roundBy(currentVND, fundStep))];
        data.currentAsset = [String(roundBy(currentAsset, minAmount))];

      }


      return {
        ...state,
        ...data,
      };
    },
    [state, lastEdit, minAmount, priceStep]
  );

  const onChangeFactory = useMemo(
    () => memoize((field: string) => (cb) => {
      setState(state => ({ ...state, [field]: cb(state[field]) }));
      setLastEdit(lastEdit => lastEdit.at(-1) != field ? [...lastEdit, field].slice(-5) : lastEdit);
    }),
    []
  );

  return { computedState, onChangeFactory };
}
