
export const APICaching = <T extends (...args: any[]) => any>(fn: T, ttl = 200): T => {
  const cache = new Map<string, any>();
  return ((...args: any[]) => {
    const key = JSON.stringify(args);
    console.log(key);
    const cached = cache.get(key);
    if (cached && cached.time > Date.now() - ttl) {
      return cached.data;
    }
    let result = fn(...args);
    cache.set(key, { time: Date.now(), data: result });
    return result;
  }) as T;
};

export const APIWrapperFactory = <U extends any[], V, T>(
  fn: (...args: U) => Promise<V>,
  key: (...args: U) => string,
  combine: (...args: U[]) => U,
  split: (params: U, results: V) => T,
  ttl = 1000
) => {
  const map = new Map<string, { param: U; rs; rj; }[]>();
  const timeoutMap = {};
  const run = (k: string) => {
    const runInfos = map.get(k);
    return fn(...combine(...runInfos.map(e => e.param)))
      .then(results => runInfos.forEach(({ param, rj, rs }) => {
        rs(split(param, results));
      }))
      .catch((error) => runInfos.forEach(({ rj, param }) => rj(error, { param })));
  };

  const runWithTTL = (k: string) => {
    clearTimeout(timeoutMap[k]);
    timeoutMap[k] = setTimeout(() => run(k), ttl);
  };

  return ((...arg: U) => {
    const k = key(...arg);
    console.count(k);
    if (!map.get(k))
      map.set(k, []);
    return new Promise((rs, rj) => {
      map.get(k).push({ param: arg, rs, rj });
      runWithTTL(k);
    });
  }) as typeof fn;
};
