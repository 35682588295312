import { Box, Stack } from "@mui/material";
import React, { useEffect, useMemo } from "react";
import { APIExchangeBalance } from "../../apis/exchange";
import { useAsyncWithCached } from "../../hook/useAsyncWithCached";
import { calc } from "../botdashboard/CalcHelper";
import { prettyNumber } from "../../format/date";
import { useAsync } from "react-use";
import { APIMarketPrice } from "../../apis/market";
import { revertMath } from "../../utils";


export const BotQuickStatus: React.FC<{ data: any; reload: any; status: any }> = ({ data, reload, status }) => {
  return <>
    {["MM", "MM_VASSET", "MM_FU_VASSET"].includes(data?.type) && <BotQuickStatusMMBase {...{ data, reload, status }} />}
    {["MM_FU_CLASSIC"].includes(data?.type) && <BotQuickStatusMMWithInit {...{ data, reload, status }} />}
  </>
};


export const BotQuickStatusMMBase: React.FC<{ data: any; reload: any; status: any }> = ({ data, reload, status }) => {

  const [asset = '', counter = ''] = data?.pair?.trim()?.split("_") || [];

  const { value: balances, error, loading, loadingCache } = useAsyncWithCached(
    (cached) => APIExchangeBalance({ exchange: data?.exchange, account: data?.account }, cached)(),
    [data?.id]
  );

  const vAssets = useMemo(
    () => status?.vAsset,
    [status?.vAsset]
  )


  const assetBalance = useMemo(
    () => (balances as any[])?.find((e: any) => e.currency == asset)?.total ?? undefined,
    [asset, balances]
  );

  const calcResult = useMemo(
    () => calc({ min: data?.config?.minPrice, max: data?.config?.maxPrice, asset: data?.config?.maxAsset }),
    [data?.config]
  );

  const calcAssets = +(vAssets ?? 0) + +assetBalance

  const minPrecision = Math.max(String(+data?.config?.minPrice ?? "0").length, String(+data?.config?.maxPrice ?? "0").length) + 1

  const middlePrice = useMemo(
    () => +(+calcResult?.kConstant / (Math.sqrt(calcResult?.kConstant / data?.config?.maxPrice) + (calcAssets ?? 0)) ** 2).toPrecision(minPrecision),
    [calcResult?.kConstant, data?.config, calcAssets]
  );

  const peakVassetPrice = useMemo(
    () => +(+calcResult?.kConstant / (Math.sqrt(calcResult?.kConstant / data?.config?.maxPrice) + (vAssets ?? 0)) ** 2).toPrecision(minPrecision),
    [calcResult?.kConstant, data?.config, vAssets]
  );

  const p = (100 * (middlePrice - data?.config?.minPrice) / (data?.config?.maxPrice - data?.config?.minPrice)).rangeBy(0, 100);
  const peak = (100 * (peakVassetPrice - data?.config?.minPrice) / (data?.config?.maxPrice - data?.config?.minPrice)).rangeBy(0, 100);

  return <Box sx={{ flex: 1, fontSize: "0.9em", margin: { md: "-0.8em 0", sx: "" }, whiteSpace: "nowrap", "small": { fontSize: "0.8em" } }}>
    <Stack direction="row" justifyContent="space-between">
      <small style={{ flex: 1, overflow: "hidden", textAlign: "left" }}>
        <span style={{ opacity: 0.5 }}>
          {vAssets !== undefined ? <>Vasset + </> : <></>}
          Asset / Max
        </span>
        <br />
        {vAssets !== undefined ? <>{prettyNumber(vAssets)} + </> : <></>}
        {prettyNumber(assetBalance)} / {prettyNumber(data?.config?.maxAsset)}
      </small>
      <small style={{ flex: 1, overflow: "hidden", textAlign: "right" }}>
        <span style={{ opacity: 0.5 }}>Min / Mid / Max Price</span>
        <br />
        {data?.config?.minPrice} / {middlePrice} /{data?.config?.maxPrice}
      </small>
    </Stack>
    <div style={{ position: "relative", width: "100%", height: "4px", marginTop: "2px" }}>
      <small style={{ position: "absolute", backgroundColor: "#0f0a", height: "100%", width: p + "%" }} />
      <small style={{ position: "absolute", backgroundColor: "#f00a", height: "100%", left: p + "%", width: (100 - p) + "%", }} />
      {vAssets !== undefined && <small style={{ position: "absolute", backgroundColor: "#888", height: "calc(150%)", top: "-25%", left: `calc(${peak}% - 1.5px)`, width: "3px" }} />}
    </div>
  </Box>;
};

export const BotQuickStatusMMWithInit: React.FC<{ data: any; reload: any; status: any }> = ({ data, reload, status }) => {

  const exchange = data?.exchange
  const pair = data?.pair?.trim()
  const [asset = '', counter = ''] = pair?.split("_") || [];


  const { value: balances, error, loading, loadingCache } = useAsyncWithCached(
    (cached) => APIExchangeBalance({ exchange: data?.exchange, account: data?.account }, cached)(),
    [data?.id]
  );

  const assetBalance = useMemo(
    () => (balances as any[])?.find((e: any) => e.currency == asset)?.total ?? undefined,
    [asset, balances]
  );

  const quoteBalancee = useMemo(
    () => (balances as any[])?.find((e: any) => e.currency == counter)?.total ?? undefined,
    [counter, balances]
  );

  const { value: marketData } = useAsync(() => APIMarketPrice({ exchange, pair })(), [exchange, pair]);

  const { kConstant, } = useMemo(
    () => calc({ min: data?.config?.minPrice, max: data?.config?.maxPrice, asset: data?.config?.maxAsset }),
    [data?.config]
  );

  const [minLiqPrice, initPrice, maxLiqPrice] = useMemo(
    () => {
      const maxLost = quoteBalancee * 0.7
      const initPrice = data?.config?.initPrice
      const initPosition = data?.config?.initPosition

      const lostCalc = (currentPrice: number) => {
        let deltaCash = Math.sqrt(kConstant * currentPrice) - Math.sqrt(kConstant * initPrice)
        let deltaAmount = Math.sqrt(kConstant / currentPrice) - Math.sqrt(kConstant / initPrice)
        return deltaAmount * currentPrice + deltaCash + (initPosition) * (currentPrice - initPrice)
      }

      const rev = revertMath(lostCalc)
      return [
        rev(-maxLost, data?.config?.initPrice / 2),
        data?.config?.initPrice,
        rev(-maxLost, data?.config?.initPrice * 2), ,
      ]

    }, [kConstant, quoteBalancee, data?.config?.initPrice]
  )

  const pos = 100 * (marketData?.currentPrice - minLiqPrice) / (maxLiqPrice - minLiqPrice)

  return <Box sx={{ flex: 1, fontSize: "0.9em", margin: { md: "-0.8em 0", sx: "" }, whiteSpace: "nowrap", "small": { fontSize: "0.8em" } }}>
    <Stack direction="row" justifyContent="space-between">
      <small style={{ flex: 1, overflow: "hidden", textAlign: "left" }}>
        <span style={{ opacity: 0.5 }}>
          Position / Init
        </span>
        <br />
        {prettyNumber(assetBalance)} / {prettyNumber(data?.config?.initPosition)}
      </small>
      <small style={{ flex: 1, overflow: "hidden", textAlign: "right" }}>
        <span style={{ opacity: 0.5 }}>Min Liq / Current / Max Liquid</span>
        <br />
        {minLiqPrice.toFixed(4)} / {Number(marketData?.currentPrice).toFixed(4)} / {maxLiqPrice.toFixed(4)}
      </small>
    </Stack>
    <div style={{ position: "relative", width: "100%", height: "4px", marginTop: "2px" }}>
      <small style={{ position: "absolute", background: "linear-gradient(to right, #f00a 5%,#0f0a,#0f0a,#0f0a,#f00a 95%)", height: "100%", width: "100%" }} />
      <small style={{ position: "absolute", backgroundColor: "#888", height: "calc(150%)", top: "-25%", left: `calc(${pos}% - 1.5px)`, width: "3px" }} />
    </div>
  </Box>;
};
