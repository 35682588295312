

export const StatusPanel: React.FC<{ show; children: any }> = ({ show, children }) => {
  return <div style={{
    position: "fixed", right: "0,8em", bottom: "0.8em", width: "calc(100% - 1.6em)", background: "linear-gradient(to bottom, #fff0,#fffa, #fffd, #ffff, #ffff)",
    paddingTop: "1em",
    pointerEvents: "none",
    fontSize: "0.9em",
    transition: "transform 0.5s",
    transform: show ? "translateY(0%)" : "translateY(100%)",
    textAlign: "right",
    fontStyle: "italic",
  }}>
    <div style={{ opacity: 0.6 }}>
      {children}
    </div>
  </div>;
};
