export const revertMath = (f: (e: number) => number) => {
  let m = 0.00001
  return (v: number, x = 0) => {
    let count = 0
    while (count++ < 400 && isFinite(x)) {
      let testV = f(x)
      let testDV = (f(x + m) - f(x)) / m
      if (Math.abs(testV - v) < 1e-5)
        break
      x += (v - testV) / testDV
    }
    console.log({count, x})
    return x
  }
}
