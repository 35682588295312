import { useEffect, useState } from "react";
import { Socket, io } from "socket.io-client";
import { BASE_URL } from "../apis/base";




const client = io(BASE_URL)

export const useWsClient = () => {
  // const [ioClient, setClient] = useState<Socket | undefined>(undefined);


  // useEffect(() => {
  //   let client = io(BASE_URL)
  //   setClient(client)
  // }, []);

  return client

}