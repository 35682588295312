import { BASE_URL, RESP, fetchConfig } from "./base"
import { fetch } from './fetch'

export const APIBOTList = () => Promise
  .resolve()
  .then(() => fetch(`${BASE_URL}/bot/list`, {
    ...fetchConfig
  }))
  .then(RESP)

export const APIBOTGet = (id: string) => Promise
  .resolve()
  .then(() => fetch(`${BASE_URL}/bot/get?id=${encodeURIComponent(id)}`, {
    ...fetchConfig
  }))
  .then(RESP)


export const APIBOTGetStatus = (id: string) => Promise
  .resolve()
  .then(() => fetch(`${BASE_URL}/bot/status?id=${encodeURIComponent(id)}`, {
    ...fetchConfig
  }))
  .then(RESP)


export const APIBOTAllStatus = () => Promise
  .resolve()
  .then(() => fetch(`${BASE_URL}/bot/allStatus`, {
    ...fetchConfig
  }))
  .then(RESP)


export const APIBOTCreate = (data: { id: string, exchange: string, data: any }) => Promise
  .resolve()
  .then(_ => fetch(`${BASE_URL}/bot/create`, {
    method: "POST",
    body: JSON.stringify(data),
    ...fetchConfig,
  }))
  .then(RESP)



export const APIBOTUpdate = (data: { id: string, [k: string]: any }) => Promise
  .resolve()
  .then(_ => fetch(`${BASE_URL}/bot/update`, {
    method: "POST",
    body: JSON.stringify(data),
    ...fetchConfig,
  }))
  .then(RESP)

export const APIBOTRemove = (id: string) => Promise
  .resolve()
  .then(_ => fetch(`${BASE_URL}/bot/remove`, {
    method: "POST",
    body: JSON.stringify({ id }),
    ...fetchConfig,
  }))
  .then(RESP)
