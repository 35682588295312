import React, { useCallback, useEffect, useState } from 'react';
import 'firebase/app'
import 'firebase/auth'
import { FirebaseError, initializeApp } from 'firebase/app';
import { getAuth, GoogleAuthProvider, onAuthStateChanged, signInWithPopup } from "firebase/auth";
import { memoize } from 'lodash';
import { Button, ButtonProps, List, ListItem, ListItemButton, ListItemIcon, ListItemText, MenuItem, Popover } from '@mui/material';
import LogoutIcon from '@mui/icons-material/Logout';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { APILogin } from './apis/accounts';


const firebaseConfig = {
  apiKey: "AIzaSyDR5ROZ4HS87_9pcd8rBV-jAuIUhKLu24U",
  authDomain: "datvo-244000.firebaseapp.com",
  databaseURL: "https://datvo-244000.firebaseio.com",
  projectId: "datvo-244000",
  storageBucket: "datvo-244000.appspot.com",
  messagingSenderId: "297782004819",
  appId: "1:297782004819:web:0e70b90f6aeae77fe70aa6"
};


const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const provider = new GoogleAuthProvider();
auth.languageCode = 'en';


export { auth }


export const AuthButton = ({ children, ...props }: ButtonProps) => {
  const [signedEmail, setSignedEmail] = useState(""); // Local signed-in state.
  const [isInited, setIsInited] = useState(false); // Local signed-in state.

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      setAnchorEl(anchorEl ? null : event.currentTarget);
    },
    [anchorEl]
  );

  useEffect(() => {
    setIsInited(true)
  }, [])

  const googleSignin = useCallback(
    async () => {
      try {
        const result = await signInWithPopup(auth, provider)



      } catch (error) {
        if (error instanceof FirebaseError) {
          const errorCode = error.code;
          const errorMessage = error.message;
          const email = error.customData.email;
          const credential = GoogleAuthProvider.credentialFromError(error);
          console.log({ errorCode, errorMessage, email, credential, })
        } else {
          console.error(error)
        }
        throw error
      }
    },
    []
  )

  useEffect(() => {
    const unregisterAuthObserver = isInited
      ? auth.onAuthStateChanged(async user => {

        if (user?.email) {
          await auth.currentUser.getIdToken(true)
          setSignedEmail(user?.email?.split("@")?.[0] ?? "");
        } else {
          setSignedEmail("");
        }

      })
      : undefined
    return () => unregisterAuthObserver?.(); // Make sure we un-register Firebase observers when the component unmounts.
  }, [auth, isInited]);

  return signedEmail
    ? <>
      <Button {...props} onClick={handleClick} >
        {signedEmail || children}
      </Button>
      <Popover
        open={!!anchorEl}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right', }}
        transformOrigin={{ vertical: 'top', horizontal: 'right', }}
        anchorEl={anchorEl} style={{ zIndex: "100", borderRadius: "8px" }}
        onClose={() => setAnchorEl(null)}>
        <MenuItem style={{ width: "15em", pointerEvents: "none" }}>
          <AccountCircleIcon style={{ marginRight: "0.5em" }} />
          {signedEmail}
        </MenuItem>
        <MenuItem style={{ width: "15em" }} onClick={() => auth?.signOut?.()}>
          <LogoutIcon style={{ marginRight: "0.5em" }} />
          Log out
        </MenuItem>
      </Popover>
    </>
    : (isInited ? <Button  {...props} onClick={googleSignin}>Google Signin</Button> : <></>)
}