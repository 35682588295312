import { memoize } from "lodash";
import React, { useMemo } from "react";



export function useSortWithState<T extends string>(
  [sort, setSort]: [[T, -1 | 1][], React.Dispatch<React.SetStateAction<[T, -1 | 1][]>>, any?]
) {

  const handleSortFactory = useMemo(
    () => memoize(
      (key: string) => () => setSort((sort) => {
        let previousSort = sort.find(e => e[0] === key)?.[1] ?? 0;
        let nextSort = previousSort == 0 ? -1 : (previousSort == -1 ? 1 : 0);
        return nextSort
          ? [[key, nextSort]] as any
          : [];
      })),
    []
  );

  const sortMap = useMemo(() => Object.fromEntries(sort), [sort])

  const sortedFunc = useMemo(() => sortFunctionFactory(sort), [sort]);

  return { sort, sortMap, handleSortFactory, sortedFunc };
}

export function sortFunctionFactory(sortBy: [string, 1 | -1][]) {

  if (sortBy
    .every(e => !e[0].includes('__')
      && !["constructor", "toString"].includes(e[0])
    )) {
    let runStr = `
    let c1, c2;
    (e,f) => {
      ${sortBy.map(([field, direction]) => `
      c1 = e?.${field.split(".").map(e => +e[0] > 0 ? `['${e}']` : e).join("?.")} || ${direction < 0 ? '-Infinity' : 'Infinity'};
      c2 = f?.${field.split(".").map(e => +e[0] > 0 ? `['${e}']` : e).join("?.")} || ${direction < 0 ? '-Infinity' : 'Infinity'};
      if(c1 != c2) 
        return (c1 < c2 ? - ${+direction} : ${+direction});
      `).join("\n")}
      return 0;
    }`;

    return eval(runStr);
  } else {
    return ((e: any, f: any) => 0) as any;
  }

}
