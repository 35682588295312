import { APICaching, APIWrapperFactory } from "./APICaching"
import { BASE_URL, RESP, fetchConfig } from "./base"
import { fetch } from './fetch'


type IDParams = { botId: string, filter?: any } | { exchange: string, account: string, filter?: any }

export const APIExchangeBalance = APICaching(
  (idf: IDParams, cached = false) => APICaching(
    () => Promise
      .resolve()
      .then(_ => fetch(`${BASE_URL}/exchange/balance`, {
        method: "POST",
        body: JSON.stringify({ ...idf, cached }),
        ...fetchConfig,
      }))
      .then(RESP) as Promise<any[]>
  ),
  10000
)


export const APIExchangeOpenorder = (idf: IDParams, cached = false) => () => Promise
  .resolve()
  .then(_ => fetch(`${BASE_URL}/exchange/openorder`, {
    method: "POST",
    body: JSON.stringify({ ...idf, cached }),
    ...fetchConfig,
  }))
  .then(RESP)


const _APIExchangeOrderhistory = APIWrapperFactory(
  APICaching(
    (idf: IDParams, cached) => Promise
      .resolve()
      .then(_ => fetch(`${BASE_URL}/exchange/orderhistory`, {
        method: "POST",
        body: JSON.stringify({ ...idf, cached: cached ?? false }),
        ...fetchConfig,
      }))
      .then(RESP) as Promise<any[]>
  ),
  ({ filter, ...rest }: IDParams, cached = false) => JSON.stringify({ ...rest, filter: { ...filter, pair: undefined }, cached }),
  ([{ filter: { pair, ...filter }, ...rest }, cached], ...restParam) => [
    { ...rest, filter: { pair: [...new Set([pair, ...restParam.map(e => e[0].filter?.pair)].flat())], ...filter, } },
    cached
  ] as any,
  ([{ filter: { pair } }, cached], results) => pair instanceof Array
    ? results.filter((e: any) => pair.includes(e.pair)).uniqueBy(e => e.id)
    : results.filter((e: any) => e.pair == pair).uniqueBy(e => e.id)
)

export const APIExchangeOrderhistory = (idf: IDParams, cached = false) => () => _APIExchangeOrderhistory(idf, cached)

export const APIExchangeAuthorize = (idf: IDParams) => (authData: any) => Promise
  .resolve()
  .then(_ => fetch(`${BASE_URL}/exchange/authorize`, {
    method: "POST",
    body: JSON.stringify({ ...idf, authData }),
    ...fetchConfig,
  }))
  .then(RESP)

export const APIExchangeGetOTP = (idf: IDParams) => () => Promise
  .resolve()
  .then(_ => fetch(`${BASE_URL}/exchange/sendOTP`, {
    method: "POST",
    body: JSON.stringify({ ...idf }),
    ...fetchConfig,
  }))
  .then(RESP)

export const APIExchangePlaceorder = (idf: IDParams) => (order: { price: number, amount: number, side: "BUY" | "SELL", pair: string, }) => Promise
  .resolve()
  .then(_ => fetch(`${BASE_URL}/exchange/placeorder`, {
    method: "POST",
    body: JSON.stringify({ ...idf, order }),
    ...fetchConfig,
  }))
  .then(RESP)

export const APIExchangeCancelorder = (idf: IDParams) => (orderId: string) => Promise
  .resolve()
  .then(_ => fetch(`${BASE_URL}/exchange/cancelorder`, {
    method: "POST",
    body: JSON.stringify({ ...idf, orderId }),
    ...fetchConfig,
  }))
  .then(RESP)

export const APIExchangeCancelall = (idf: IDParams) => () => Promise
  .resolve()
  .then(_ => fetch(`${BASE_URL}/exchange/cancelall`, {
    method: "POST",
    body: JSON.stringify({ ...idf }),
    ...fetchConfig,
  }))
  .then(RESP)

export const APIExchangeSetData = (idf: IDParams) => (data: any) => Promise
  .resolve()
  .then(_ => fetch(`${BASE_URL}/exchange/setData`, {
    method: "POST",
    body: JSON.stringify({ ...idf, data }),
    ...fetchConfig,
  }))
  .then(RESP)

export const APIExchangeGetData = (idf: IDParams) => () => Promise
  .resolve()
  .then(_ => fetch(`${BASE_URL}/exchange/getData`, {
    method: "POST",
    body: JSON.stringify({ ...idf }),
    ...fetchConfig,
  }))
  .then(RESP)



