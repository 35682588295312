import { Box, Button, ButtonGroup, Stack, Tab, Tabs, Typography } from "@mui/material"
import { TradeSummary } from "./TradeSummary"
import { APIBOTList } from "../../apis/bots";
import { useAsync, useToggle } from "react-use";
import { APIKeyList } from "../../apis/keys";
import { useSearchParams } from "react-router-dom";
import { useCallback } from "react";
import { TradePerfomance } from "./TradePerfomance";

export const DashBoard: React.FC = () => {


  const [showAll, toggleShowAll] = useToggle(false)

  const { value: keyList } = useAsync(
    () => APIKeyList() as Promise<{ id; exchange; }[]>,
    []
  );

  const { value: pairs } = useAsync(
    () => APIBOTList()
      .then((e) => e.map(({ exchange, pair, account, enable }) => ({ exchange, pair, account, enable })))
      .then(e => e.filter(f => showAll || f.enable))
      .then(e => (e as any[]).groupBy(e => e.account, e => e.pair?.trim())),
    [showAll]
  );

  const [searchParams, _setSearchParams] = useSearchParams({ accountIndex: "0", tab: "0" })

  const setSearchParams = useCallback(
    (cb) => _setSearchParams(f => cb(Object.fromEntries(f.entries()))),
    [_setSearchParams],
  )

  return <>
    <Box sx={{}}>
      <Tabs
        value={+(searchParams.get("accountIndex") ?? 0)}
        onChange={(e, index) => setSearchParams(e => ({ ...e, accountIndex: String(index) }))}
        centered>
        {keyList?.map(({ id, exchange }, index) => <Tab label={`${exchange} ${id}`} />)}
      </Tabs>
    </Box >

    <br />

    {
      [keyList?.[searchParams.get("accountIndex")]].filter(Boolean).map(({ id, exchange }) => <>
        <Box sx={{}}>
          <Stack direction="row" justifyContent="space-between">
            <Tabs value={+searchParams.get("tab") ?? 0} onChange={(e, index) => setSearchParams(e => ({ ...e, tab: String(index) }))}>
              <Tab label="Trade Report" />
              <Tab label="Perfomance Report" />
            </Tabs>
            <Stack direction="row" alignItems="center" sx={{ button: { p: "0 0.7em", minWidth: "6em" } }}>
              <ButtonGroup>
                <Button color={!showAll ? "primary" : "inherit"} onClick={() => toggleShowAll(false)}>Running</Button>
                <Button color={showAll ? "primary" : "inherit"} onClick={() => toggleShowAll(true)}>All</Button>
              </ButtonGroup>
            </Stack>
          </Stack>
        </Box >
        {(+searchParams.get("tab") ?? 0) == 0 && <TradeSummary {...{ exchange, id, pairs: pairs?.[id], key: id }} />}
        {(+searchParams.get("tab") ?? 0) == 1 && <TradePerfomance {...{ exchange, id, pairs: pairs?.[id], key: id }} />}
      </>)
    }
  </>
}