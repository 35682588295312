

import { Button, ButtonGroup, CircularProgress, FormHelperText, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import { prettyNumber } from "../../format/date";
import { StatusPanel } from "../botdashboard/StatusPanel";
import { useTradeSummaryState } from "./useTradeSummaryState";
import { useCallback, useMemo, useState } from "react";
import useLocalStorageState from "use-local-storage-state";
import { calcPL } from "../botdashboard/calcPL";


export const TradeSummary: React.FC<{ exchange: string, id: string, pairs: string[] }> = ({ exchange, id, pairs }) => {


  const [layoutBy, setLayoutBy] = useState("VER")
  const [groupBy, setGroupBy] = useState("DATE")
  const [summaryBy, setSummaryBy] = useState("VAL")
  const [toggleStock, setToggleStock] = useLocalStorageState<Record<string, boolean> | undefined>("toggleStock", {})

  const groupByFunc = useMemo(
    () => (groupBy == "" || groupBy == "DATE") ? (e => new Date(+e?.timestamp ?? 0).toLocaleDateString())
      : groupBy == "WEEK" ? (e => [
        new Date(Number(+e?.timestamp - 3600000 * 3 * 24).floorByUnit(3600000 * 7 * 24) + 3600000 * 4 * 24).toLocaleDateString(),
        new Date(Number(+e?.timestamp - 3600000 * 3 * 24).floorByUnit(3600000 * 7 * 24) + 3600000 * 8 * 24).toLocaleDateString()
      ].join(" - "))
        : groupBy == "MONTH" ? (e => `${new Date(+e?.timestamp ?? 0).getMonth() + 1}/${(new Date(+e?.timestamp ?? 0) as any).getYear() + 1900}`)
          : (e => ""),
    [groupBy]
  )

  const filteredSymbols = useMemo(
    () => pairs
      ?.filter(f => toggleStock?.[f.split("_")[0]] ?? true)
      ?.map(e => e.split("_")[0]) ?? [],
    [pairs, toggleStock]
  )

  const { fields, summaryData, error, loading, loadingCache, filteredHistory } = useTradeSummaryState({ exchange, id, pairs, filteredSymbols: filteredSymbols }, groupByFunc)

  const filteredFields = useMemo(
    () => fields
      .filter(f => (!f.startsWith("VOL") && !f.startsWith("VAL")) || (summaryBy == "VOL" ? f.startsWith("VOL") : f.startsWith("VAL")))
      .filter(f => toggleStock?.[f.split("_")[1]] ?? true),
    [fields, summaryBy, toggleStock]
  )

  const getOpacity = (s) => String(s).split("/").some(e => parseFloat(e) > 0) ? 1 : 0.3
  const getColor = (s) => getOpacity(s) >= 1 && (String(s)[0] == "+" ? "green" : (String(s)[0] == "-" ? "red" : "")) || ""
  const getColor2 = (s) => s > 0 ? "green" : (s < 0 ? "red" : "gray")

  return <>
    <Paper style={{ padding: "0.8em", flex: 1, transform: "translate3d(0,0,0)", overflow: "hidden" }}>
      {/* <Typography variant="h5">Trading Summary</Typography> */}
      <Stack direction="row" gap={1} alignItems="flex-start" flexWrap="wrap" sx={{ button: { p: "0 0.7em", minWidth: "6em" } }} my={1}>
        <ButtonGroup>
          <Button color={(groupBy == "DATE") ? "primary" : "inherit"} onClick={() => setGroupBy("DATE")}>DATE</Button>
          <Button color={(groupBy == "WEEK") ? "primary" : "inherit"} onClick={() => setGroupBy("WEEK")}>WEEK</Button>
          <Button color={(groupBy == "MONTH") ? "primary" : "inherit"} onClick={() => setGroupBy("MONTH")}>MONTH</Button>
        </ButtonGroup>
        <ButtonGroup>
          <Button color={(summaryBy == "VOL") ? "primary" : "inherit"} onClick={() => setSummaryBy("VOL")}>ASSET</Button>
          <Button color={(summaryBy == "VAL") ? "primary" : "inherit"} onClick={() => setSummaryBy("VAL")}>CASH</Button>
        </ButtonGroup>
        <ButtonGroup>
          <Button color={(layoutBy == "VER") ? "primary" : "inherit"} onClick={() => setLayoutBy("VER")}>Vertical</Button>
          <Button color={(layoutBy == "HOR") ? "primary" : "inherit"} onClick={() => setLayoutBy("HOR")}>Horizontal</Button>
        </ButtonGroup>
      </Stack>
      <Stack direction="row" gap={0.2} flexWrap="wrap" sx={{ button: { p: "0", minWidth: "4em" } }} my={1}>
        {pairs
          ?.map(e => e.split("_")[0])
          ?.map(f => <Button size="small"
            color={(toggleStock?.[f] ?? true) ? "primary" : "inherit"}
            onClick={() => setToggleStock((e) => ({ ...e || {}, [f]: !(e?.[f] ?? true) }))}>{f}</Button>)}
      </Stack>
      <br />
      <QuickTradeSummary {...{ layoutBy, filteredHistory, getOpacity, getColor: getColor2, loading, loadingCache }} />
      <TradeSummaryTable {...{ layoutBy, filteredFields, summaryData, getOpacity, getColor, loading, loadingCache }} />
      {error && <FormHelperText error>{String(error)}</FormHelperText>}
    </Paper >

  </>;
};

const QuickTradeSummary = ({ layoutBy, filteredHistory, getOpacity, getColor, loading, loadingCache }: { layoutBy: string; filteredHistory: any[]; getOpacity: (s: any) => 1 | 0.3; getColor: (s: any) => string; loading: boolean; loadingCache: boolean; }) => {
  const groupedHistory = useMemo(
    () => Object
      .entries(filteredHistory?.groupBy(e => e.pair) ?? {})
      .map(([k, records]) => ({ pair: k, records: calcPL(records) })),
    [filteredHistory]
  )

  const groupedHistoryPoints = useMemo(
    () => {
      let toDay = new Date(new Date().toDateString()).getTime()
      let last7Day = Date.now() - 24 * 3600000 * 7
      let lastMonth = Date.now() - 24 * 3600000 * 30
      return groupedHistory
        .map(({ pair, records }) => ({
          pair,
          now: records?.at(-1),
          lastDay: records?.findLast(e => e.timestamp <= toDay) ?? records?.at(0),
          last7Day: records?.findLast(e => e.timestamp <= last7Day) ?? records?.at(0),
          lastMonth: records?.findLast(e => e.timestamp <= lastMonth) ?? records?.at(0),
        }))
    },
    [groupedHistory]
  )


  const summary = useMemo(
    () => ({
      toDayBuy: groupedHistoryPoints
        .map(e => e.now.cashOut - e.lastDay.cashOut)
        .reduce((e, f) => e + f, 0),
      toDaySell: groupedHistoryPoints
        .map(e => e.now.cashIn - e.lastDay.cashIn)
        .reduce((e, f) => e + f, 0),
      last7DayBuy: groupedHistoryPoints
        .map(e => e.now.cashOut - e.last7Day.cashOut)
        .reduce((e, f) => e + f, 0),
      last7DaySell: groupedHistoryPoints
        .map(e => e.now.cashIn - e.last7Day.cashIn)
        .reduce((e, f) => e + f, 0),
      lastMonthBuy: groupedHistoryPoints
        .map(e => e.now.cashOut - e.lastMonth.cashOut)
        .reduce((e, f) => e + f, 0),
      lastMonthSell: groupedHistoryPoints
        .map(e => e.now.cashIn - e.lastMonth.cashIn)
        .reduce((e, f) => e + f, 0),
      allBuy: groupedHistoryPoints
        .map(e => e.now.cashOut)
        .reduce((e, f) => e + f, 0),
      allSell: groupedHistoryPoints
        .map(e => e.now.cashIn)
        .reduce((e, f) => e + f, 0),
    }),
    [groupedHistoryPoints]
  )

  const getTimeDisplay = useCallback(
    ({ buy, sell, title }) => ({
      title,
      main: <>  <span style={{ color: "red" }}>{prettyNumber(buy ?? "----")}</span>
        {" / "}
        <span style={{ color: "green" }}>{prettyNumber(sell ?? "----")}</span>
      </>,
      sub: <>
        <span style={{ color: getColor(sell - buy) }}>Δ {prettyNumber(sell - buy, 0, true)}</span>
        {" / "}
        <span style={{ color: sell || buy ? "" : "gray" }}>Σ {prettyNumber(sell + buy)} </span>
      </>,
    }),
    []
  )

  const items = useMemo(
    () => [
      getTimeDisplay({ buy: summary?.allBuy, sell: summary?.allSell, title: "All time Buy/Sell" }),
      getTimeDisplay({ buy: summary?.lastMonthBuy, sell: summary?.lastMonthSell, title: "Monthly Buy/Sell" }),
      getTimeDisplay({ buy: summary?.last7DayBuy, sell: summary?.last7DaySell, title: "Weekly Buy/Sell" }),
      getTimeDisplay({ buy: summary?.toDayBuy, sell: summary?.toDaySell, title: "Today Buy/Sell" }),
    ],
    [summary]
  )

  return <Stack direction="row" my={2} justifyContent="center" gap={[1, 2, 3]} flexWrap="wrap">
    {items?.map(e => <Paper elevation={3} sx={{ p: ["0.3em 0.5em", "0.4em 0.8em", "0.7em 1.5em",], flex: 1, maxWidth: "15em" }} >
      <Typography variant="h5" sx={{ opacity: 0.5 }}>{e?.title}</Typography>
      <Typography variant="h6">{e?.main}</Typography>
      <Typography variant="subtitle2">{e?.sub}</Typography>
    </Paper>)}
  </Stack>
}

const TradeSummaryTable = ({ layoutBy, filteredFields, summaryData, getOpacity, getColor, loading, loadingCache }: { layoutBy: string; filteredFields: string[]; summaryData: { "BUY/SELL": string; "CHG/VAL": string; date: any; }[]; getOpacity: (s: any) => 1 | 0.3; getColor: (s: any) => "" | "green" | "red"; loading: boolean; loadingCache: boolean; }) => {
  return <TableContainer sx={{
    height: "350px", overflow: "auto", position: "relative",
    "th,td": {
      fontSize: "0.85em", p: "0.12em 0.7em", fontFamily: "monospace", whiteSpace: "nowrap", textAlign: "center"
    },
    "[data-row-title]": {
      position: "sticky", left: 0, zIndex: 1, background: "white"
    },
    paddingBottom: "2em",
    // "thead": { zIndex: 2, position: "relative" },
    // "thead [data-row-title]": { zIndex: 10, position: "relative" }
  }}>
    <Table stickyHeader size="small">
      {layoutBy == "VER" && <>
        <TableHead color="white" sx={{ whiteSpace: "nowrap" }}>
          {filteredFields.map(f => <TableCell align="center" data-row-title={f == "date" ? true : undefined}>{f}</TableCell>)}
        </TableHead>
        <TableBody>
          {summaryData.map(data => <TableRow key={data?.date}>
            {filteredFields.map(f => ['date'].includes(f)
              ? <TableCell align="center" data-row-title>{data?.[f]}</TableCell>
              : <TableCell align="center" style={{ opacity: getOpacity(data?.[f]), color: getColor(data?.[f]) }}>
                {typeof data?.[f] === 'number'
                  ? prettyNumber(data?.[f], 0, f?.startsWith("CHANGE_"))
                  : data?.[f]}
              </TableCell>
            )}
          </TableRow>)}
        </TableBody>
      </>}
      {layoutBy == "HOR" && <>
        <TableHead color="white" sx={{ whiteSpace: "nowrap" }}>
          <TableCell align="center" data-row-title>Date </TableCell>
          {summaryData.map(f => <TableCell align="center">{f?.date}</TableCell>)}
        </TableHead>
        <TableBody>
          {filteredFields
            .filter(f => !['date'].includes(f))
            .map(f => <TableRow key={f}>
              <TableCell align="center" data-row-title>{f} </TableCell>
              {summaryData.map(data => <TableCell align="center" style={{ opacity: getOpacity(data?.[f]), color: getColor(data?.[f]) }}>
                {typeof data?.[f] === 'number'
                  ? prettyNumber(data?.[f], 0, f?.startsWith("CHANGE_"))
                  : data?.[f]}
              </TableCell>
              )}
            </TableRow>)}
        </TableBody>
      </>}
    </Table>
    <StatusPanel show={loading || loadingCache}>
      <CircularProgress size="0.9em" sx={{ px: "0.5em" }} />
      Fetching {loadingCache ? ' cached ' : ' latest '} data ...
    </StatusPanel>
  </TableContainer>;
}
