import { useCallback, useEffect, useState } from "react"
import { useAsync } from "react-use"
import { PromiseType } from "react-use/lib/misc/types"

export const useAsyncWithCached = <F extends (...args: any[]) => Promise<any>>(
  func: F,
  dependency: any[]
): {
  loadingCache: boolean,
  loading: boolean,
  error: any,
  value: PromiseType<ReturnType<F>>
} => {

  const { loading, error, value } = useAsync(
    () => func(true),
    dependency
  )
  
  const { loading: loading2, error: error2, value: value2 } = useAsync(
    () => new Promise(r => setTimeout(r, 500))
      .then(() => func(false)),
    dependency
  )

  return {
    loadingCache: loading && !(value2 ?? value),
    loading: loading2 || loading,
    error: error2 ?? error,
    value: (value2 ?? value),
  }
}
