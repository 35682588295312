import { useCallback, useEffect, useState } from "react"

export const useReload = () => {
  const [token, setReload] = useState(() => Math.random())

  return {
    token,
    reload: useCallback(() => {
      setReload(Math.random())
      // console.trace("RELOAD")
    }, [])
  }
}

export const useIntervalReload = (t: number = 1000, token = 0) => {
  const [intervalToken, setReload] = useState(() => Math.random())

  useEffect(() => {
    let timeout = setInterval(() => setReload(Math.random()), t)
    return () => clearInterval(timeout)
  }, [token, t])

  return intervalToken + token
}