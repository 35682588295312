import { useAsync } from "react-use";
import { APIExchangeBalance, APIExchangeOrderhistory } from "../../apis/exchange";
import { useMemo } from "react";
import { useAsyncWithCached } from "../../hook/useAsyncWithCached";



export const useBalanceState = ({ id, exchange, }) => {

  const { error, loading, loadingCache, value: allBalances } = useAsyncWithCached(
    (cached) => id && exchange
      && APIExchangeBalance({ account: id, exchange: exchange }, cached)()
      || Promise.resolve([]),
    [id, exchange]
  )

  return {
    allBalances,
    error, loading,
    loadingCache,
  };
};
