import React, { useEffect, useState } from 'react';
import { Box, Button, Container, Paper, Stack, Tab, Tabs, Typography } from '@mui/material';
import { createRoot, } from "react-dom/client";
import {
  createBrowserRouter,
  RouterProvider,
  Link,
  Outlet,
  redirect,
  useRoutes,
  useLocation,
} from "react-router-dom";
import './App.css';
import { APIKeyManager } from './components/apikey';
import { BotManager } from './components/bot';
import { BotDashBoard, BotDashBoardPage, } from './components/botdashboard';
import { AuthButton } from './firebase';
import { Dashboard, KeyOffRounded, KeyRounded, List, Terminal } from '@mui/icons-material';
import { DashBoard } from './components/dashboard/DashboardIndex';

const MainPage = () => {
  const { pathname, } = useLocation()
  const [botList, setBotList] = useState<string[]>([])

  useEffect(() => {
    console.log({ pathname })
    if (!botList.includes(pathname) && pathname.startsWith("/bot/")) {
      setBotList(e => [...new Set([...e, pathname])])
    }
  }, [pathname, botList])

  return <Container maxWidth="xl" sx={{ p: ["1em 0.3em", "1em", "1em"] }}>
    <Stack direction="row" justifyContent="flex-start" p="1em">
      <span style={{ flex: 1 }} />
      <AuthButton>Login with Google</AuthButton>
    </Stack>
    <Tabs value={pathname}>
      <Tab component={Link} value='/bots' to='/bots' label={<><span> <List style={{ verticalAlign: "middle", marginTop: "-0.1em" }} /> Bot List </span> </>} />
      <Tab component={Link} value='/api-keys' to='/api-keys' label={<><span> <KeyRounded style={{ verticalAlign: "middle", marginTop: "-0.1em" }} /> API Keys </span> </>} />
      <Tab component={Link} value='/dashboard' to='/dashboard' label={<><span> <Dashboard style={{ verticalAlign: "middle", marginTop: "-0.1em" }} /> Dashboard </span> </>} />
      {botList.map(path => <Tab component={Link} value={path} to={path} label={<><span> <Terminal style={{ verticalAlign: "middle", marginTop: "-0.1em" }} /> {decodeURIComponent(path?.slice(5))} </span> </>} />)}
    </Tabs>
    <Outlet />

  </Container>
}

const router = createBrowserRouter([
  {
    path: "/",
    element: <MainPage />,
    children: [
      { path: "", loader: () => redirect("bots") },
      { path: "bots", element: <Paper sx={{ p: ["1em 0.3em", "1em", "1em"] }} > <BotManager /></Paper>, },
      { path: "api-keys", element: <Paper sx={{ p: ["1em 0.3em", "1em", "1em"] }} > <APIKeyManager /></Paper> },
      { path: "dashboard", element: <DashBoard /> },
      { path: "bot/:botId", element: <BotDashBoardPage /> },
    ]

  },
])



function App() {
  return (<>

    <RouterProvider router={router} />
  </>
  );
}

export default App;
