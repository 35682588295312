

import { Button, ButtonGroup, CircularProgress, FormHelperText, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import { prettyNumber } from "../../format/date";
import { StatusPanel } from "../botdashboard/StatusPanel";
import { useTradeSummaryState } from "./useTradeSummaryState";
import { useEffect, useMemo, useState } from "react";
import useLocalStorageState from "use-local-storage-state";
import { useBalanceState } from "./useBalanceState";
import { useAsync } from "react-use";
import { APIMarketPrice } from "../../apis/market";
import { useBotSummaryState } from "../botdashboard/BotSummary";
import { APIExchangeOrderhistory } from "../../apis/exchange";
import { useAsyncWithCached } from "../../hook/useAsyncWithCached";
import { calcPL } from "../botdashboard/calcPL";
import { useSortWithState } from "../shared/useSortWithState";
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
import StraightIcon from '@mui/icons-material/Straight';
import HeightSharpIcon from '@mui/icons-material/HeightSharp';
import ArrowDropDownSharpIcon from '@mui/icons-material/ArrowDropDownSharp';
import ArrowDropUpSharpIcon from '@mui/icons-material/ArrowDropUpSharp';
const getColor = (s) => s > 0 ? "green"
  : s < 0 ? "red"
    : "#8888"

const sStyle = { verticalAlign: "middle", fontSize: "0.6em", transform: "scale(1.8)", margin: "0 -0.4em" }

const getSortSymbol = (value) => value > 0 ? <ArrowDropUpSharpIcon style={sStyle} />
  : value < 0 ? <ArrowDropDownSharpIcon style={sStyle} />
    : <HeightSharpIcon style={{ ...sStyle, opacity: 0 }} />


export const TradePerfomance: React.FC<{ exchange: string, id: string, pairs: string[] }> = ({ exchange, id, pairs }) => {


  const { handleSortFactory, sortedFunc, sortMap } = useSortWithState(
    useState(() => [["cost", -1]] as [string, 1 | -1][])
  )

  const filteredPairSet = useMemo(
    () => new Set(pairs?.map(e => e.split("_")[0]) ?? []),
    [pairs]
  )

  const { allBalances, error, loading, loadingCache } = useBalanceState({ id, exchange })

  const { value: histories, error: e1, loading: l1, loadingCache: lc1 } = useAsyncWithCached(
    (cached) => APIExchangeOrderhistory({ account: id, exchange: exchange, filter: { pair: pairs ?? undefined } }, cached)()
      .then(f => f.map(g => ({ ...g, account: id, exchange: exchange })))
      .catch(e => [])
      .then(e => e.groupBy(f => f.pair?.split("_")?.[0]))
      ?? Promise.resolve({}),
    [id, exchange, pairs]
  );

  const { value: prices } = useAsync(
    () => Promise
      .all(pairs
        ?.map(pair => APIMarketPrice({ exchange, pair })()
          .then(data => ({ pair, ...data }))
          .catch(data => ({ pair, }))
        )
      )
      .then(e => Object.fromEntries(e?.map(f => [f.pair?.split("_")[0], f]))),
    [pairs]
  )


  const calcBalance = useMemo(
    () => allBalances
      ?.filter(e => filteredPairSet?.has(e.currency))
      ?.map(e => ({
        ...e,
        records: calcPL(histories?.[e.currency] ?? []),
      }))
      ?.map(e => ({
        ...e,
        calcPos: e.records?.at(-1)?.cumToken || 0,
        avgPrice: e.records?.at(-1)?.avgPrice || 0,
        settlePL: e.records?.at(-1)?.cumPL || 0,
        last7DPL: (e.records?.at(-1)?.cumPL || 0)
          - ((e.records?.findLast(e => e.timestamp <= Date.now() - 7 * 24 * 3600000) ?? e.records[0])?.cumPL || 0),
        maxLost: e.records?.at(-1)?.maxLost,
        maxProfit: e.records?.at(-1)?.maxProfit,
        maxLong: e.records?.at(-1)?.maxLong,
        maxShort: e.records?.at(-1)?.maxShort,
      }))
      ?.map(e => ({
        ...e,
        cost: e.total * e.avgPrice,
        mktPrice: prices?.[e.currency]?.currentPrice,
      }))
      ?.map(e => ({
        ...e,
        mktValue: e.mktPrice * e.total,
        unsetPL: e.mktPrice * e.total - e.cost,
      }))
      ?.map(e => ({
        ...e,
        unsetPLPercent: e.unsetPL / e.cost * 100,
        settlePLPercent: e.settlePL / e.cost * 100,
        last7DPLPercent: e.last7DPL / e.cost * 100,
      }))
      ?.sort(sortedFunc),
    [sortedFunc, filteredPairSet, allBalances, prices, histories]
  )




  return <>
    <Paper style={{ padding: "0.8em", flex: 1, transform: "translate3d(0,0,0)", paddingBottom: "2em", overflow: "hidden", }}>
      <PerfomanceSummary {...{ handleSortFactory, sortMap, calcBalance, loading, loadingCache }} />
      <PerfomanceView {...{ handleSortFactory, sortMap, calcBalance, loading, loadingCache }} />
      {error && <FormHelperText error>{String(error)}</FormHelperText>}
    </Paper>

  </>;
};

const PerfomanceView = ({ handleSortFactory, sortMap, calcBalance, loading, loadingCache }: { handleSortFactory; sortMap: { [k: string]: 1 | -1; }; calcBalance: any; loading: boolean; loadingCache: boolean; }) => {
  return <TableContainer sx={{ overflow: "auto", position: "relative", whiteSpace: "nowrap", td: { fontFamily: "monospace", fontSize: "0.85em" } }}>
    <Table>
      <TableHead>
        <TableRow style={{ whiteSpace: "nowrap" }}>
          <TableCell style={{ cursor: "pointer" }} onClick={handleSortFactory('currency')}>Asset {getSortSymbol(sortMap['currency'])}</TableCell>

          <TableCell align="center">Max.S / Pos. / Max.L</TableCell>
          <TableCell align="center">Avg. Price / Mkt. Price</TableCell>
          <TableCell align="center"  >
            <span style={{ cursor: "pointer" }} onClick={handleSortFactory('cost')}>{getSortSymbol(sortMap['cost'])} Cost Value </span>
            {" / "}
            <span style={{ cursor: "pointer" }} onClick={handleSortFactory('mktValue')}>Mkt. Value {getSortSymbol(sortMap['mktValue'])}</span>
          </TableCell>
          {/* <TableCell align="center" style={{ cursor: "pointer" }} onClick={handleSortFactory('mktValue')}>Mkt. Value {getSortSymbol(sortMap['mktValue'])}</TableCell> */}
          <TableCell align="center" style={{ cursor: "pointer" }} onClick={handleSortFactory('maxLost')}>Max Lost  {getSortSymbol(sortMap['maxLost'])}</TableCell>
          <TableCell align="center" style={{ cursor: "pointer" }} onClick={handleSortFactory('unsetPL')}>Tmp. PL {getSortSymbol(sortMap['unsetPL'])}</TableCell>
          <TableCell align="center" style={{ cursor: "pointer" }} onClick={handleSortFactory('settlePL')}>Rea. PL  {getSortSymbol(sortMap['settlePL'])}</TableCell>
          <TableCell align="center" style={{ cursor: "pointer" }} onClick={handleSortFactory('last7DPL')}>Last7D. PL  {getSortSymbol(sortMap['last7DPL'])}</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {calcBalance?.map((row) => (
          <TableRow key={row.currency}>
            <TableCell component="th" scope="row">
              {row.currency}
            </TableCell>
            <TableCell align="center" style={{ color: "#8888" }}>
              <span style={{ color: row.maxShort > 0 ? "red" : "#8888" }}>{prettyNumber(row.maxShort)}</span>
              {" / "}
              <span style={{ color: getColor(row.total), fontWeight: "bold" }}>{prettyNumber(Math.abs(row.total))}</span>
              {" / "}
              <span style={{ color: row.maxLong > 0 ? "green" : "#8888" }}>{prettyNumber(row.maxLong)}</span>
            </TableCell>
            <TableCell align="center">
              {prettyNumber(row.avgPrice)} / {prettyNumber(row.mktPrice)}
            </TableCell>
            <TableCell align="center">{prettyNumber(row.cost)} / {prettyNumber(row.mktValue)}</TableCell>
            <TableCell align="center">
              {prettyNumber(row.maxLost)}
            </TableCell>
            <TableCell align="center" style={{ color: getColor(row.unsetPL) }}>
              {prettyNumber(row.unsetPL)}
              {row.unsetPL > 0 ? " ↗ " : " ↘ "}
              {row.unsetPLPercent.toFixed(2)}%
            </TableCell>
            <TableCell align="center" style={{ color: getColor(row.settlePL) }}>
              {prettyNumber(row.settlePL)} ({row.settlePLPercent.toFixed(2)}%)
            </TableCell>
            <TableCell align="center" style={{ color: getColor(row.last7DPL) }}>
              {prettyNumber(row.last7DPL)} ({row.last7DPLPercent.toFixed(2)}%)
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
    <StatusPanel show={loading || loadingCache}>
      <CircularProgress size="0.9em" sx={{ px: "0.5em" }} />
      Fetching {loadingCache ? ' cached ' : ' latest '} data ...
    </StatusPanel>
  </TableContainer>;
}


const PerfomanceSummary = ({ handleSortFactory, sortMap, calcBalance, loading, loadingCache }: { handleSortFactory; sortMap: { [k: string]: 1 | -1; }; calcBalance: any; loading: boolean; loadingCache: boolean; }) => {


  const [totalCost, totalValue, unsetPL, settedPL, lastWeekPL] = useMemo(() => [
    calcBalance?.map(e => e.cost || 0)?.reduce((e, f) => e + f, 0),
    calcBalance?.map(e => e.mktValue || 0)?.reduce((e, f) => e + f, 0),
    calcBalance?.map(e => e.unsetPL || 0)?.reduce((e, f) => e + f, 0),
    calcBalance?.map(e => e.settlePL || 0)?.reduce((e, f) => e + f, 0),
    calcBalance?.map(e => e.last7DPL || 0)?.reduce((e, f) => e + f, 0),
  ], [calcBalance])

  const items = useMemo(
    () => [
      {
        title: "Total Asset Value",
        main: <>{prettyNumber(totalValue ?? "----")}</>,
        sub: <></>,
      },
      {
        title: "Temporary PL",
        main: <>{prettyNumber(unsetPL ?? "----")}</>,
        sub: <span style={{ color: getColor(unsetPL) }}>{(unsetPL / totalCost * 100).toFixed(2)}%</span>,
      },
      {
        title: "Settle PL",
        main: <>{prettyNumber(settedPL ?? "----")}</>,
        sub: <span style={{ color: getColor(settedPL) }}>{(settedPL / totalCost * 100).toFixed(2)}%</span>,
      },
      {
        title: "Last 7D PL",
        main: <>{prettyNumber(lastWeekPL ?? "----")}</>,
        sub: <span style={{ color: getColor(lastWeekPL) }}>{(lastWeekPL / totalCost * 100).toFixed(2)}%</span>,
      },
    ],
    [calcBalance]
  )

  return <Stack direction="row" my={2} justifyContent="center" gap={[1, 2, 3]} flexWrap="wrap">
    {items?.map(e => <Paper elevation={3} sx={{ p: ["0.3em 0.5em", "0.4em 0.8em", "0.7em 1.5em",], flex: 1, maxWidth: "15em" }} >
      <Typography variant="h5" sx={{ opacity: 0.5 }}>{e?.title}</Typography>
      <Typography variant="h6">{e?.main}</Typography>
      <Typography variant="subtitle2">{e?.sub}</Typography>
    </Paper>)}
  </Stack>
}

