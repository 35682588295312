import { BASE_URL, RESP, fetchConfig } from "./base"


export const APILogin = (data: { firebaseToken, email }) => Promise
  .resolve()
  .then(_ => (console.trace("login"), fetch(`${BASE_URL}/account/login`, {
    method: "POST",
    body: JSON.stringify(data),
    ...fetchConfig,
  })))
  .then(RESP)
