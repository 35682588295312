import { APICaching, APIWrapperFactory } from "./APICaching"
import { BASE_URL, RESP, fetchConfig } from "./base"
import { fetch } from './fetch'

type IDParams = { exchange: string, pair: string }

export const APIMarketOrderBook = (p: IDParams) => () => Promise
  .resolve()
  .then(_ => fetch(`${BASE_URL}/market/orderbook?${new URLSearchParams(p).toString()}`))
  .then(RESP)


const getMarketPrice = APIWrapperFactory(
  APICaching(
    (p: IDParams) => Promise
      .resolve()
      .then(_ => fetch(`${BASE_URL}/market/prices?${new URLSearchParams(p).toString()}`))
      .then(RESP) as Promise<Record<string, {
        avgPrice: number,
        ceiling: number,
        currentPrice: number,
        floor: number,
        openPrice: number,
        refPrice: number,
      }>>
  ),
  ({ exchange }) => exchange,
  ([{ exchange, pair }], ...rest) => ([{ exchange, pairs: [...new Set([pair, rest.map(e => e[0].pair)].flat() as any)] }] as any),
  ([{ exchange, pair }], result: any) => result?.[pair]
)

export const APIMarketPrice = (p: IDParams) => () => getMarketPrice(p)

