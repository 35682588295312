import { Chip, Stack } from '@mui/material';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import useLocalStorageState from 'use-local-storage-state';

interface BotQuickFilterProps {
  // Add your props here
  list: { exchange, type, account, market, enable, prod }[],
  setFilterFund,
}

export const BotQuickFilter: React.FC<BotQuickFilterProps> = ({ list, setFilterFund }) => {

  const [, update] = useState(0)

  const [filterList, filterSet] = useMemo(
    () => {
      let filters = []
      let filterSet = new Set()
      for (let [key, values] of [
        ["account", [...new Set(list?.map(e => e?.account))]],
        ["enable", [...new Set(list?.map(e => e?.enable))]],
        ["type", [...new Set(list?.map(e => e?.type))]],
      ] as [string, any[]][]) {
        if (values.length >= 2) {
          filters.push(...values.map(value => ({
            key, value,
            count: list.filter(e => e[key] == value).length
          })))
          values.forEach(value => filterSet.add(`${key}:${value}`))
        }
      }
      return [filters, filterSet]
    }, [list]
  )

  const [filters, _setFilters] = useLocalStorageState("botFilters", { defaultValue: {} as Record<string, any> })

  const toggleFilters = useCallback(
    (key: string, value) => _setFilters(
      ({ [key]: preValue, ...e }) => ({
        ...e,
        ...value === preValue ? {} : { [key]: value }
      })),
    []
  )

  useEffect(() => {
    setFilterFund(
      [e => Object.entries(filters).every(([key, value]) => value === undefined || e[key] == value || !filterSet.has(`${key}:${e[key]}`))]
    )
  }, [filterSet, setFilterFund, JSON.stringify(filters)])




  return (
    <Stack direction="row" gap={0.5} flexWrap="wrap" mb={2}>
      {filterList
        // .sortBy(e => -e.count)
        .map(e => <Chip
          variant='filled'
          color={filters[e.key] === e.value ? "primary" : "default"}
          size='small'
          label={`${e.key}:${String(e.value).toLowerCase()}`}
          onClick={() => toggleFilters(e.key, e.value)}
        />)}
    </Stack>
  );
};

