import { useAsync } from "react-use";
import { CircularProgress, FormHelperText, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import { APIExchangeBalance } from "../../apis/exchange";
import { prettyNumber } from "../../format/date";
import { useIntervalReload } from "../../hook/useReload";
import { useEffect, useMemo } from "react";
import { useRefValue } from "../../hook/useRefValue";
import { useAsyncWithCached } from "../../hook/useAsyncWithCached";
import { StatusPanel } from "./StatusPanel";



export const BotBalance: React.FC<{ data: any, botId: string; token: any; reload: any; }> = ({ data, botId, reload }) => {

  const intervalToken = useIntervalReload(5000)

  const [asset = '', counter = ''] = data?.pair?.trim()?.split("_") || []

  const { value: balances, error, loading, loadingCache } = useAsyncWithCached(
    (cached) => APIExchangeBalance({ botId }, cached)(), [botId, intervalToken]
  );

  const sortedBalances = useMemo(
    () => (balances as any[])
      ?.filter((e: any) => e.currency == asset || e.currency == counter || e.total > 0)
      ?.sortBy((e: any) => e.currency == asset || e.currency == counter ? 1 : 10),
    [balances, asset, counter]
  )

  const assetBalance = useMemo(() => (balances as any[])?.find(e => e.currency == asset), [balances, asset])

  const ref = useRefValue({ assetBalance })

  useEffect(() => () => {
    if (assetBalance && (JSON.stringify(assetBalance) != JSON.stringify(ref.assetBalance))) {
      reload?.()
    }
  }, [assetBalance,])


  return <>
    <Paper style={{ padding: "0.8em", flex: 1, transform: "translate3d(0,0,0)", overflow: "hidden", paddingBottom: "2em", }}>
      <Typography variant="h6">Balances</Typography>

      <TableContainer style={{ height: "120px", overflow: "auto", position: "relative", }} >
        <Table  >
          <TableHead>
            <TableRow style={{ whiteSpace: "nowrap" }}>
              <TableCell>Asset</TableCell>
              <TableCell align="right">Available</TableCell>
              <TableCell align="right">Locked</TableCell>
              <TableCell align="right">InOrder</TableCell>
              <TableCell align="right">Recieving</TableCell>
              <TableCell align="right">AVG</TableCell>
              <TableCell align="right">Total</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedBalances?.map((row) => (
              <TableRow
                key={row.currency}
                sx={{ '&:last-child td, &:last-child th': { border: 0 }, opacity: row.currency == asset || row.currency == counter ? 1 : 0.3 }}
              >
                <TableCell component="th" scope="row">
                  {row.currency}
                </TableCell>
                <TableCell align="right">{prettyNumber(row.available)}</TableCell>
                <TableCell align="right">{prettyNumber(row.in_lock)}</TableCell>
                <TableCell align="right">{prettyNumber(row.in_order)}</TableCell>
                <TableCell align="right">{prettyNumber(row.in_recieve)}</TableCell>
                <TableCell align="right">{prettyNumber(row.avgPrice)}</TableCell>
                <TableCell align="right">{prettyNumber(row.total)}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <StatusPanel show={loading || loadingCache}>
          <CircularProgress size="0.9em" sx={{ px: "0.5em" }} />
          Fetching {loadingCache ? ' cached ' : ' latest '} data ...
        </StatusPanel>
      </TableContainer>

      {/* <pre>{JSON.stringify(balances, null, 2)}</pre> */}
      {error && <FormHelperText error>{String(error)}</FormHelperText>}

    </Paper>

  </>;
};
