import { useLocation, useParams, useSearchParams } from "react-router-dom"
import { useAsync, useEffectOnce, useSearchParam, useToggle } from "react-use"
import { APIBOTGet, APIBOTGetStatus } from "../../apis/bots"
import { useIntervalReload, useReload } from "../../hook/useReload"
import { BottomNavigation, BottomNavigationAction, Box, Hidden, IconButton, Paper, Stack, Tab, Tabs, Typography, useEventCallback } from "@mui/material"
import { BotOpenOrders } from "./BotOpenOrders"
import { BotBalance } from "./BotBalance"
import { BotOrderHistory } from "./BotOrderHistory"
import { BotActions } from "./BotActions"
import { BotLoggingPanel } from "./BotLoggingPanel"
import YAML from "yaml"
import { Settings } from "@mui/icons-material"
import { BotUpdateDialog } from "../bot/BotForm"
import { BotOrderBook } from "./OrderBook"
import { auth } from "../../firebase"
import { memo, useCallback, useEffect, useMemo } from "react"
import { BotSummary } from "./BotSummary"
import { QuickLocks } from "../bot/BotQuickLogs"
import { BotQuickStatus } from "../bot/BotQuickStatus"
import RestoreIcon from '@mui/icons-material/Restore';
import FavoriteIcon from '@mui/icons-material/Favorite';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import SettingsIcon from '@mui/icons-material/Settings';
import ViewListIcon from '@mui/icons-material/ViewList';
import BarChartIcon from '@mui/icons-material/BarChart';
import { BotQuickTradeSummary } from "../bot/BotQuickTradeSummary"
import { BotOrderHistoryVis } from "./BotOrderHistoryVis"
export const BotDashBoardPage: React.FC = () => {


  const { botId = "" } = useParams()
  return <BotDashBoard botId={botId} key={botId} />
}

export const GridItem = ({ children, gridArea, ...props }) => {

  return <Box gridArea={gridArea} {...props} data-active-area={gridArea}>
    {children}
  </Box>
}




export const BotDashBoard: React.FC<{ botId: string }> = memo(
  ({ botId }) => {
    const { reload, token } = useReload()
    const { reload: reloadRealtime, token: tokenRealtime } = useReload()
    const { value: data } = useAsync(() => APIBOTGet(botId), [botId, token])
    const [searchParams, _setSearchParams] = useSearchParams({ orderTab: "openorder", loggingTab: "logging", active: "" })
    const [updateDialog, toggleUpdate] = useToggle(false)

    const setSearchParams = useCallback(
      (cb) => _setSearchParams(f => cb(Object.fromEntries(f.entries()))),
      [_setSearchParams],
    )

    const [orderTab, loginTab, avtiveUI] = useMemo(
      () => [
        searchParams.get("orderTab") ?? "openorder",
        searchParams.get("loggingTab") ?? "logging",
        searchParams.get("active") ?? "active",
      ],
      [searchParams]
    )

    const setActiveTab = useCallback(
      (tab) => setSearchParams(f => ({ ...f, active: tab })),
      [setSearchParams]
    )

    const pair = data?.pair?.trim()
    const account = data?.account?.trim()
    const exchange = data?.exchange?.trim()

    const layout = [
      `"q q a" auto`,
      `"ba ba a" auto`,
      `"o o b" 400px`,
      `"l l l" 400px`,
    ].join("\n")

    const layoutSM = avtiveUI == "0" ? [`"q"`, `"a"`]
      : avtiveUI == "1" ? [`"ba"`, `"b"`]
        : avtiveUI == "2" ? [`"o"`]
          : [`"l"`]


    return <>
      <Box sx={{
        display: "grid",
        columnGap: "0.5em",
        rowGap: "0.5em",
        maxWidth: "100%",
        gridTemplate: {
          md: layout, lg: layout, xl: layout,
          sm: layoutSM.join("\n"),
          xs: layoutSM.join("\n"),
        },
        "div[data-active-area]": {
          display: { xs: "none", sm: "none", md: "block", lg: "block", xl: "block" },
        },
        [layoutSM.map(e => `div[data-active-area=${e}]`).join(",")]: {
          display: "block",
        },
        "> *": {
          maxWidth: "calc(100vw - 2em)",
        },

      }}>
        <GridItem gridArea="q" >
          <BotQuickView {...{ data, toggleUpdate, reload, updateDialog, botId }} />
        </GridItem>

        <GridItem gridArea="a" >
          <BotActions {...{ botId, data, reload, token }} />
        </GridItem>

        <GridItem gridArea="ba">
          <BotBalance data={data} botId={botId} token={token} reload={reloadRealtime} />
        </GridItem>

        <GridItem gridArea="o" >
          <Tabs value={orderTab} onChange={(_, value) => setSearchParams(f => ({ ...f, orderTab: value }))}>
            <Tab label="Open Orders" value="openorder" />
            <Tab label="Order History" value="orderhistory" />
            <Tab label="Vis" value="vis" />
            <Tab label="Summary" value="summary" />
          </Tabs>
          <Paper style={{ padding: "1.5em 1em", height: "calc(100% - 1.5em)", minHeight:"20em" }} elevation={2}>
            {orderTab == "openorder" && <BotOpenOrders botId={botId} token={token} reload={reloadRealtime} pair={pair} />}
            {orderTab == "orderhistory" && <BotOrderHistory botId={botId} token={token} reload={reloadRealtime} pair={pair} />}
            {orderTab == "vis" && <BotOrderHistoryVis botId={botId} token={token} reload={reloadRealtime} pair={pair} />}
            {orderTab == "summary" && <BotSummary account={account} exchange={exchange} token={token} reload={reloadRealtime} pair={pair} />}
          </Paper>
        </GridItem>
        <GridItem gridArea="b" >
          <Tabs value="0">
            <Tab label="OrderBook" value="0" />
          </Tabs>
          <BotOrderBook botId={botId} token={token + tokenRealtime} reload={reloadRealtime} pair={pair} exchange={exchange} />
        </GridItem>
        <GridItem gridArea="l" >
          <Box sx={{ borderBottom: 1, borderColor: 'divider', flex: 1 }}>
            <Tabs value={loginTab} onChange={(_, value) => setSearchParams(f => ({ ...f, loggingTab: value }))}>
              <Tab label="Logging" value="logging" />
              <Tab label="Status Log" value="status" />
            </Tabs>
            {loginTab == "logging" && <BotLoggingPanel botId={botId} />}
            {loginTab == "status" && <BotQuickLogs botId={botId} />}
          </Box>
        </GridItem>
      </Box >
      <Hidden mdUp>
        <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0, }} elevation={3}>
          <BottomNavigation showLabels value={avtiveUI}>
            <BottomNavigationAction value="0" label="Config" onClick={() => setActiveTab("0")} icon={<SettingsIcon />} />
            <BottomNavigationAction value="1" label="Book/Balance" onClick={() => setActiveTab("1")} icon={<BarChartIcon />} />
            <BottomNavigationAction value="2" label="Orders" onClick={() => setActiveTab("2")} icon={<ViewListIcon />} />
            <BottomNavigationAction value="3" label="Log" onClick={() => setActiveTab("3")} icon={<ViewListIcon />} />
          </BottomNavigation>
        </Paper>
      </Hidden>
    </>
  }

)

function BotQuickView({ data, toggleUpdate, reload, updateDialog, botId, }: { data: any; toggleUpdate: (nextValue?: any) => void; reload: () => void; updateDialog: boolean; botId: string; }) {
  const intervalToken = useIntervalReload(2000)
  const { value: status } = useAsync(
    () => APIBOTGetStatus(botId),
    [botId, intervalToken, intervalToken]
  )

  return <Stack direction="column" gap={2} flex={1}>
    <Paper style={{ padding: "0.8em" }}>
      <Typography variant="h6">
        [{data?.type} BOT] [{data?.pair}] [{data?.exchange}]  {data?.id}
        <IconButton onClick={toggleUpdate}><Settings /></IconButton>
      </Typography>
      <Stack direction="row" gap={1}>
        <pre style={{ flex: 1 }}>{YAML.stringify({
          ...data ?? {}, ...status ?? {},
          id: undefined, type: undefined, exchange: undefined, pair: undefined, logs: undefined,
          market: undefined,
          config: undefined, restartId: undefined, error: undefined, text: undefined
        }, null, 2)}</pre>
        <pre style={{ flex: 1 }}>{YAML.stringify(data?.config ?? {}, null, 2)}</pre>
      </Stack>

      <Stack direction="row" gap={1} flexWrap="wrap" justifyContent="stretch">
        <Paper style={{ flex: 1, padding: "1.5em 1em" }} elevation={2}>
          <BotQuickStatus data={data} reload={reload} status={status} />
        </Paper>
        <Paper style={{ flex: 1, padding: "1.5em 1em" }} elevation={2}>
          <BotQuickTradeSummary data={data} reload={reload} status={status} />
        </Paper>
      </Stack>

      <BotUpdateDialog onClose={toggleUpdate} open={updateDialog} preData={data} reload={reload} />
    </Paper>
  </Stack>
}

function BotQuickLogs({ botId }: { botId: string; }) {
  const intervalToken = useIntervalReload(5000)
  const { value: status } = useAsync(
    () => APIBOTGetStatus(botId),
    [botId, intervalToken, intervalToken]
  )

  return <Paper sx={{ padding: "0", flex: 1, width: "100%", position: "relative", "pre  > div": { borderBottom: "1px solid #8884", py: "3px" } }}>
    <QuickLocks
      logs={[...status?.logs ?? []].reverse()} style={{
        overflow: "auto", zIndex: 1, width: "100%", margin: 0, height: "30em", background: "rgba(0, 0, 0, 0.02)", fontSize: "",
      }} />
  </Paper>
}
