import { TableCell, TableRow } from "@mui/material";
import { ManageSearchOutlined } from "@mui/icons-material";


export const NoRecord: React.FC = ({ }) => <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
  <TableCell align="center" colSpan={8} sx={{ p: "2em", opacity: 0.8 }}>
    <br />
    <ManageSearchOutlined color="inherit" style={{ opacity: 0.4, fontSize: "5em" }} />
    <br />
    No record ...
  </TableCell>
</TableRow>;
