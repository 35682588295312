import { useAsync } from "react-use";
import { APIExchangeOrderhistory } from "../../apis/exchange";
import { useEffect, useMemo } from "react";
import { useAsyncWithCached } from "../../hook/useAsyncWithCached";
import { APIKeyList } from "../../apis/keys";
import { prettyNumber } from "../../format/date";
import { APIBOTList } from "../../apis/bots";

export const summaryList = (e: any[], ALL_ASSETS = []) => {
  const allBUY = e.filter(e => e.side == "BUY")
  const allSELL = e.filter(e => e.side == "SELL")
  const TOTAL_BUY_VND = allBUY.map(e => e.executed * (e.fill_avg || e.price)).reduce((e, f) => e + f, 0)
  const TOTAL_SELL_VND = allSELL.map(e => e.executed * (e.fill_avg || e.price)).reduce((e, f) => e + f, 0)
  return {
    ...Object.fromEntries(ALL_ASSETS.map(ASSET => [
      `VOL_${ASSET}`,
      (() => {
        const f = e
          ?.filter(e => e.pair?.split("_")?.[0] == ASSET)
        return [
          prettyNumber(f?.map(e => (e.side == "BUY" ? 1 : -1) * e.executed)
            ?.reduce((e, f) => e + f, 0)
            ?? 0, 0, true).padStart(5, " "),
          prettyNumber(f?.map(e => e.executed)
            ?.reduce((e, f) => e + f, 0)
            ?? 0).padStart(5, " "),
        ].join("/")
      })(),
    ])),
    ...Object.fromEntries(ALL_ASSETS.map(ASSET => [
      `VAL_${ASSET}`,
      (() => {
        const f = e
          ?.filter(e => e.pair?.split("_")?.[0] == ASSET)
        return [
          prettyNumber(f?.map(e => (e.side == "BUY" ? -1 : 1) * e.executed * (e.fill_avg ?? e.price))
            ?.reduce((e, f) => e + f, 0)
            ?? 0, 0, true).padStart(5, " "),
          prettyNumber(f?.map(e => e.executed * (e.fill_avg ?? e.price))
            ?.reduce((e, f) => e + f, 0)
            ?? 0).padStart(5, " "),
        ].join("/")
      })(),
    ])),
    [`BUY/SELL`]: [TOTAL_BUY_VND, TOTAL_SELL_VND]
      .map(e => prettyNumber(e))
      .map(e => e.padStart(6, " ")).join("/"),
    [`CHG/VAL`]: [
      prettyNumber(TOTAL_SELL_VND - TOTAL_BUY_VND ?? 0, 0, true).padStart(6, " "),
      prettyNumber(TOTAL_SELL_VND + TOTAL_BUY_VND ?? 0, 0).padStart(6, " "),
    ].join("/"),

  }
}

export const useTradeSummaryState = ({
  id, exchange, pairs, filteredSymbols = undefined
}: {
  id: string, exchange: string, pairs: string[], filteredSymbols?: string[]
}, groupBy: (f: { timestamp: number }) => string) => {


  const { value: history, error, loading, loadingCache } = useAsyncWithCached(
    (cached) => APIExchangeOrderhistory({ account: id, exchange: exchange, filter: { pair: pairs ?? undefined} }, cached)()
      .then(f => f.map(g => ({ ...g, account: id, exchange: exchange })))
      .catch(e => [])
      ?? Promise.resolve([]),
    [id, exchange, pairs]
  );

  const filteredHistory = useMemo(
    () => history
      ?.filter(e => ["FILLED", "PARTIAL_FILL", "OPEN"].includes(e.status) && e.executed > 0)
      ?.filter(e => filteredSymbols?.includes(e.pair?.split("_")?.[0]))
      ?.sortBy(e => e.timestamp ?? 0),
    [history, filteredSymbols]
  );

  const allAssetSymbols = useMemo(
    () => filteredSymbols ?? [...new Set(history?.map(f => f.pair?.split("_")?.[0] as "") ?? [])] as string[],
    [filteredSymbols, history]
  );

  const grouppedData = useMemo(
    () => Object
      .entries((filteredHistory as any[])?.groupBy(groupBy) || []),
    [filteredHistory, groupBy]
  );

  const summaryData = useMemo(
    () => [
      ...grouppedData,
      ["ALL", filteredHistory ?? []],
    ].map(([date, trades]: any) => ({
      date,
      ...summaryList(trades, allAssetSymbols),
    })),
    [filteredHistory, grouppedData, allAssetSymbols]
  );

  const fields = useMemo(
    () => Object
      .keys(summaryData?.[0] ?? {})
      .filter(e => (summaryData?.at(-1)?.[e] ?? ' +0.00 / 0.00') != ' +0.00 / 0.00'),
    [summaryData?.[0]?.[1], allAssetSymbols]
  );

  return { summaryData, fields, error, loading, loadingCache, filteredHistory };
};
