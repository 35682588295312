import { Box, Button, FormLabel, InputBase, Paper, Slide, Slider, Stack, StackProps, TextField, Typography } from "@mui/material";
import { memoize } from "lodash";
import { useCallback, useMemo, useState } from "react";
import YAML from "yaml"
import { useCalcState, FormBlock } from "./useCalcState";
import "../../@polyfill/Number"
import { ChevronRight } from "@mui/icons-material";
import { revertMath } from "../../utils";


const prettyNumber = (e: any, a = 0) => {
  if(!isFinite(e))
    return String(e)
  const s = +e * (10 ** a)
  if (s >= 1e9) {
    return `${(s / 1e9).toFixed(2)}b`
  } else if (s >= 1e8) {
    return `${(s / 1e6).toFixed(0)}m`
  } else if (s >= 1e6) {
    return `${(s / 1e6).toFixed(2)}m`
  } else if (s >= 1000) {
    return `${(s / 1e3).toFixed(0)}k`
  } else {
    return (+s).toFixed(4)
  }
}


export const roundBy = (e: number, f = 1) => {
  return Math.round(e / f) * f
}

export const BothTextAndSlider: React.FC<StackProps & { values: string[], onChanges?: any, titles?: string[], min?: number, max?: number, step?: number }> = ({ values, onChanges, titles, min = 0, max = Infinity, step, ...rest }) => {

  const onChangeFactory = useMemo(
    () => memoize((index: number) => ({ target: { value = "0" } = {} } = {}) => onChanges?.(
      values => values
        .map((e: string, i: number) => index == i ? value : e)
    )),
    [onChanges]
  )

  return <Stack direction="row" gap={1} {...rest}>
    <Stack direction="row" flex={1} gap={1}>
      {values.map((value, index) => <TextField
        type="number"
        inputProps={{ step } as any}
        sx={{ flex: 1 }}
        onChange={onChangeFactory(index)}
        size="small" value={String(value)} variant="outlined" label={titles?.[index]} />)}
    </Stack >
    <Stack direction="row" flex={1} gap={1}>
      <fieldset style={{ width: "100%", border: "solid 1px rgba(0, 0, 0, 0.23)", padding: "0.42em 0.5em", margin: "0" }}>
        <Stack direction="row" alignItems="center" gap={1} paddingX="0.4em">
          {isFinite(min) && min > 0 && <Typography>{prettyNumber(min)}</Typography>}
          <Slider
            step={step}
            disableSwap
            value={values.map(e => +e)} size="small" min={min} max={max}
            style={{ padding: "0", margin: "0 0.3em" }}
            onChange={(event, values) => onChanges?.(() => values)}
          />
          {isFinite(max) && <Typography>{prettyNumber(max)}</Typography>}
        </Stack>
      </fieldset>
    </Stack >
  </Stack>
}


export function calc({ min, max, asset = 0, fund = 0 }) {
  if (typeof asset == 'number' && asset > 0) {
    const rate = Math.sqrt(max / min)
    const assetAtHighPrice = asset / (rate - 1)
    const fundAtHighPrice = assetAtHighPrice * max
    const assetAtLowPrice = assetAtHighPrice + asset
    const fundAtLowPrice = assetAtLowPrice * min
    const kConstant = assetAtHighPrice * fundAtHighPrice

    return {
      assetAtHighPrice,
      fundAtHighPrice,
      assetAtLowPrice,
      fundAtLowPrice,
      kConstant,
      // c:`typeof maxAsset == 'number' && ${maxAsset} > 0`
    }
  } else if (typeof fund == 'number' && fund > 0) {
    const rate = Math.sqrt(max / min)
    const fundAtLowPrice = fund / (rate - 1)
    const assetAtLowPrice = fundAtLowPrice / min
    const fundAtHighPrice = fundAtLowPrice + fund
    const assetAtHighPrice = fundAtHighPrice / max
    const kConstant = assetAtHighPrice * fundAtHighPrice
    return {
      assetAtHighPrice,
      fundAtHighPrice,
      assetAtLowPrice,
      fundAtLowPrice,
      kConstant,
      // c:`typeof maxFund == 'number' && ${maxFund} > 0`
    }
  } else {
    return {
      assetAtHighPrice: 0,
      fundAtHighPrice: 0,
      assetAtLowPrice: 0,
      fundAtLowPrice: 0,
      kConstant: 0,
    }
  }
}




const useSettingFactory = (state, setState, field, transfer = e => e) => {
  return [
    state?.[field],
    useCallback(
      (valueOrCB) => typeof valueOrCB == 'function'
        ? setState(s => ({ ...s, [field]: transfer(valueOrCB(s[field] ?? undefined)) }))
        : setState(s => ({ ...s, [field]: transfer(valueOrCB) })),
      [field, setState]
    ),
  ]
}

const useSetStateFactory = (setState: any, transfer = e => e) => {
  return useMemo(
    () => memoize(
      (field: string) => {
        return (valueOrCB) => typeof valueOrCB == 'function'
          ? setState(s => ({ ...s, [field]: transfer(valueOrCB(s[field] ?? undefined)) }))
          : setState(s => ({ ...s, [field]: transfer(valueOrCB) }))
      }
    ),
    [setState]
  )
}






export const SettingForm = ({ state, setState }) => {
  const setFactory = useSetStateFactory(setState, e => e.target.value)
  return <div>
    <FormLabel className="title-hr" sx={{ my: 1 }}>MM Config</FormLabel>
    <Stack direction="row" flex={1} gap={1}>
      <TextField label={"Min Gap (%)"}
        type="number" sx={{ flex: 1 }} inputProps={{ step: 0.05, min: 0 }}
        onChange={setFactory("gap")} value={state.gap} />
      <TextField label={"Min Amount"}
        type="number" sx={{ flex: 1 }} inputProps={{ step: 10, min: 10 }}
        onChange={setFactory("minAmount")} value={state.minAmount} />
      <TextField label={"Price Step"}
        type="number" sx={{ flex: 1 }} inputProps={{ step: 10, min: 10 }}
        onChange={setFactory("priceStep")} value={state.priceStep} />
    </Stack >
  </div>
}


export const KConfigForm = ({ setting, state, setState }: any) => {
  const priceStep = +setting.priceStep ?? 1
  const minAmount = +setting.minAmount ?? 1
  const minQuote = priceStep * minAmount

  const [{ lastEdit, localEdit }, setEditor] = useState(() => ({ lastEdit: [], localEdit: { ...state } }))

  const updateCb = useCallback(
    (field: string, value) => {

      setEditor(({ lastEdit = [], localEdit = {} }) => {
        lastEdit = [field, ...(lastEdit ?? []).filter(e => e != field).slice(0, 2)]
        localEdit = { ...localEdit, [field]: value }
        const [lastField, preField] = lastEdit

        console.log("lastEdit >>", ...lastEdit)

        switch (lastField) {
          case "maxFund": {
            setState(state => {
              const { kConstant } = calc({ min: +state.minPrice, max: +state.maxPrice, fund: +value })
              return {
                ...state,
                maxFund: +value,
                maxAsset: (Math.sqrt(kConstant / +state.minPrice) - Math.sqrt(kConstant / +state.maxPrice)).roundByUnit(minAmount),
              }
            })
          }; break;
          case "maxAsset": {
            setState(state => {
              const { kConstant } = calc({ min: +state.minPrice, max: +state.maxPrice, asset: +value })
              return {
                ...state,
                maxAsset: +value,
                maxFund: (Math.sqrt(kConstant * +state.maxPrice) - Math.sqrt(kConstant * +state.minPrice)).roundByUnit(minQuote),
              }
            })
          }; break;
          case "range": {
            setState(state => {
              const preField = lastEdit.find(e => e == "maxPrice" || e == "minPrice") ?? "minPrice"
              return {
                ...state,
                range: +value,
                ...preField == "maxPrice" ? { minPrice: (+state.maxPrice / (1 + +value * 0.01)).roundByUnit(priceStep) } : {},
                ...preField == "minPrice" ? { maxPrice: (+state.maxPrice * (1 + +value * 0.01)).roundByUnit(priceStep) } : {},
              }
            })
          } break;
          case "minPrice": case "maxPrice": {
            setState(state => {
              const { kConstant } = calc({ min: +state.minPrice, max: +state.maxPrice, fund: +state.maxFund })
              const f: any = {
                ...state,
                [lastField]: +value,
                ...preField == "range" && field == "maxPrice" ? { minPrice: (+value / (1 + state.range * 0.01)).roundByUnit(priceStep) } : {},
                ...preField == "range" && field == "minPrice" ? { maxPrice: (+value * (1 + state.range * 0.01)).roundByUnit(priceStep) } : {},
              }

              if (kConstant > 0) {
                f.maxAsset = (Math.sqrt(kConstant / +state.minPrice) - Math.sqrt(kConstant / +state.maxPrice)).roundByUnit(minAmount)
                f.maxFund = (Math.sqrt(kConstant * +state.maxPrice) - Math.sqrt(kConstant * +state.minPrice)).roundByUnit(minQuote)
              } else {
                f.maxAsset = "0"
                f.maxFund = "0"
              }

              return {
                ...state,
                ...f
              }
            })
          } break;
          default:
            setState(state => ({ ...state, [field]: +value }))
        }

        return {
          lastEdit,
          localEdit,
        }
      })
    },
    [setEditor, setState, priceStep, minAmount, minQuote]
  )

  const localState = useMemo(
    () => {
      const { kConstant } = calc({ min: +state.minPrice, max: +state.maxPrice, fund: +state.maxFund })
      const middlePrice = Math.sqrt(state.minPrice * state.maxPrice).roundByUnit(priceStep)
      const middlePrice1Percent = middlePrice * 1.01
      // const middlePrice2Percent = middlePrice * 1.02
      // const fund1Percent = 9
      //k = amount * amount * price => amount = Math.sqrt(k / price)
      //  Math.sqrt(k * price)
      return {
        ...state,
        range: (state.maxPrice / state.minPrice * 100 - 100).toFixed(2) ?? "",
        fund1P: (Math.sqrt(kConstant * middlePrice1Percent) - Math.sqrt(kConstant * middlePrice)).roundByUnit(minQuote),
        asset1P: (Math.sqrt(kConstant / middlePrice) - Math.sqrt(kConstant / middlePrice1Percent)).roundByUnit(minAmount),
        // [lastField]: localEdit[lastField] ?? state[lastField]
      }
    },
    [state, lastEdit, localEdit]
  )

  console.table({ state, localState })



  const setFactory = useMemo(
    () => memoize(field => e => updateCb(field, e.target.value)),
    [updateCb]
  )


  return <div>
    <FormLabel className="title-hr" sx={{ my: 1 }}>k-Constant</FormLabel>
    <Stack gap={1}>
      <Stack direction="row" gap={1}>
        <TextField label={"Min Price"}
          type="number" sx={{ flex: 1 }} inputProps={{ step: priceStep, min: priceStep }}
          onChange={setFactory("minPrice")} value={localState.minPrice} />
        <TextField label={"Range %"} type="number" sx={{ flex: 1 }} value={localState.range} onChange={setFactory("range")} inputProps={{ step: 1, min: 0 }} />
        <TextField label={"Max Price"}
          type="number" sx={{ flex: 1 }} inputProps={{ step: priceStep, min: priceStep }}
          onChange={setFactory("maxPrice")} value={localState.maxPrice} />
      </Stack>
      <Stack direction="row" gap={1}>
        <TextField label={"Delta Fund"}
          type="number" sx={{ flex: 1 }} inputProps={{ step: minQuote, min: minQuote }}
          onChange={setFactory("maxFund")} value={localState.maxFund} />
        <TextField label={"Liquidity 1%"} type="number" sx={{ flex: 1 }} value={localState.fund1P} InputProps={{ disabled: true }} />
      </Stack>
      <Stack direction="row" gap={1}>
        <TextField label={"Delta Asset"}
          type="number" sx={{ flex: 1 }} inputProps={{ step: minAmount, min: minAmount }}
          onChange={setFactory("maxAsset")} value={localState.maxAsset} />
        <TextField label={"Liquidity 1%"} type="number" sx={{ flex: 1 }} InputProps={{ disabled: true }} value={localState.asset1P} />
      </Stack>
    </Stack>
  </div>
}

export const MMConfig = ({ state, setState, kConfig }) => {
  const setFactory = useSetStateFactory(setState, e => e.target.value)
  const [collateral, setCollateral] = useState("100")

  const [lowLiquid, highLiquid] = useMemo(
    () => {
      const { kConstant } = calc({ min: +kConfig.minPrice, max: +kConfig.maxPrice, fund: +kConfig.maxFund })
      const maxLost = +collateral * 0.7
      const initPrice = state?.initPrice
      const initPosition = state?.initPosition
      const lostCalc = (currentPrice: number) => {
        let deltaCash = Math.sqrt(kConstant * currentPrice) - Math.sqrt(kConstant * initPrice)
        let deltaAmount = Math.sqrt(kConstant / currentPrice) - Math.sqrt(kConstant / initPrice)
        return deltaAmount * currentPrice + deltaCash + (initPosition) * (currentPrice - initPrice)
      }
      const rev = revertMath(lostCalc)
      return [
        rev(-maxLost, initPrice / 1000),
        rev(-maxLost, initPrice * 1000),

      ]
    },
    [kConfig, state, collateral]
  )

  return <div>
    <FormLabel className="title-hr" sx={{ my: 1 }}>Configs</FormLabel>
    <Stack flex={1} gap={1}>

      <Stack direction="row" flex={1} gap={1}>
        <TextField label={"Init Price"}
          type="number" sx={{ flex: 1 }} inputProps={{ step: 0.05, min: 0 }}
          onChange={setFactory("initPrice")} value={state.initPrice} />
        <TextField label={"Init Position"}
          type="number" sx={{ flex: 1 }} inputProps={{ step: 10, min: 10 }}
          onChange={setFactory("initPosition")} value={state.initPosition} />
        <TextField label={"Collateral Fund"}
          type="number" sx={{ flex: 1 }} inputProps={{ step: 10, min: 10 }}
          onChange={e => setCollateral(e.target.value)} value={collateral} />
      </Stack >
      <Stack direction="row" flex={1} gap={1}>
        <TextField label={"Liquidation Low Price"}
          sx={{ flex: 1 }} inputProps={{ disabled: true }} value={(lowLiquid).toFixed(4)} />
        <TextField label={"Liquidation High Price"}
           sx={{ flex: 1 }} inputProps={{ disabled: true }} value={(highLiquid).toFixed(4)} />
      </Stack >
    </Stack >
  </div>
}



export const CalcHelperV2: React.FC<{ preConfig?: ReturnType<typeof useCalcState>["computedState"], onApply?: Function }> = ({ preConfig, onApply }) => {

  const [config, setConfig] = useState(() => ({
    setting: {
      gap: "1",
      minAmount: "0.01",
      priceStep: "0.01"
    },
    config: {
      k: {
        minPrice: "1000",
        maxPrice: "2000",
        maxFund: "",
        maxAsset: "",
        kConstant: ""
      },
      mmConfig: {
        initPrice: "",
        initPosition: "0",
      },
      runtime: {
        buySize: 3,
        sellSize: 3,
        interval: 60,
      }

    }
  }))

  const [setting, setSetting] = useSettingFactory(config, setConfig, "setting")
  const [botconfig, setBotConfig] = useSettingFactory(config, setConfig, "config")
  const [kconfig, setKConfig] = useSettingFactory(botconfig, setBotConfig, "k")
  const [mmconfig, setMMConfig] = useSettingFactory(botconfig, setBotConfig, "mmConfig")

  return <>
    <Stack direction="column" gap={2}>
      <SettingForm state={setting} setState={setSetting} />
      <KConfigForm state={kconfig} setState={setKConfig} setting={setting} />
      <MMConfig state={mmconfig} setState={setMMConfig} kConfig={kconfig} />
    </Stack>

  </>;
};
