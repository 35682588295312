import { Button, ButtonProps, Dialog, DialogContent, DialogTitle, FormHelperText, Paper, Stack, TextField, Typography } from "@mui/material";
import { APIExchangeAuthorize, APIExchangeBalance, APIExchangeCancelall, APIExchangeGetOTP } from "../../apis/exchange";
import { ChangeEvent, useCallback, useMemo, useState } from "react";
import { memoize } from "lodash";
import { LoadingButton } from "@mui/lab";
import { useLoading } from "../../hook/useLoading";
import { APIBOTUpdate } from "../../apis/bots";
import { useRefValue } from "../../hook/useRefValue";
import { useAsync, useToggle } from "react-use";
import { CalcHelper, calc } from "./CalcHelper";
import YAML from "yaml"
import { CalcHelperV2 } from "./CalcHelperV2";


export const CalcHelperButton: React.FC<ButtonProps & { preState: any, onApply?: any }> = ({ preState, onApply, ...props }) => {
  const [helpModal, toggleHelpModal] = useToggle(false)

  return <>
    <Button onClick={toggleHelpModal} {...props} />
    <Dialog open={helpModal} onClose={toggleHelpModal} maxWidth="lg">
      <DialogTitle> Market Maker Calculator Helper </DialogTitle>
      <DialogContent>
        {helpModal && <CalcHelper preState={preState} onApply={onApply ? (e) => (onApply(e), toggleHelpModal(false)) : undefined} />}
      </DialogContent>
    </Dialog>
  </>
}

export const CalcHelperButtonV2: React.FC<ButtonProps & { preConfig: any, onApply?: any }> = ({ preConfig, onApply, ...props }) => {
  const [helpModal, toggleHelpModal] = useToggle(false)

  return <>
    <Button onClick={toggleHelpModal} {...props} />
    <Dialog open={helpModal} onClose={toggleHelpModal} maxWidth="lg">
      <DialogTitle> Market Maker Calculator Helper </DialogTitle>
      <DialogContent>
        {helpModal && <CalcHelperV2 preConfig={preConfig} onApply={onApply ? (e) => (onApply(e), toggleHelpModal(false)) : undefined} />}
      </DialogContent>
    </Dialog>
  </>
}

export const BotUtils: React.FC<{ botId: string; token: any; reload: any; data: any; }> = ({ botId, token, data, reload }) => {

  const { value: balances, error } = useAsync(() => APIExchangeBalance({ botId }, true)(), [botId, token]);

  const baseSymbol = useMemo(
    () => data?.pair?.trim()?.toUpperCase()?.split("_")?.[0] ?? "",
    [data?.pair]
  )
  const assetBalance = useMemo(
    () => balances?.find(e => baseSymbol == e.currency)?.total,
    [balances, data?.pair]
  )

  const preState = useMemo(
    () => {
      const { kConstant } = calc({ min: data?.config?.minPrice, max: data?.config?.maxPrice, asset: data?.config?.maxAsset, });
      return ({
        prices: [
          data?.config?.minPrice,
          kConstant / ((Math.sqrt(kConstant / data?.config?.maxPrice) + +assetBalance) ** 2),
          data?.config?.maxPrice
        ],
        maxHoldAsset: [data?.config?.maxAsset],
        maxHoldFund: [data?.config?.maxFund],
        currentAsset: [""],
        currentVND: [""],
      })
    },
    [assetBalance, data?.config]
  )

  return <>
    <Stack direction="row" gap={2}>
      <CalcHelperButton style={{ whiteSpace: "nowrap" }} preState={preState}>MM Calc</CalcHelperButton>
      <CalcHelperButtonV2 style={{ whiteSpace: "nowrap" }} preConfig={data?.config}>MM Calc</CalcHelperButtonV2>
    </Stack>
  </>

}

export const BotSystem: React.FC<{ botId: string; token: any; reload: any; data: any; }> = ({ botId, token, data, reload }) => {

  const refData = useRefValue({ botId })

  const handleUpdate = useCallback(async (data: any) => {
    console.log("APIBOTUpdate", { id: refData.botId, ...data })
    await APIBOTUpdate({ id: refData.botId, ...data });
    reload?.();
  }, [refData])

  const [
    [handleStart, { loading: starting }],
    [handleStop, { loading: stoping }],
    [handleRestart, { loading: restarting }],
    [handleCancelAll, { loading: cancelAlling }],
  ] = [
      useLoading(useCallback(() => handleUpdate({ enable: true }), [])),
      useLoading(useCallback(() => handleUpdate({ enable: false }), [])),
      useLoading(useCallback(() => handleUpdate({ restartId: String(Math.random()) }), [])),
      useLoading(useCallback(() => APIExchangeCancelall({ botId: refData.botId })(), [refData])),
    ]

  return <Stack direction="row" gap={2}>
    <LoadingButton disabled={!data || data?.enable} onClick={handleStart} loading={starting} style={{ flex: 1 }}>Start</LoadingButton>
    <LoadingButton disabled={!data || !data?.enable} onClick={handleStop} loading={stoping} style={{ flex: 1 }}>Stop</LoadingButton>
    <LoadingButton disabled={!data || !data.enable} onClick={handleRestart} loading={restarting} style={{ flex: 1 }}>Restart</LoadingButton>
    <LoadingButton onClick={handleCancelAll} loading={cancelAlling} style={{ flex: 1 }}>Cancel All</LoadingButton>
  </Stack>
}

export const BotActions: React.FC<{ botId: string; token: any; reload: any; data: any; }> = ({ botId, token, data, reload }) => {


  return <Paper style={{ padding: "0.8em", flex: 1, whiteSpace: "nowrap", minHeight:"100%" }}>
    <Typography variant="h6">Actions</Typography>
    <Stack gap={1} direction="column" flexWrap="wrap">
      <fieldset>
        <legend style={{ padding: "0 0.5em" }}>Authorize</legend>
        <BotAuthorize {...{ botId, token, data, reload }} />
      </fieldset>
      <fieldset>
        <legend style={{ padding: "0 0.5em" }}>System</legend>
        <BotSystem {...{ botId, token, data, reload }} />
      </fieldset>
      <fieldset>
        <legend style={{ padding: "0 0.5em" }}>Ultils</legend>
        <BotUtils {...{ botId, token, data, reload }} />
      </fieldset>

    </Stack>
  </Paper>

}

export const BotAuthorize: React.FC<{ botId: string; token: any; reload: any; data: any; }> = ({ botId, token, data, reload }) => {

  const authFields = useMemo(
    () => ({
      ["SSI"]: { otp: "" },
      ["WrappedSSI"]: { otp: "" },
    } as any)[data?.exchange],
    [data?.exchange]
  );

  const [authData, setAuthData] = useState(() => authFields ?? {});


  const onChangeFactory = useMemo(
    () => memoize(field => (newValue: any) => setAuthData((state: any) => ({ ...state ?? {}, [field]: newValue }))),
    []
  );
  const eventWrapper = useMemo(
    () => memoize(cb => (e: ChangeEvent<HTMLInputElement>) => cb(e.target.value)),
    []
  );

  const [onGetOTP, { error: errorOTP, loading: loadingOTP }] = useLoading(useCallback(async () => {
    console.log("[APIExchangeAuthorize]", { botId });
    await APIExchangeGetOTP({ botId })();
    reload?.();
  }, [botId]));


  const [onAuthorize, { error, loading }] = useLoading(useCallback(async () => {
    console.log("[APIExchangeAuthorize]", { botId, authData });
    await APIExchangeAuthorize({ botId })(authData as any);
    reload?.();
  }, [botId, authData]));


  return <>
    <Stack gap={2} direction="row">
      {Object.entries(authFields ?? {}).map(([key, v]) => <>
        <TextField
          style={{ flex: 1 }}
          variant="filled" label={key}
          value={authData?.[key] ?? ""}
          inputProps={{ style: { height: "1em", paddingTop: "0.7em" } }}
          InputLabelProps={{ style: { top: "-8px" } }}
          onChange={eventWrapper(onChangeFactory(key))} />
      </>)}


      <LoadingButton style={{ flex: 1 }} disabled={!authFields} onClick={onGetOTP} loading={loadingOTP}>Send OTP</LoadingButton>
      <LoadingButton style={{ flex: 1 }} disabled={!authFields} onClick={onAuthorize} loading={loading}>Authorize</LoadingButton>
    </Stack>
    {error && <FormHelperText error>{String(error)}</FormHelperText>}
    {errorOTP && <FormHelperText error>{String(errorOTP)}</FormHelperText>}
  </>;
};
