import { useCallback, useState } from "react"


export const useLoading = <T extends Function>(e: T): [T, { loading: boolean, error: string }] => {

  const [{ loading, error }, setLoading] = useState({ loading: false, error: "" })



  return [useCallback(async (...params: any[]) => {
    try {
      setLoading({ error: "", loading: true })
      let result = await e(...params);
      setLoading({ error: "", loading: false })

      return result
    } catch (error) {
      setLoading({ error: String(error), loading: false })
    }
  }, [e]) as any as T, { loading, error }]

}