
declare global {
  interface Number {
    roundBy(decimal: number): number
    roundByUnit(decimal: number): number
    ceilBy(decimal: number): number
    ceilByUnit(unit: number): number
    floorBy(decimal: number): number
    floorByUnit(unit: number): number
    rangeBy(min: number, max: number): number
  }
}



Object.defineProperty(Number.prototype, 'roundBy', {
  enumerable: false,
  value: function (decimal: number) {
    var tmp = (10 ** decimal);
    return Math.round(this * tmp) / tmp
  }
});

Object.defineProperty(Number.prototype, 'roundByUnit', {
  enumerable: false,
  value: function (unit: number) {
    return Math.round(this / unit) * unit
  }
});

Object.defineProperty(Number.prototype, 'floorByUnit', {
  enumerable: false,
  value: function (unit: number) {
    return Math.floor(this / unit) * unit
  }
});
Object.defineProperty(Number.prototype, 'ceilByUnit', {
  enumerable: false,
  value: function (unit: number) {
    return Math.ceil(this / unit) * unit
  }
});

Object.defineProperty(Number.prototype, 'ceilBy', {
  enumerable: false,
  value: function (decimal: number) {
    var tmp = (10 ** decimal);
    return Math.ceil(this * tmp) / tmp
  }
});

Object.defineProperty(Number.prototype, 'floorBy', {
  enumerable: false,
  value: function (decimal: number) {
    var tmp = (10 ** decimal);
    return Math.floor(this * tmp) / tmp
  }
});


Object.defineProperty(Number.prototype, 'rangeBy', {
  enumerable: false,
  value: function (min, max) {
    return Math.max(min, Math.min(max, this))
  }
});


export { }