import React, { useMemo } from "react";
import { useIntervalReload } from "../../hook/useReload";

const rtf = new Intl.RelativeTimeFormat('en', { style: 'narrow', localeMatcher: "lookup", });

const ma = [
  [0, e => rtf.format(-e | 0, 'seconds').padStart(10, " ")],
  [60, e => rtf.format(-e / 60 | 0, 'minutes').padStart(10, " ")],
  [3600, e => rtf.format(-e / 3600 | 0, 'hours').padStart(10, " ")],
  [3600 * 24, e => rtf.format(-e / 3600 / 24 | 0, 'days').padStart(10, " ")],
  [3600 * 24 * 7, e => rtf.format(-e / 3600 / 24 / 7 | 0, 'week').padStart(10, " ")],
  // [3600 * 24 * 7, e => rtf.format(-e / 3600 / 24 / 7 | 0, 'week')],
].reverse() as [number, (e: number) => string][]

export const RelativeTime: React.FC<{ timestamp: number; }> = ({ timestamp = undefined }) => {

  useIntervalReload(1000);

  const t = ((timestamp ?? Date.now()) - Date.now()) / 1000 | 0

  const s = useMemo(
    () => -t > 0 ? (ma.find(e => -t > (e[0] as number))?.[1]?.(-t) ?? "") : "now",
    [t]
  )

  return <>{s.padStart(10, " ")}</>;
};
