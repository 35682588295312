import { useAsync, useMeasure } from "react-use";
import { CircularProgress, FormHelperText, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, useTheme } from "@mui/material";
import { APIExchangeOrderhistory } from "../../apis/exchange";
import { formatDate, prettyNumber } from "../../format/date";
import { useEffect, useMemo, useRef } from "react";
import { useAsyncWithCached } from "../../hook/useAsyncWithCached";
import { StatusPanel } from "./StatusPanel";
import { RelativeTime } from "../shared/RelativeTime";
import { calcPL } from "./calcPL";
import { createChart } from 'lightweight-charts';


export const BotOrderHistoryVis: React.FC<{ botId: string; token: any; reload: any; pair: string }> = ({ botId, token, pair }) => {

  const { value: history, error, loading, loadingCache } = useAsyncWithCached(
    (cached) => APIExchangeOrderhistory({ botId, filter: { pair, status: ["FILLED", "PARTIAL_FILL", "OPEN"] } }, cached)(),
    [botId, pair]
  );

  const filteredHistory = useMemo(
    () => history
      ?.filter(e => e.pair == pair && e.executed > 0)
      ?.filter(e => ["FILLED", "PARTIAL_FILL", "OPEN"].includes(e.status))
      ?.sortBy(e => e?.timestamp ?? 0),
    [history, pair]
  )

  const filteredHistoryWithPL = useMemo(
    () => {
      let lastTime = 0
      let volCashBuy = 0, volCashSell = 0, tmp = 0
      let indexLast24h = 0
      return calcPL(filteredHistory)
        .filter(e => (e.timestamp > +lastTime + 3600000) && (lastTime = e.timestamp))
        .map((e, i, a) => (e.timestamp > a[indexLast24h].timestamp + (3600000 * 24) && (indexLast24h += 1), ({ ...e, indexLast24h })))
        .map((e, i, a) => ({
          ...e,
          volCashBuy: (tmp = e.cashOut - volCashBuy, volCashBuy = e.cashOut, tmp),
          volCashSell: (tmp = e.cashIn - volCashSell, volCashSell = e.cashIn, tmp),
          pl24H: e.plStat - (a[e.indexLast24h]?.plStat ?? 0),
        }))
        .map(e => ({
          ...e,
          timestamp: Number(e.timestamp).roundByUnit(3600000),

        }))
    },
    [filteredHistory]
  )

  const chartRef = useRef<HTMLDivElement>(null);

  const [containerRef, { width, height }] = useMeasure() as any;

  const el = useMemo(() => chartRef.current, [chartRef.current]);

  const CHART_HEIGHT = 400

  const { chart } = useMemo(() => {
    if (!!el) {
      el.childNodes.forEach(e => e.remove());
      const chart = createChart(el, {
        width: width,
        height: isFinite(height) && height > 0 ? height : CHART_HEIGHT,
        timeScale: {
          timeVisible: true,

        },
      })
      return { chart };
    } else {
      return { chart: null };
    }

  }, [el]);

  useEffect(() => {
    chart && chart.priceScale("price").applyOptions({
      scaleMargins: {
        top: 0,
        bottom: 0.6
      },
      ticksVisible: true,
      visible: true,
      alignLabels: true,
      borderVisible: true,
    })

    chart && chart.priceScale("pl").applyOptions({
      scaleMargins: {
        top: 0.4,
        bottom: 0.2
      },
      ticksVisible: true,
      visible: true,
      alignLabels: true,
      borderVisible: true,
    })

    chart && chart.priceScale("vol").applyOptions({
      scaleMargins: {
        top: 0.8,
        bottom: 0
      },
      ticksVisible: true,
      visible: true,
      alignLabels: true,
      borderVisible: true,
    })
  }, [chart, width, height]);

  useEffect(() => {
    chart && width && chart.resize(width, isFinite(height) && height > 0 ? height : CHART_HEIGHT, true);
  }, [chart, width, height]);

  const [priceSeries, avgSeries, cumPlSeries, tmpPLSeries, volBuySeries, volSellSeries, plSeries] = useMemo(() => [
    chart?.addLineSeries({
      title: "Match Price", lineWidth: 1,
      priceScaleId: "price",
    }),
    chart?.addLineSeries({
      title: "Avg Price", lineWidth: 1, color: "gray",
      priceScaleId: "price",
    }),
    chart?.addBaselineSeries({
      title: "24H.PL", lineWidth: 1,
      baseValue: {
        type: 'price', price: 0,
      },
      priceLineWidth: 2,
      topLineColor: 'rgba( 38, 166, 154, 1)',
      topFillColor1: 'rgba( 38, 166, 154, 0.28)',
      topFillColor2: 'rgba( 38, 166, 154, 0.05)',
      bottomLineColor: 'rgba( 239, 83, 80, 1)',
      bottomFillColor1: 'rgba( 239, 83, 80, 0.05)',
      bottomFillColor2: 'rgba( 239, 83, 80, 0.28)',
      priceScaleId: "pl",
    }),
    chart?.addBaselineSeries({
      title: "TMP.PL", lineWidth: 1,
      baseValue: {
        type: 'price', price: 0,
      },
      topLineColor: 'rgba( 128, 128, 151284, 1)',
      topFillColor1: 'rgba( 38, 166, 154, 0.28)',
      topFillColor2: 'rgba( 38, 166, 154, 0.05)',
      bottomLineColor: 'rgba( 128, 128, 128, 1)',
      bottomFillColor1: 'rgba( 239, 83, 80, 0.05)',
      bottomFillColor2: 'rgba( 239, 83, 80, 0.28)',
      priceScaleId: "pl",
    }),
    chart?.addHistogramSeries({
      priceScaleId: "vol",
      color: "green"
    }),
    chart?.addHistogramSeries({
      priceScaleId: "vol",
      color: "red"
    }),
    chart?.addHistogramSeries({
      priceScaleId: "pl",
      title: "Settle.PL",
    }),
  ], [chart])


  const [priceDatas, avgPriceDatas, plCumDatas, plTmps, volCashBuy, volCashSell, plDatas] = useMemo(
    () => [
      filteredHistoryWithPL.map(e => ({
        time: ((e.timestamp + 3600000 * 7) / 1000 | 0) as any,
        value: e.fill_avg,
      })),
      filteredHistoryWithPL.map(e => ({
        time: ((e.timestamp + 3600000 * 7) / 1000 | 0) as any,
        value: e.avgPrice,
      })),
      filteredHistoryWithPL.map(e => ({
        time: ((e.timestamp + 3600000 * 7) / 1000 | 0) as any,
        value: +e.pl24H,
      })),
      filteredHistoryWithPL.map(e => ({
        time: ((e.timestamp + 3600000 * 7) / 1000 | 0) as any,
        value: +e.tmpPl,
      })),
      filteredHistoryWithPL.map(e => ({
        time: ((e.timestamp + 3600000 * 7) / 1000 | 0) as any,
        value: -e.volCashBuy,
      })),
      filteredHistoryWithPL.map(e => ({
        time: ((e.timestamp + 3600000 * 7) / 1000 | 0) as any,
        value: +e.volCashSell,
      })),
      filteredHistoryWithPL.map((e, i, a) => ({
        time: ((e.timestamp + 3600000 * 7) / 1000 | 0) as any,
        value: +e.cumPL - (a[i - 1]?.cumPL ?? 0),
      })).map(e => ({
        ...e, color: e.value >= 0 ? "green" : "red"
      })),
    ],
    [filteredHistoryWithPL]
  )

  useEffect(() => {
    priceSeries && priceSeries.setData(priceDatas)
    avgSeries && avgSeries.setData(avgPriceDatas)
    cumPlSeries && cumPlSeries.setData(plCumDatas)
    tmpPLSeries && tmpPLSeries.setData(plTmps)
    volBuySeries && volBuySeries.setData(volCashBuy)
    volSellSeries && volSellSeries.setData(volCashSell)
    plSeries && plSeries.setData(plDatas)
  }, [priceDatas, avgPriceDatas, priceSeries, plTmps])

  useEffect(() => () => { priceSeries && chart?.removeSeries(priceSeries) }, [priceSeries]);
  useEffect(() => () => { avgSeries && chart?.removeSeries(avgSeries) }, [avgSeries]);
  useEffect(() => () => { cumPlSeries && chart?.removeSeries(cumPlSeries) }, [cumPlSeries]);
  useEffect(() => () => { tmpPLSeries && chart?.removeSeries(tmpPLSeries) }, [tmpPLSeries]);
  useEffect(() => () => { volBuySeries && chart?.removeSeries(volBuySeries) }, [volBuySeries]);
  useEffect(() => () => { volSellSeries && chart?.removeSeries(volSellSeries) }, [volSellSeries]);
  useEffect(() => () => { plSeries && chart?.removeSeries(plSeries) }, [plSeries]);

  return <>
    <TableContainer style={{ height: "100%", overflow: "auto", position: "relative", paddingBottom: "2em", }} >
      <div ref={containerRef} style={{ width: "100%", height: "100%", position: "absolute", left: 0, top: 0 }}>
        <div ref={chartRef} />
      </div>
      <StatusPanel show={loading || loadingCache}>
        <CircularProgress size="0.9em" sx={{ px: "0.5em" }} />
        Fetching {loadingCache ? ' cached ' : ' latest '} data ...
      </StatusPanel>
    </TableContainer>
    {error && <FormHelperText error>{String(error)}</FormHelperText>}

  </>;
};



