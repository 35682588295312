import { Button, DialogActions, Divider, Stack, Typography } from "@mui/material"
import { BotCreateDialog, } from "./BotForm"
import { BotList } from "./BotList"
import { useToggle } from "react-use"
import { Add, PlusOneOutlined } from "@mui/icons-material"
import { useReload } from "../../hook/useReload"



export const BotManager: React.FC<{}> = () => {
  const [enableCreateFrom, toggleCreaeteForm] = useToggle(false)
  const { reload, token } = useReload()
  return <>
    <Typography variant="h5">Bot Manager</Typography>
    <br />
    <BotList token={token} reload={reload} />
    <br />
    <Stack direction="row-reverse">
      <Button onClick={toggleCreaeteForm} >
        <Add sx={{ mr: "0.3em" }} /> New Bot
      </Button>
    </Stack>
    {enableCreateFrom && <BotCreateDialog open={enableCreateFrom} onClose={toggleCreaeteForm} reload={reload} />}
  </>
}