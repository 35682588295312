import { Box, Stack, Typography } from "@mui/material";
import React, { memo, useEffect, useMemo } from "react";
import { APIExchangeBalance, APIExchangeOrderhistory } from "../../apis/exchange";
import { useAsyncWithCached } from "../../hook/useAsyncWithCached";
import { calc } from "../botdashboard/CalcHelper";
import { useBotSummaryState, summaryList } from "../botdashboard/BotSummary";
import { calcPL } from "../botdashboard/calcPL";
import { APIMarketPrice } from "../../apis/market";
import { useAsync } from "react-use";
import { prettyNumber } from "../../format/date";
import { useBalanceState } from "../dashboard/useBalanceState";

const colors = (e) => <>
  <span style={{ color: +e > 0 ? "green" : (+e < 0 ? "red" : "gray") }}>{prettyNumber(e, 0, true)}</span>
</>

export const BotQuickTradeSummary: React.FC<{ data: any; reload: any; status: any }> = memo(
  ({ data, reload, status }) => {

    const exchange = data?.exchange
    const account = data?.account
  
    const pair = data?.pair?.trim()
  
    const [base, quote] = pair?.split("_") || [];
  
    const { filteredHistory, error, loading, loadingCache } = useBotSummaryState(
      { exchange, account, pair, token: "" },
    )
  
    const { allBalances } = useBalanceState({ id: account, exchange })
  
    const position = useMemo(() => allBalances?.find(e => e.currency == base), [base, allBalances])
  
    const { value: marketData } = useAsync(() => APIMarketPrice({ exchange, pair })(), [exchange, pair]);
  
    const summaryData = useMemo(
      () => {
        let today = new Date().toLocaleDateString()
        let thismmonth = new Date().getMonth() + ":" + new Date().getFullYear()
        return [
          { date: "Today ", ...summaryList((filteredHistory || []).filter(e => new Date(+e?.timestamp ?? 0).toLocaleDateString() == today)) },
          { date: "This month", ...summaryList((filteredHistory || []).filter(e => new Date(+e?.timestamp ?? 0).getMonth() + ":" + new Date(+e?.timestamp ?? 0).getFullYear() == thismmonth)) },
          { date: "All time", ...summaryList((filteredHistory || [])) },
        ]
      },
      [filteredHistory]
    )
  
    const plData = useMemo(
      () => {
        const recordWithPL = calcPL(filteredHistory)
        const tail = recordWithPL?.tail()
        return {
          settlePL: tail?.cumPL,
          avgPrice: tail?.avgPrice || NaN,
          cumToken: position?.total ?? recordWithPL?.map(e => e.side == "BUY" ? e.executed : -e.executed)?.reduce((e, f) => e + f, 0) ?? 0,
          cumCash: recordWithPL?.map(e => e.side == "BUY" ? -e.executed * e.fill_avg : e.executed * e.fill_avg)?.reduce((e, f) => e + f, 0) ?? 0,
        }
      }, [filteredHistory, position]
    )
  
    const unSettlePL = plData?.cumToken * (marketData?.currentPrice - plData?.avgPrice)
  
    return <Box flex={1} sx={{ fontSize: "0.9em", margin: { md: "-0.8em 0", sx: "" } }}>
      <Stack direction="row" spacing={2} >
        {
          summaryData
            .map(data => <Box textAlign="left" sx={{ "*": { fontSize: "0.7em", whiteSpace: "nowrap" } }} >
              <div style={{ opacity: 0.5, fontSize: "0.8em", }}>{data.date}</div>
              <div>{data["CHG / VOL"]} <code style={{ opacity: 0.5 }}>{base?.slice(0, 3)}</code></div>
              <div>{data["CHG / VOL (CASH)"]} <code style={{ opacity: 0.5 }}>{quote?.slice(0, 3)}</code></div>
            </Box>)
        }
        <Box textAlign="left" sx={{ "*": { fontSize: "0.7em", whiteSpace: "nowrap" } }} >
          <div style={{ opacity: 0.5, fontSize: "0.8em", }}>P/L {quote?.slice(0, 3)}</div>
          <div><code>{colors(plData?.settlePL)}</code> <code style={{ opacity: 0.5 }}>Settled</code></div>
          <div><code>{colors(unSettlePL)}</code> <code style={{ opacity: 0.5 }}>Unsettle</code></div>
        </Box>
      </Stack>
    </Box >;
  }
)
