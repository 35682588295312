import { Box, Button, Chip, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, LinearProgress, Modal, Paper, Stack, Switch, Tooltip, Typography } from "@mui/material"
import { useAsync, useDebounce, useInterval, useKey, useKeyPress, useToggle } from "react-use"
import ReactJson from 'react-json-view'
import { BorderColor, Circle, DeleteForever, DeleteOutline, Edit, EditAttributes, Info, InfoOutlined, ListAltOutlined, Terminal, PowerSettingsNew } from "@mui/icons-material"
import React, { useCallback, useDebugValue, useDeferredValue, useEffect, useMemo, useState } from "react"
import { useLoading } from "../../hook/useLoading"
import { APIBOTList, APIBOTRemove, APIBOTAllStatus, APIBOTUpdate } from "../../apis/bots"
import { useIntervalReload } from "../../hook/useReload"
import { Link } from "react-router-dom"
import { ButtonWithConfirm } from "../shared/ButtonWithConfirm"
import { relative } from "path"
import { BotQuickStatus } from "./BotQuickStatus"
import { BotQuickLogs } from "./BotQuickLogs"

import RestartAltIcon from '@mui/icons-material/RestartAlt';
import { useWsClient } from "../../hook/useWs"
import { BotQuickFilter } from "./BotQuickFilter"
import { BotQuickTradeSummary } from "./BotQuickTradeSummary"

export const BotList: React.FC<{ token?: any, reload: any }> = ({ token, reload }: any) => {
  const intervalToken = useIntervalReload(20000, token)
  const { value: list } = useAsync(APIBOTList, [token])
  const [[filterFund], setFilterFund] = useState([(e: any) => true])
  const { value: status1 } = useAsync(
    () => APIBOTAllStatus()
      .then(status => ({ status, timestamp: Date.now() })) as Promise<{ status: [], timestamp }>,
    [token, intervalToken]
  )
  const wsClient = useWsClient()

  const [status2, setStatus2] = useState<{ status: [], timestamp }>({ timestamp: 0, status: [] })

  useEffect(() => {
    wsClient.on("allStatus", status => setStatus2({ status, timestamp: Date.now() }))
  }, [wsClient])


  const statusIndexed = useMemo(
    () => ({
      ...Object.fromEntries(status1?.status?.map((e: any) => [e?.id, e]) ?? []),
      ...Object.fromEntries(status2?.timestamp > status1?.timestamp
        ? (status2?.status?.map((e: any) => [e?.id, e]) ?? [])
        : (status1?.status?.map((e: any) => [e?.id, e]) ?? [])
      ),
    }),
    [status1?.timestamp, status2?.timestamp]
  )

  const filteredList = useMemo(
    () => list?.filter(filterFund),
    [list, filterFund]
  )

  return <>
    <BotQuickFilter list={list} setFilterFund={setFilterFund} />
    {filteredList?.map((data: any) => <BotRowItem data={data} reload={reload} status={statusIndexed?.[data?.id]} key={data?.id} />)}
  </>
}


const BotInfoDialog: React.FC<{ enable: boolean, setToggle: any, data: any, }> = ({ enable, setToggle, data, }) => {
  return <Dialog open={enable} onClose={setToggle} style={{ padding: 0 }} maxWidth="md">
    <DialogTitle>CONFIG</DialogTitle>
    <DialogContent>
      <BotInfo data={data} />
    </DialogContent>
    <DialogActions>
      <Button onClick={setToggle}>CLOSE</Button>
    </DialogActions>
  </Dialog>;
}


export const BotInfo: React.FC<{ data: any, style?: any }> = ({ data, style }) => {
  return <ReactJson
    style={{
      padding: "0.3em", flex: 1, overflow: "auto", background: "#00000008",
      ...(style || {})
    }}
    src={data?.config} enableClipboard={false} name=""
    collapseStringsAfterLength={20} quotesOnKeys={false} displayDataTypes={false}
  />
}

export const BotRowItem: React.FC<{ data: any, status: any, reload: any }> = ({ data, reload, status }) => {


  const [onDelete, { loading: deleting, error }] = useLoading(useCallback(
    async () => {
      await APIBOTRemove(data?.id);
      reload?.()
    },
    [data?.id]
  ))


  const [onRestart, { loading: restarting }] = useLoading(
    useCallback(
      async () => {
        await APIBOTUpdate({ id: data?.id, restartId: Math.random() })
        reload?.()
      },
      [data?.id]
    )
  )

  const [onToggleSwich, { loading: toggleing }] = useLoading(
    useCallback(
      async (enable) => {
        await APIBOTUpdate({ id: data?.id, enable: Boolean(enable) })
        await new Promise(r => setTimeout(r, 2000))
        reload?.()
      },
      [data?.id]
    )
  )

  const getChipStyle = (type: string, value: string) => {
    switch ((type + "_" + value).toLowerCase()) {
      case "exchange_ssi": return { background: "rgb(211, 47, 47)", color: "white", fontWeight: "bold" }
      case "exchange_binance": return { background: "rgb(230, 185, 0)", color: "white", fontWeight: "bold" }
      case "exchange_bfutures": return { background: "rgb(0, 0, 0)", color: "white", fontWeight: "bold" }
      // case "type_mm": return { background: "rgb(211, 47, 47)", color: "white", fontWeight: "bold" }
      // case "type_mm_vasset": return { background: "rgb(230, 185, 0)",color: "white", fontWeight: "bold" }
      // case "type_mm_fu_vasset": return { background: "rgb(0, 0, 0)", color: "white", fontWeight: "bold" }
    }
    return {}
  }

  return <>
    <Paper elevation={1} sx={{ p: "0.6em", background: "#00000008", my: "0.5em", opacity: (restarting || toggleing) ? 0.5 : 1, pointerEvents: (restarting || toggleing) ? "none" : "auto" }} >
      <Stack direction={{ sx: "column", md: "row" }} alignItems={{ md: "center", sx: "left" }} gap={[1, 1, 2]} flexWrap="wrap">
        <Stack style={{ flex: 1 }} direction={{ sx: "row", md: "column" }}>
          <Stack direction="row" justifyContent="start" spacing={1} flexWrap="wrap" style={{ whiteSpace: "nowrap" }}>
            <Typography style={{ textTransform: "uppercase", verticalAlign: "middle", whiteSpace: "nowrap" }} >
              <Circle color={!(data?.enable) ? "disabled" : (status?.online ? "success" : "error")} style={{ fontSize: "0.9em", verticalAlign: "inherit" }} />
              {" "}
              {data.id}
            </Typography>
            <Box sx={{ opacity: 0.7, ".MuiChip-root": { height: "1.3em", mr: "0.3em" } }} >
              <Chip size="small" label={data.exchange} style={getChipStyle("exchange", data.exchange) as any} />
              <Chip size="small" label={data.type} style={getChipStyle("type", data.type) as any} />
              <Chip size="small" label={data.pair} />
            </Box>
          </Stack>

          {/* <BotQuickLogs status={status} botId={data?.id} size={1} /> */}
        </Stack>

        <BotQuickStatus data={data} reload={reload} status={status} />
        <BotQuickTradeSummary data={data} reload={reload} status={status} />
        <Stack direction="row-reverse" >
          <ButtonWithConfirm
            text={`Confirm to delete bot [${data?.id?.trim()}]`}
            component={IconButton} disabled={deleting} onClick={onDelete}><DeleteForever />
          </ButtonWithConfirm>
          <Tooltip arrow placement="top" disableInteractive title={<BotInfo data={data} style={{ padding: "1em", fontSize: "0.9em", background: "#fff" }} />} {...{ big: true }} >
            <IconButton><ListAltOutlined /></IconButton>
          </Tooltip>
          <IconButton component={Link} to={`/bot/${data.id}`} ><Terminal /></IconButton>
          <IconButton onClick={onRestart} disabled={restarting}><RestartAltIcon /></IconButton>
          <IconButton
            onClick={() => onToggleSwich(!(data?.enable || false))}
            disabled={restarting || toggleing}
            style={{
              opacity: (data?.enable || false) ? 1 : 0.3,
              color: (data?.enable || false) ? "#4edb14" : "gray",
            }}><PowerSettingsNew />
          </IconButton>
        </Stack>
      </Stack>


    </Paper>
  </>
}


