import { Chip, Paper, Stack, Typography, useScrollTrigger } from "@mui/material";
import { useCallback, useEffect, useLayoutEffect, useRef, useState } from "react";
import { Socket, io } from "socket.io-client";
import { BASE_URL } from "../../apis/base";
import { useScroll } from "react-use";
import { useRefValue } from "../../hook/useRefValue";
import { debounce, throttle } from "lodash";
import { Delete, KeyboardDoubleArrowDown } from "@mui/icons-material";
import { useWsClient } from "../../hook/useWs";


export const BotLoggingPanel: React.FC<{ botId: string; }> = ({ botId }) => {
  const [logs, setLogs] = useState<any[]>([]);
  const [haveNewMsg, setHaveNewMsg] = useState(false)
  const ioClient = useWsClient()
  // const [ioClient, setClient] = useState<Socket | undefined>(undefined);

  const [scrollToBottom, setScrollToBottom] = useState(true)

  const pannelRef = useRef<HTMLElement>()

  const revValue = useRefValue({ ioClient, scrollToBottom })

  const scorllHandler = useCallback(debounce((ev: WheelEvent) => {

    const height = pannelRef.current?.offsetHeight ?? 100
    const scrollTop = pannelRef.current?.scrollTop ?? 0
    const scrollHeight = pannelRef.current?.scrollHeight ?? 100
    const enable = scrollTop + height + 10 >= scrollHeight && ev.deltaY > 1
    // console.log(scrollTop + height + 10, scrollHeight, ev.deltaY)

    if (enable != revValue.scrollToBottom) {
      setScrollToBottom(enable)
      enable && setHaveNewMsg(false);
    }
  }, 50), [])


  useEffect(() => {

    pannelRef.current?.addEventListener("wheel", scorllHandler)

    return () => pannelRef.current?.removeEventListener("wheel", scorllHandler)
  }, [pannelRef.current])


  useEffect(() => {
    // let client = io(BASE_URL)
    // setClient(client)

    let handlerAddLog = (...log) => {
      setLogs(l => [...l, ...log].slice(-500)?.sortBy(e => Date.parse(e?.log?.time)));
      !revValue.scrollToBottom && setHaveNewMsg(true);
    }
    ioClient?.on("log", handlerAddLog);
    return () => { ioClient?.off("log", handlerAddLog) }
  }, []);

  useEffect(() => {
    scrollToBottom && pannelRef?.current?.scrollBy({ top: 10000 })
  }, [scrollToBottom, logs.length])

  useEffect(() => {
    setLogs([])

    botId && ioClient?.on(
      "connect",
      () => {
        console.log("----------------------------------------------")
        ioClient?.emit("log-stream", { botId })
      }
    )

  }, [ioClient, botId]);

  const getLog = useCallback(
    (from: number, to: number) => botId && ioClient?.emit("log-get", { botId, from, to }),
    [ioClient, botId]
  );

  useEffect(() => {
    getLog(Date.now() - 3600000 * 24, Date.now() + 3600000 * 24)
  }, [getLog])

  return <>
    <Paper style={{ padding: "0", flex: 1, width: "100%", position: "relative" }}>
      <pre style={{ height: "30em", width: "100%", overflow: "auto", background: "#00000005", padding: "10px 7px", border: "solid 1px #0001", margin: 0 }} ref={pannelRef as any} >
        {logs.map((e, index) => <div key={index} className="highlight-new border-bottom">
          <b style={{ display: "inline-block", verticalAlign: "top" }}>{new Date(Date.parse(e?.log?.time)).toLocaleString()} </b>
          <span style={{ display: "inline-block" }}>{e.log?.message}</span>
        </div>)}
      </pre>
      <Stack direction="row-reverse" gap={1} style={{ position: "absolute", right: "40px", bottom: "40px" }}>
        {!scrollToBottom
          && <Chip size="small" label="Scroll To Bottom" color="info"
            deleteIcon={<KeyboardDoubleArrowDown />}
            clickable
            onDelete={() => { }}
            onClick={() => pannelRef?.current?.scrollBy({ top: 10000 })}
          />}
        {logs.length > 10
          && <Chip size="small" label="Clear logs" color="info"
            deleteIcon={<Delete />}
            clickable
            onDelete={() => { }}
            onClick={() => setLogs([])}
          />}
      </Stack>
    </Paper>

  </>;
};
