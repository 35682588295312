import { useAsync } from "react-use";
import { CircularProgress, FormHelperText, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, useTheme } from "@mui/material";
import { APIExchangeOrderhistory } from "../../apis/exchange";
import { formatDate, prettyNumber } from "../../format/date";
import { useMemo } from "react";
import { useAsyncWithCached } from "../../hook/useAsyncWithCached";
import { StatusPanel } from "./StatusPanel";
import { RelativeTime } from "../shared/RelativeTime";
import { calcPL } from "./calcPL";


export const BotOrderHistory: React.FC<{ botId: string; token: any; reload: any; pair: string }> = ({ botId, token, pair }) => {

  const { value: history, error, loading, loadingCache } = useAsyncWithCached(
    (cached) => APIExchangeOrderhistory({ botId, filter: { pair, status: ["FILLED", "PARTIAL_FILL", "OPEN"]} }, cached)(),
    [botId, token, pair]
  );

  const filteredHistory = useMemo(
    () => history
      ?.filter(e => e.pair == pair && e.executed > 0)
      ?.filter(e => ["FILLED", "PARTIAL_FILL", "OPEN"].includes(e.status))
      ?.sortBy(e => e?.timestamp ?? 0),
    [history, pair]
  )

  const filteredHistoryWithPL = useMemo(
    () => calcPL(filteredHistory)
      ?.toReversed(),
    [filteredHistory]
  )

  return <>
    <TableContainer style={{ height: "100%", overflow: "auto", position: "relative", paddingBottom: "2em", }} >
      <Table  >
        <TableHead color="white" sx={{ whiteSpace: "nowrap" }}>
          <TableCell align="right">Pair</TableCell>
          <TableCell align="right">Side</TableCell>
          <TableCell align="right">Price</TableCell>
          <TableCell align="right">Amount</TableCell>
          <TableCell align="right">Executed</TableCell>
          {/* <TableCell align="right">Fill Avg</TableCell> */}
          <TableCell align="right">Status</TableCell>
          <TableCell align="right">PL</TableCell>
          <TableCell align="right">PL. Cum</TableCell>
          <TableCell align="right">Timestamp</TableCell>

        </TableHead>
        <TableBody>
          {filteredHistoryWithPL?.map(book => <TableRow key={book.id}>
            <TableCell align="right">{book.pair}</TableCell>
            <TableCell align="right" style={{ color: book.side == "BUY" ? "green" : "red" }}>{book.side}</TableCell>
            <TableCell align="right">{book.price}</TableCell>
            <TableCell align="right">{book.amount}</TableCell>
            <TableCell align="right">{book.executed}</TableCell>
            {/* <TableCell align="right">{book.fill_avg}</TableCell> */}
            <TableCell align="right">{book.status}</TableCell>
            <TableCell align="right" style={{ color: Math.abs(book.pl) <= 0.001 ? "#8888" : (book.pl > 0 ? "green" : "red") }}>{book.pl > 0 ? "+" : ""}{(book.pl).toFixed(4)}</TableCell>
            <TableCell align="right">{book.cumPL.toFixed(4)}</TableCell>
            <TableCell align="right"><RelativeTime timestamp={book?.timestamp} /></TableCell>
          </TableRow>)}
        </TableBody>
      </Table>
      <StatusPanel show={loading || loadingCache}>
        <CircularProgress size="0.9em" sx={{ px: "0.5em" }} />
        Fetching {loadingCache ? ' cached ' : ' latest '} data ...
      </StatusPanel>
    </TableContainer>
    {error && <FormHelperText error>{String(error)}</FormHelperText>}

  </>;
};



