import { useAsync, useMeasure } from "react-use";
import { FormHelperText, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import { APIExchangeOpenorder } from "../../apis/exchange";
import { formatDate } from "../../format/date";
import { useCallback, useEffect, useLayoutEffect, useMemo, useRef } from "react";
import { APIMarketOrderBook } from "../../apis/market";
import YAML from "yaml"
import { useIntervalReload } from "../../hook/useReload";
import "../../@polyfill/Array"
import { useAsyncWithCached } from "../../hook/useAsyncWithCached";

export const BotOrderBook: React.FC<{ botId: string; token: any; reload: any; exchange: string, pair: string }> = ({ botId, exchange, pair, token }) => {
  
  const { value: openOrders, error: openOrderErrr } = useAsyncWithCached(
    (cached) => APIExchangeOpenorder({ botId, filter: { pair } }, cached)(),
    [botId, token, pair]
  );

  const { value: orderBook, error: orderBookError } = useAsync(
    () => APIMarketOrderBook({ exchange, pair })(),
    [exchange, pair, token]
  );

  const filteredOrderBook = useMemo(
    () => openOrders
      ?.filter(e => e.pair == pair)
      ?.filter(e => e.status == "QUEUE" || e.status == "OPEN") ?? [],
    [openOrders, pair]
  )

  const { indexedBuyOrders, indexedSellOrders } = useMemo(
    () => ({
      indexedBuyOrders: filteredOrderBook?.filter(e => e.side == "BUY")?.groupBy(e => +e.price),
      indexedSellOrders: filteredOrderBook?.filter(e => e.side == "SELL")?.groupBy(e => +e.price),
    }),
    [filteredOrderBook]
  )

  const [askSet, bidSet] = useMemo(
    () => [
      new Set(orderBook?.asks.map(e => +e[0]) ?? []),
      new Set(orderBook?.bids.map(e => +e[0]) ?? []),
    ],
    [orderBook]
  )

  const asks = useMemo(
    () => [
      ...filteredOrderBook
        ?.filter(e => e.side == "SELL" && !askSet.has(+e.price))
        ?.map(e => [e.price, e.amount, e.timestamp]) ?? [],
      ...orderBook?.asks ?? []
    ]
      ?.map(([price, amount, timestamp]) => ({
        price,
        amount,
        userAmount: (indexedSellOrders?.[price])
          ?.map(e => e.amount)
          ?.reduce((e, f) => e + f, 0) ?? 0
      }))
      ?.sortBy(e => -e?.price) ?? [],
    [orderBook, indexedSellOrders, askSet]
  )


  const bids = useMemo(
    () => [
      ...filteredOrderBook
        ?.filter(e => e.side == "BUY" && !bidSet.has(+e.price))
        ?.map(e => [e.price, e.amount, e.timestamp]) ?? [],
      ...orderBook?.bids ?? []
    ].map(([price, amount, timestamp]) => ({
      price,
      amount,
      userAmount: (indexedBuyOrders?.[price])
        ?.map(e => e.amount)
        ?.reduce((e, f) => e + f, 0) ?? 0
    }))
      ?.sortBy(e => -e?.price) ?? [],
    [orderBook, indexedBuyOrders, bidSet]
  )

  const maxAmount = useMemo(
    () => Math.max(...asks.map(e => +e.amount), ...bids.map(e => +e.amount), 0) * 1.4,
    [asks, bids]
  )

  const getColors = useCallback(
    ({ amount, userAmount }, color) => `linear-gradient(
      to right, 
      #${color}c 0%, 
      #${color}c ${100 * (userAmount / maxAmount)}%, 
      #${color}6 ${100 * (userAmount / maxAmount)}%, 
      #${color}6 ${100 * (amount / maxAmount)}%, 
      #${color}0 ${100 * (amount / maxAmount)}%, 
      #0000 100%
    )`,
    [maxAmount]
  )

  const mRef = useRef()
  const [ref, { height = 100 } = {}] = useMeasure()
  const [middleRef, { height: cellHeight = 20 } = {}] = useMeasure()

  const lineCount = useMemo(() => Math.ceil((height - cellHeight * 2 - 5) / 2 / cellHeight), [height, cellHeight])

  // useLayoutEffect(
  //   () => {(mRef?.current as HTMLElement)?.scrollIntoView({block:"center"})},
  //   [lineCount, asks, bids]
  // )

  return <>
    <Paper style={{ padding: "0.8em", flex: 1 }}>
      <TableContainer ref={ref} style={{ height: "350px", overflow: "auto" }} sx={{ td: { lineHeight: 1.1 } }} >
        <Table >
          <TableHead style={{ whiteSpace: "nowrap" }}>
            <TableRow>
              <TableCell align="right">Price</TableCell>
              <TableCell align="right">Amount</TableCell>
              <TableCell align="right">User</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {lineCount > asks?.length && new Array(lineCount - asks?.length).fill(0).map((e, i) =>
              <TableRow key={'askfill_' + i}>
                <TableCell align="right">-</TableCell>
                <TableCell align="right">-</TableCell>
                <TableCell align="right">-</TableCell>
              </TableRow>)}
            {asks?.map(ask => <TableRow key={ask.price} style={{
              backgroundImage: getColors(ask, "f00")
            }}>
              <TableCell align="right">{ask.price}</TableCell>
              <TableCell align="right">{ask.amount}</TableCell>
              <TableCell align="right">{ask.userAmount}</TableCell>
            </TableRow>)}

            <TableRow ref={middleRef}>
              <TableCell ref={mRef} align="right"> {asks?.at(-1)?.price - bids?.at(-0)?.price}</TableCell>
              <TableCell align="right"> </TableCell>
              <TableCell align="right"> </TableCell>
            </TableRow>


            {bids?.map(bid => <TableRow key={bid.price} style={{
              backgroundImage: getColors(bid, "0f0")
            }}>
              <TableCell align="right">{bid.price}</TableCell>
              <TableCell align="right">{bid.amount}</TableCell>
              <TableCell align="right">{bid.userAmount}</TableCell>
            </TableRow>)}

            {lineCount > bids?.length && new Array(lineCount - bids?.length).fill(0).map((e, i) =>
              <TableRow key={'bidfill_' + i}>
                <TableCell align="right">-</TableCell>
                <TableCell align="right">-</TableCell>
                <TableCell align="right">-</TableCell>
              </TableRow>)}

          </TableBody>

        </Table>
      </TableContainer>
      {openOrderErrr && <FormHelperText error>{String(openOrderErrr)}</FormHelperText>}
    </Paper>

  </>;
};
