import { BASE_URL, RESP, fetchConfig } from "./base"
import { fetch } from './fetch'

export const APIKeyList = () => Promise
  .resolve()
  .then(() => fetch(`${BASE_URL}/apikey/list`, {
    ...fetchConfig
  }))
  .then(RESP) as Promise<any[]>

export const APIKeyCreate = (data: { id: string, exchange: string, data: any }) => Promise
  .resolve()
  .then(_ => fetch(`${BASE_URL}/apikey/create`, {
    method: "POST",
    body: JSON.stringify(data),
    ...fetchConfig,
  }))
  .then(RESP)

export const APIKeyUpdate = (data: { id: string, exchange: string, data: any }) => Promise
  .resolve()
  .then(_ => fetch(`${BASE_URL}/apikey/update`, {
    method: "POST",
    body: JSON.stringify(data),
    ...fetchConfig,
  }))
  .then(RESP)



export const APIKeyRemove = (id: string) => Promise
  .resolve()
  .then(_ => fetch(`${BASE_URL}/apikey/remove`, {
    method: "POST",
    body: JSON.stringify({ id }),
    ...fetchConfig,
  }))
  .then(RESP)
