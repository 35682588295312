import { Button, DialogActions, Divider, Stack, Typography } from "@mui/material"
import { APICreateDialog, } from "./APIForm"
import { APIKeys } from "./APIKeys"
import { useToggle } from "react-use"
import { Add, PlusOneOutlined } from "@mui/icons-material"
import { useReload } from "../../hook/useReload"



export const APIKeyManager: React.FC = () => {
  const [enableCreateFrom, toggleCreaeteForm] = useToggle(false)
  const { reload, token } = useReload()

  return <>
    <Typography variant="h5">API Keys Manager</Typography>
    <br />
    <APIKeys token={token} reload={reload} />
    <br />
    <Stack direction="row-reverse">
      <Button onClick={toggleCreaeteForm} variant="contained" color="inherit">
        <Add sx={{ mr: "0.3em" }} /> New Key
      </Button>
    </Stack>
    {enableCreateFrom && <APICreateDialog open={enableCreateFrom} onClose={toggleCreaeteForm} reload={reload}/>}
  </>
}