import { InputOutlined } from "@mui/icons-material"
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, FormHelperText, MenuItem, Paper, Select, Stack, TextField, Typography, useEventCallback } from "@mui/material"
import SelectInput from "@mui/material/Select/SelectInput"
import { ChangeEvent, FormEvent, useCallback, useEffect, useMemo, useState } from "react"
import ReactJson from 'react-json-view'
import { memoize } from "lodash"
import { useLoading } from "../../hook/useLoading"
import { LoadingButton } from "@mui/lab"
import { APIKeyCreate, APIKeyUpdate } from "../../apis/keys"
import { ExchangeSelect } from "../ExchangeSelect"



export const getDefaultAPIKey = (exchange: string) => {
  return {
    "SSI": {
      consumer_id: "",
      consumer_iscret: "",
      account_id: "",
      public_key: "",
      private_key: "",
    },
    "Binance": {
      consumer_id: "",
      consumer_iscret: "",
      account_id: "",
      public_key: "",
      private_key: "",
    },
    "WrappedSSI": {
      consumer_id: "",
      consumer_iscret: "",
      account_id: "",
      public_key: "",
      private_key: "",
    },
    "MOCK": {
      account_id: "",
    },
  }[exchange] ?? {}
}

export const APIKeyForm: React.FC<{ value: any, onChange: (e: any) => void }> = ({ value, onChange }) => {


  const onChangeFactory = useMemo(
    () => memoize(field => (newValue: any) => onChange((state: any) => ({ ...state, [field]: newValue }))),
    []
  )
  const eventWrapper = useMemo(
    () => memoize(cb => (e: ChangeEvent<HTMLInputElement>) => cb(e.target.value)),
    []
  )

  const JSONEnable = useCallback(
    ({ updated_src }: any) => (onChangeFactory('data')(updated_src), true),
    []
  )

  return <>
    <ExchangeSelect
      sx={{ width: "12em" }} label="Exchange" select value={value?.exchange ?? " "}
      onChange={eventWrapper(onChangeFactory("exchange"))}
    />
    <TextField sx={{ width: "12em" }} label="KeyId" value={value?.id ?? " "} onChange={eventWrapper(onChangeFactory("id"))} />
    <Paper style={{ width: "100%", padding: "0.7em", backgroundColor: "rgba(0, 0, 0, 0.07)", overflow: "auto" }} elevation={1} >
      <ReactJson
        src={value?.data || getDefaultAPIKey(value?.exchange)}
        onEdit={JSONEnable} onDelete={JSONEnable} onAdd={JSONEnable}
        style={{ fontSize: "1em" }}
        displayDataTypes={false} quotesOnKeys={false} name="data"
        enableClipboard={false}
      />
    </Paper>
  </>
}

export const APICreateDialog: React.FC<{ open: boolean, onClose: any, reload: any }> = ({ onClose, open, reload }) => {
  const [data, setData] = useState()

  const [onSubmit, { error, loading }] = useLoading(useEventCallback(async (e: FormEvent) => {
    e.preventDefault()
    e.stopPropagation()
    await APIKeyCreate(data as any)
    reload?.()
    onClose?.()
  }))

  return <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
    <DialogTitle>New Key</DialogTitle>
    <DialogContent>
      <Stack direction="row" gap={2} flexWrap="wrap">

        <APIKeyForm value={data} onChange={setData} />
      </Stack>
      {error && <FormHelperText error>{error}</FormHelperText>}
    </DialogContent>
    <DialogActions>
      <Button onClick={onClose}>Cancel</Button>
      <LoadingButton loading={loading} onClick={onSubmit}>Submit</LoadingButton>
    </DialogActions >
  </Dialog >
}

export const APIUpdateDialog: React.FC<{ open: boolean, onClose: any, reload: any, prevData: any }> = ({ prevData, onClose, open, reload }) => {
  const [data, setData] = useState(() => prevData)

  const [onSubmit, { error, loading }] = useLoading(useEventCallback(async (e: FormEvent) => {
    e.preventDefault()
    e.stopPropagation()
    await APIKeyUpdate(data as any)
    reload?.()
    onClose?.()
  }))

  return <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
    <DialogTitle>New Key</DialogTitle>
    <DialogContent>
      <Stack direction="row" gap={2} flexWrap="wrap">

        <APIKeyForm value={data} onChange={setData} />
      </Stack>
      {error && <FormHelperText error>{error}</FormHelperText>}
    </DialogContent>
    <DialogActions>
      <Button onClick={onClose}>Cancel</Button>
      <LoadingButton loading={loading} onClick={onSubmit}>Submit</LoadingButton>
    </DialogActions>
  </Dialog>
}