import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Modal, Paper, Stack, Typography } from "@mui/material"
import { useAsync, useToggle } from "react-use"
import ReactJson from 'react-json-view'
import { BorderColor, DeleteForever, DeleteOutline, Edit, EditAttributes, Info, InfoOutlined, ListAltOutlined } from "@mui/icons-material"
import React, { useCallback, useState } from "react"
import { APIKeyList, APIKeyRemove } from "../../apis/keys"
import { useLoading } from "../../hook/useLoading"
import { APIUpdateDialog } from "./APIForm"
import { ButtonWithConfirm } from "../shared/ButtonWithConfirm"



export const APIKeys: React.FC<{ token: any, reload: any }> = ({ token, reload }) => {
  const { value: list } = useAsync(APIKeyList, [token])
  const [updateId, setUpdateId] = useState("")
  const clearUpdateId = useCallback(() => setUpdateId(""), [])
  return <>
    {list?.map((data: any) => <APIKeyRowItem data={data} reload={reload} onUpdateClick={() => setUpdateId(data.id)} />)}
    {
      updateId
      && list?.find(e => e.id == updateId)
      && <APIUpdateDialog
        open={!!updateId} onClose={clearUpdateId} prevData={list?.find(e => e.id == updateId)} reload={reload} />
    }

  </>
}


const APIKeyInfoDialog: React.FC<{ enable: boolean, setToggle: any, data: any, }> = ({ enable, setToggle, data, }) => {
  return <Dialog open={enable} onClose={setToggle} style={{ padding: 0 }}>
    <DialogTitle>INFO</DialogTitle>
    <DialogContent>
      <ReactJson
        style={{ padding: "0.3em", flex: 1, maxWidth: "40em", overflow: "auto", background: "#00000008" }}
        src={data?.data} enableClipboard={false} name=""
        collapseStringsAfterLength={20} quotesOnKeys={false} displayDataTypes={false}
      />
    </DialogContent>
    <DialogActions>
      <Button onClick={setToggle}>CLOSE</Button>
    </DialogActions>
  </Dialog>;
}


export const APIKeyRowItem: React.FC<{ data: any, reload: any, onUpdateClick: any }> = ({ data, reload, onUpdateClick }) => {

  const [onDelete, { loading: deleting, error }] = useLoading(useCallback(
    async () => {
      await APIKeyRemove(data?.id);
      reload?.()
    },
    [data?.id]
  ))
  const [enable, setToggle] = useToggle(false)

  return <>
    <Paper elevation={1} sx={{ p: "0.1em 0.8em", background: "#00000008", my: "0.5em" }} >
      <Stack direction="row" alignItems="center">
        <Typography style={{ flex: 1, textTransform: "uppercase" }} >{data.id}</Typography>
        <Typography style={{ flex: 1 }}>{data.exchange}</Typography>
        <Stack style={{ flex: 1 }} direction="row-reverse" >
          {/* <IconButton disabled={deleting} onClick={onDelete}><DeleteForever /></IconButton> */}
          <ButtonWithConfirm
            text={`Confirm to delete key [${data?.id?.trim()}]`}
            component={IconButton} disabled={deleting} onClick={onDelete}><DeleteForever />
          </ButtonWithConfirm>
          <IconButton onClick={onUpdateClick}><BorderColor /></IconButton>
          <IconButton onClick={setToggle}><ListAltOutlined /></IconButton>
        </Stack>
      </Stack>
    </Paper>
    {enable && <APIKeyInfoDialog enable={enable} setToggle={setToggle} data={data} />}
  </>
}