import { Box, Button, FormLabel, InputBase, Paper, Slide, Slider, Stack, StackProps, TextField, Typography } from "@mui/material";
import { memoize } from "lodash";
import { useMemo, useState } from "react";
import YAML from "yaml"
import { useCalcState, FormBlock } from "./useCalcState";
import "../../@polyfill/Number"
import { ChevronRight } from "@mui/icons-material";


const prettyNumber = (e: any, a = 0) => {
  const s = +e * (10 ** a)
  if (s >= 1e9) {
    return `${(s / 1e9).toFixed(2)}b`
  } else if (s >= 1e8) {
    return `${(s / 1e6).toFixed(0)}m`
  } else if (s >= 1e6) {
    return `${(s / 1e6).toFixed(2)}m`
  } else if (s >= 1000) {
    return `${(s / 1e3).toFixed(0)}k`
  } else {
    return +s
  }
}


export const roundBy = (e: number, f = 1) => {
  return Math.round(e / f) * f
}

export const BothTextAndSlider: React.FC<StackProps & { values: string[], onChanges?: any, titles?: string[], min?: number, max?: number, step?: number }> = ({ values, onChanges, titles, min = 0, max = Infinity, step, ...rest }) => {

  const onChangeFactory = useMemo(
    () => memoize((index: number) => ({ target: { value = "0" } = {} } = {}) => onChanges?.(
      values => values
        .map((e: string, i: number) => index == i ? value : e)
    )),
    [onChanges]
  )

  return <Stack direction="row" gap={1} {...rest}>
    <Stack direction="row" flex={1} gap={1}>
      {values.map((value, index) => <TextField
        type="number"
        inputProps={{ step } as any}
        sx={{ flex: 1 }}
        onChange={onChangeFactory(index)}
        size="small" value={String(value)} variant="outlined" label={titles?.[index]} />)}
    </Stack >
    <Stack direction="row" flex={1} gap={1}>
      <fieldset style={{ width: "100%", border: "solid 1px rgba(0, 0, 0, 0.23)", padding: "0.42em 0.5em", margin: "0" }}>
        <Stack direction="row" alignItems="center" gap={1} paddingX="0.4em">
          {isFinite(min) && min > 0 && <Typography>{prettyNumber(min)}</Typography>}
          <Slider
            step={step}
            disableSwap
            value={values.map(e => +e)} size="small" min={min} max={max}
            style={{ padding: "0", margin: "0 0.3em" }}
            onChange={(event, values) => onChanges?.(() => values)}
          />
          {isFinite(max) && <Typography>{prettyNumber(max)}</Typography>}
        </Stack>
      </fieldset>
    </Stack >
  </Stack>
}


export function calc({ min, max, asset = 0, fund = 0 }) {
  if (typeof asset == 'number' && asset > 0) {
    const rate = Math.sqrt(max / min)
    const assetAtHighPrice = asset / (rate - 1)
    const fundAtHighPrice = assetAtHighPrice * max
    const assetAtLowPrice = assetAtHighPrice + asset
    const fundAtLowPrice = assetAtLowPrice * min
    const kConstant = assetAtHighPrice * fundAtHighPrice

    return {
      assetAtHighPrice,
      fundAtHighPrice,
      assetAtLowPrice,
      fundAtLowPrice,
      kConstant,
      // c:`typeof maxAsset == 'number' && ${maxAsset} > 0`
    }
  } else if (typeof fund == 'number' && fund > 0) {
    const rate = Math.sqrt(max / min)
    const fundAtLowPrice = fund / (rate - 1)
    const assetAtLowPrice = fundAtLowPrice / min
    const fundAtHighPrice = fundAtLowPrice + fund
    const assetAtHighPrice = fundAtHighPrice / max
    const kConstant = assetAtHighPrice * fundAtHighPrice
    return {
      assetAtHighPrice,
      fundAtHighPrice,
      assetAtLowPrice,
      fundAtLowPrice,
      kConstant,
      // c:`typeof maxFund == 'number' && ${maxFund} > 0`
    }
  } else {
    return {
      assetAtHighPrice: 0,
      fundAtHighPrice: 0,
      assetAtLowPrice: 0,
      fundAtLowPrice: 0,
      kConstant: 0,
    }
  }
}



export const CalcHelper: React.FC<{ preState?: ReturnType<typeof useCalcState>["computedState"], onApply?: Function }> = ({ preState, onApply }) => {


  const [gap, setGap] = useState("1")
  const [fee, setFee] = useState("0.15")
  const [minAmount, setMinAmount] = useState("100")
  const [priceStep, setPriceStep] = useState("100")


  const { computedState, onChangeFactory } = useCalcState(preState, { minAmount: +minAmount, priceStep: +priceStep });

  const profitAndLoss = useMemo(
    () => {

      const {
        currentAsset: [currentAsset = 0] = [],
        currentVND: [currentVND = 0] = [],
        maxHoldAsset: [maxHoldAsset = 0] = [],
        maxHoldFund: [maxHoldFund = 0] = [],
        prices: [min = 0, current = 0, max = 0] = [],
      } = computedState || {}

      const totalInitFund = +currentAsset * +current + +currentVND

      const profitAtMaxPrice = +maxHoldFund - totalInitFund

      const avgSellAtMaxPrice = (+maxHoldFund - +currentVND) / +currentAsset

      const lossAtMinPrice = totalInitFund - (+maxHoldAsset * +min)

      const avgBuyAtMinPrice = +currentVND / (+maxHoldAsset - +currentAsset)

      return {
        initFund: {
          asset: +currentAsset * +current,
          vnd: +currentVND,
          total: totalInitFund,
        },
        atMaxPrice: {
          profitAtMaxPrice,
          avgSellAtMaxPrice,
        },
        atMinPrice: {
          lossAtMinPrice,
          avgBuyAtMinPrice,
        },
        // estimate: {
        //   totalOrderMatchToCoverLoss: "...",
        // }
      }
    },
    [computedState]
  )

  return <>
    <Stack direction="column" gap={2}>
      <div>
        <FormBlock {...{ computedState, onChangeFactory }} />
        <br />

        <FormLabel className="title-hr" sx={{ my: 1 }}>Configs</FormLabel>
        <Stack direction="row" flex={1} gap={1}>
          <TextField label={"Min Gap (%)"}
            type="number" sx={{ flex: 1 }} inputProps={{ step: 0.05, min: 0 }}
            onChange={e => setGap(e.target.value)} value={String(gap)} />
          <TextField label={"Fees (%)"}
            type="number" sx={{ flex: 1 }} inputProps={{ step: 0.01, min: 0 }}
            onChange={e => setFee(e.target.value)} value={String(fee)} />
          <TextField label={"Min Amount"}
            type="number" sx={{ flex: 1 }} inputProps={{ step: 10, min: 10 }}
            onChange={e => setMinAmount(e.target.value)} value={String(minAmount)} />
          <TextField label={"Price Step"}
            type="number" sx={{ flex: 1 }} inputProps={{ step: 10, min: 10 }}
            onChange={e => setPriceStep(e.target.value)} value={String(priceStep)} />
        </Stack >
      </div>

      <Stack direction="row" gap={1}>
        <fieldset style={{ width: "100%", border: "solid 1px rgba(0, 0, 0, 0.23)", padding: "0.42em 0.5em", margin: "0" }}>
          <pre>
            {YAML.stringify(profitAndLoss)}
          </pre>
        </fieldset>
        <fieldset style={{ width: "100%", border: "solid 1px rgba(0, 0, 0, 0.23)", padding: "0.42em 0.5em", margin: "0" }}>
          <TargetOrderBook {...{ computedState, priceStep, onChangeFactory, minGapPercent: gap, minAmount: +minAmount }} />
        </fieldset>
        <fieldset style={{ width: "100%", border: "solid 1px rgba(0, 0, 0, 0.23)", padding: "0.42em 0.5em", margin: "0" }}>
          <TargetConfig {...{ computedState, onChangeFactory, onApplyConfig: onApply, minGapPercent: gap, minAmount: +minAmount }} />
        </fieldset>
      </Stack>

    </Stack>

  </>;
};


const calcKConstant = ({ min, max, asset = 0, fund = 0 }) => {
  if (typeof asset == 'number' && asset > 0) {
    const rate = Math.sqrt(max / min)
    const assetAtHighPrice = asset / (rate - 1)
    const fundAtHighPrice = assetAtHighPrice * max
    const assetAtLowPrice = assetAtHighPrice + asset
    const fundAtLowPrice = assetAtLowPrice * min
    const kConstant = assetAtHighPrice * fundAtHighPrice

    return {
      assetAtHighPrice,
      fundAtHighPrice,
      assetAtLowPrice,
      fundAtLowPrice,
      kConstant,
      // c:`typeof maxAsset == 'number' && ${maxAsset} > 0`
    }
  } else if (typeof fund == 'number' && fund > 0) {
    const rate = Math.sqrt(max / min)
    const fundAtLowPrice = fund / (rate - 1)
    const assetAtLowPrice = fundAtLowPrice / min
    const fundAtHighPrice = fundAtLowPrice + fund
    const assetAtHighPrice = fundAtHighPrice / max
    const kConstant = assetAtHighPrice * fundAtHighPrice
    return {
      assetAtHighPrice,
      fundAtHighPrice,
      assetAtLowPrice,
      fundAtLowPrice,
      kConstant,
      // c:`typeof maxFund == 'number' && ${maxFund} > 0`
    }
  } else {
    return {
      assetAtHighPrice: 0,
      fundAtHighPrice: 0,
      assetAtLowPrice: 0,
      fundAtLowPrice: 0,
      kConstant: 0,
    }
  }
}

const calcTargetOrders = ({
  maxFund, maxAsset, minPrice, maxPrice,
  minGapPercent, priceStep = 100, minAmount = 100,

  assetBalance, cashBalance,
  ceiling, floor, minAsk, maxBid,
}) => {

  // console.table({
  //   maxFund, maxAsset, minPrice, maxPrice,
  //   minGapPercent, priceStep, minAmount,

  //   assetBalance, cashBalance,
  // })

  const { kConstant } = calcKConstant({ min: minPrice, max: maxPrice, fund: maxFund })
  const middlePrice = kConstant / (Math.sqrt(kConstant / maxPrice) + assetBalance) ** 2

  const orders: { price, amount, side: "BUY" | "SELL" }[] = []

  const sellGap = (1 + minGapPercent / 100 / 2)

  const compareSell = {
    price: middlePrice,
    amount: Math.sqrt(kConstant / middlePrice)
  }

  let baseTotal = 0, counterTotal = 0

  for (let i = 0; i < 30; i++) {
    const sellPrice = (compareSell.price * sellGap).ceilByUnit(priceStep)
    const calcPrice = sellPrice / sellGap
    const newAmount = Math.sqrt(kConstant / calcPrice)
    const delta = (compareSell.amount - newAmount).floorByUnit(minAmount)

    if (delta + baseTotal > assetBalance)
      break;

    if (delta >= minAmount && sellPrice > maxBid && orders.filter(e => e.side == "SELL").length < 3) {
      orders.push({ side: "SELL", price: sellPrice, amount: delta })
      compareSell.amount -= delta
      baseTotal += delta
    }

    compareSell.price += priceStep

    if (compareSell.price > ceiling)
      break;
  }

  const buyGap = (1 - minGapPercent / 100 / 2)

  const compareBuy = {
    price: middlePrice,
    amount: Math.sqrt(kConstant / middlePrice)
  }

  for (let i = 0; i < 30; i++) {
    const buyPrice = (compareBuy.price * buyGap).floorByUnit(priceStep)
    const calcPrice = buyPrice / buyGap
    const newAmount = Math.sqrt(kConstant / calcPrice)
    const delta = (newAmount - compareBuy.amount).floorByUnit(minAmount)

    if (delta * buyPrice + counterTotal > cashBalance)
      break;

    if (delta >= minAmount && buyPrice < minAsk && orders.filter(e => e.side == "BUY").length < 3) {
      orders.push({ side: "BUY", price: buyPrice, amount: delta })
      compareBuy.amount += delta
      counterTotal += delta * buyPrice
    }

    compareBuy.price -= priceStep

    if (compareBuy.price < floor)
      break;
  }


  return orders
    .sortBy(e => e.price)
}


const TargetOrderBook: React.FC<ReturnType<typeof useCalcState> & { minGapPercent, minAmount, priceStep }> = ({ computedState, minGapPercent = 1, minAmount = 100, priceStep = 100 }) => {

  const {
    currentAsset: [currentAsset = 0] = [],
    currentVND: [currentVND = 0] = [],
    maxHoldAsset: [maxHoldAsset = 0] = [],
    maxHoldFund: [maxHoldFund = 0] = [],
    prices: [minPrice = 0, currentPrice = 0, maxPrice = 0,] = []
  } = computedState || {}

  const targetOrderBook = useMemo(
    () => calcTargetOrders({
      assetBalance: +currentAsset,
      cashBalance: +currentVND,
      minPrice, maxPrice,
      maxAsset: +maxHoldAsset,
      maxFund: +maxHoldFund,
      ceiling: +currentPrice * 1.30,
      floor: +currentPrice / 1.30,
      minAsk: +currentPrice,
      maxBid: +currentPrice,
      minGapPercent: +(minGapPercent ?? 0),
      minAmount: +(minAmount ?? 0),
      priceStep: +(priceStep ?? 100)
    }),
    [currentAsset, currentVND, minPrice, currentPrice, maxHoldAsset, maxHoldFund, maxPrice, computedState, minGapPercent, minAmount, priceStep]
  )


  return <pre>
    {YAML.stringify({
      TARGET_ORDERBOOK: {
        BUY: targetOrderBook.filter(e => e.side == "BUY")
          .map(e => [String(e.price), String(e.amount)])
          .map(e => e.map(f => f.padStart(8, " ")).join(" x ")),
        SELL: targetOrderBook.filter(e => e.side == "SELL")
          .map(e => [String(e.price), String(e.amount)])
          .map(e => e.map(f => f.padStart(8, " ")).join(" x ")),
      }
    })}
  </pre>
}


const TargetConfig: React.FC<ReturnType<typeof useCalcState> & { onApplyConfig, minGapPercent, minAmount }> = ({ computedState, onApplyConfig, minAmount, minGapPercent }) => {

  const {
    currentAsset: [currentAsset = 0] = [],
    currentVND: [currentVND = 0] = [],
    maxHoldAsset: [maxHoldAsset = 0] = [],
    maxHoldFund: [maxHoldFund = 0] = [],
    prices: [minPrice = 0, currentPrice = 0, maxPrice = 0,] = [],
  } = computedState || {}

  const TARGET_CONFIG = useMemo(
    () => ({
      minPrice: +minPrice,
      maxPrice: +maxPrice,
      maxAsset: +maxHoldAsset,
      maxFund: +maxHoldFund,
      maxFundDay: +maxHoldFund / 10,
      intervalSecond: 5,
      minGapPercent: +minGapPercent,
      sellSize: 3,
      buySize: 3,
    }),
    [computedState]
  )


  return <>
    <pre>
      {YAML.stringify({ TARGET_CONFIG })}
    </pre>
    {onApplyConfig && <Button onClick={() => onApplyConfig(TARGET_CONFIG)} >
      Apply
      <ChevronRight />
    </Button>}
  </>
}