import { ButtonProps, createTheme, ThemeProvider as Provider, responsiveFontSizes, TableCell, tableCellClasses } from "@mui/material"

const processOpacityColor = (e: string, opacity = 1) => {
  opacity = Math.min(1, Math.max(0, opacity))
  if (e.startsWith("#")) {
    if (e.length == 4) {
      return `${e}${(opacity * 16 | 0).toString(16)}`
    } else if (e.length == 5) {
      const preOpacity = parseInt(e[4], 16) / 16
      return `${e.slice(0, 4)}${(preOpacity * opacity * 16 | 0).toString(16)}`
    } else if (e.length == 7) {
      return `${e}${(opacity * 256 | 0).toString(16)}`
    } else if (e.length == 9) {
      const preOpacity = parseInt(e.slice(8, 9), 16) / 256
      return `${e.slice(0, 8)}${(preOpacity * opacity * 256 | 0).toString(16)}`
    }
  }
  return e
}

const defaultButtonProps: any = { size: "small", color: "inherit", variant: "contained" } as Partial<ButtonProps>


const theme = responsiveFontSizes(
  createTheme({
    shape: {
      borderRadius: 0,
    },
    mixins: {
    },
    typography: {
      fontSize: 13,
      // h1: { fontSize: ["1.5rem", "2rem", "3rem"] as any },
      // h2: { fontSize: ["1.25rem", "1.5rem", "2rem"] as any },
      // h3: { fontSize: ["1.2rem", "1.4rem", "1.75rem"] as any },
      // h4: { fontSize: ["1.125rem", "1.25rem", "1.5rem"] as any },
      // h5: { fontSize: ["1.068rem", "1.125rem", "1.25rem"] as any },
      // h6: { fontSize: ["1.025rem", "1.05rem", "1.1rem"] as any }
    },

    components: {
      MuiButton: {
        defaultProps: defaultButtonProps
      },
      MuiButtonGroup: {
        defaultProps: defaultButtonProps
      },
      ["MuiLoadingButton" as any]: {
        defaultProps: defaultButtonProps
      },
      MuiTextField: {
        defaultProps: { size: "small", variant: "outlined" }
      },
      MuiPaper: {

      },
      MuiTooltip: {
        styleOverrides: {
          
          tooltip: ({ theme, ownerState }) => ({
            backgroundColor: theme.palette.common.white,
            color: 'rgba(0, 0, 0, 0.87)',
            filter: "drop-shadow(1px 2px 2px #888)",
            ...ownerState.big ? { padding: 0 } : {}
          }),
          arrow: ({ theme, ownerState }) => ({
            color: theme.palette.common.white,
          })
        }
      },
      MuiTab: {
        styleOverrides: {
          root: {
            padding: "6px 12px",
            minHeight: "30px",
          }
        }
      },
      MuiTabs: {
        styleOverrides: {
          root: {
            minHeight: "30px",
          }
        }
      },
      MuiChip: {
        styleOverrides: {
          root: {
            borderRadius: 1
          }
        }
      },
      MuiTable: {
        defaultProps: {
          size: "small",
          stickyHeader: true,
        },
      },
      MuiTableCell: {
        styleOverrides: {
          root: {
            padding: "3px 8px"
          }
        }
      },

    }
  }),
  {
    factor: 20
  }
);

export const ThemeProvider = ({ children }) => {
  return <Provider theme={theme}>
    {children}
  </Provider>
}