import { useToggle } from "react-use";
import React, { memo } from "react";
import { RelativeTime } from "../shared/RelativeTime";


export const QuickLocks: React.FC<{ logs } & React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>> = ({ logs, ...props }) => {
  return <pre style={{
    padding: "0.3em 1em",
    fontSize: "0.9em",
    margin: "-0.8em 0",
    background: "#eee",
    // whiteSpace: "pre-wrap",
    overflow:"-moz-hidden-unscrollable",
    textOverflow:"ellipsis",
    ...props.style ?? {},
  }}>

    {(
      logs
        ?.filter((l, i, a) => !(
          (a[i - 1]?.text?.endsWith?.("SIGNAL: []") && a[i]?.text?.endsWith?.("SIGNAL: []"))
          || (l?.status?.toUpperCase?.() != "RUNNING" && a[i - 1]?.status == l?.status)
        ))
        ?.map(log => <div>
          <span style={{ opacity: 0.5 }}>[<RelativeTime timestamp={log.timestamp} />]</span>
          {" "}
          <span style={{ fontWeight: 500 }} data-status-color={String(log?.status ?? "").toUpperCase()}>[{String(log?.status ?? "").toUpperCase()}] </span>
          <span>{String(log?.text || log?.error?.msg || log?.error || "")}</span>
        </div>)
    )}
  </pre>
}

export const BotQuickLogs: React.FC<{ status; botId; alwaysShow?, size?: number }> = memo(
  ({ status, botId, alwaysShow = false, size = 2 }) => {
    // const [onHover, setHover] = useToggle(false);


    return <QuickLocks
      logs={status?.logs?.slice(-size)?.reverse()} style={{
        position: "relative", overflow: "hidden",
        width: "30em", maxWidth: "38em", zIndex: 1,
        padding: 0, margin: 0,
        background: "transparent"
      }} />
    // return <div style={{ flex: 3, position: "relative", }
    // } onMouseEnter={(e) => setHover(true)} onMouseLeave={(e) => setHover(false)}>
    //   {!alwaysShow
    //     && <QuickLocks
    //       logs={status?.logs?.slice(-size)?.reverse()} style={{
    //         position: "relative", overflow: "hidden",
    //         maxWidth: "38em", zIndex: 1,
    //         padding: 0, margin: 0,
    //         background: "transparent"
    //       }} />}
    //   {/* {(alwaysShow || onHover)
    //     && <QuickLocks
    //       logs={status?.logs?.reverse()}
    //       style={{
    //         position: "absolute", top: 0, left: 0, width: "calc(100% - 2em)",
    //         boxShadow: "3px 3px 2px #0002", overflowY: "auto", zIndex: 2, maxHeight: "50em",
    //       }} />} */}
    // </div>;
  }
);
