import { useAsync } from "react-use";
import { Chip, CircularProgress, FormHelperText, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import { APIExchangeCancelorder, APIExchangeOpenorder } from "../../apis/exchange";
import { formatDate, formatTime } from "../../format/date";
import { memo, useCallback, useEffect, useMemo } from "react";
import { useIntervalReload } from "../../hook/useReload";
import { useAsyncWithCached } from "../../hook/useAsyncWithCached";
import { StatusPanel } from "./StatusPanel";
import { FindInPageOutlined } from "@mui/icons-material";
import { NoRecord } from "./NoRecord";


export const BotOpenOrders: React.FC<{ botId: string; token: any; reload: any; pair: string }> = memo(
  ({ botId, token, reload, pair }) => {

    const { value: orderBook, error, loading, loadingCache } = useAsyncWithCached(
      (cached) => APIExchangeOpenorder({ botId, filter: { pair } }, cached)(),
      [botId, token, pair]
    );
  
  
    // const handleUpdateOrderState = useCallback(async ({ orderId, status }: any) => {
    //   console.log("APIExchangeSetOrderStatus", { botId, orderId, status })
    //   await APIExchangeSetOrderStatus({ botId })({ orderId, status });
    //   reload?.();
    // }, [])
  
  
    const handleCancelOrder = useCallback(async (orderId: string) => {
      console.log("APIExchangeCancelorder", { botId, orderId })
      await APIExchangeCancelorder({ botId })(orderId);
      reload?.();
    }, [botId])
  
  
    const filteredOrderBook = useMemo(
      () => orderBook
        ?.filter(e => e.pair == pair)
        ?.filter(e => e.status != "CANCELED" && e.status != "REJECTED" && e.status != "EXPIRED" && e.status != "FILLED") ?? [],
      [orderBook, pair]
    )
  
  
    return <>
      <TableContainer style={{ height: "100%", overflow: "auto", paddingBottom: "2em", }} >
        <Table >
          <TableHead style={{ whiteSpace: "nowrap", overflow: "auto", position: "relative", }}>
            <TableRow>
              <TableCell align="right">Pair</TableCell>
              <TableCell align="right">Side</TableCell>
              <TableCell align="right">Price</TableCell>
              <TableCell align="right">Amount</TableCell>
              <TableCell align="right">Executed</TableCell>
              {/* <TableCell align="right">Fill Avg</TableCell> */}
              <TableCell align="right">Status</TableCell>
              <TableCell align="right">Timestamp</TableCell>
              <TableCell align="right">Action</TableCell>
            </TableRow>
  
          </TableHead>
          <TableBody>
            {filteredOrderBook?.map(book => <TableRow
              key={book.id}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
              <TableCell align="right">{book.pair}</TableCell>
              <TableCell align="right" style={{ color: book.side == "BUY" ? "green" : "red" }}>{book.side}</TableCell>
              <TableCell align="right">{book.price}</TableCell>
              <TableCell align="right">{book.amount}</TableCell>
              <TableCell align="right">{book.executed}</TableCell>
              {/* <TableCell align="right">{book.fill_avg}</TableCell> */}
              <TableCell align="right">{book.status}</TableCell>
              <TableCell align="right">{formatTime(book?.timestamp)}</TableCell>
              <TableCell align="right">
                <Stack direction="row-reverse" gap={1}>
                  {["OPEN", "QUEUE"].includes(book.status) && <Chip label="Cancel" size="small" onClick={() => handleCancelOrder(book.id)} />}
  
                  {/* {book.status == "QUEUE" && <Chip label="Submited" size="small" onClick={() => handleUpdateOrderState({ orderId: book.id, status: "OPEN" })} />}
                    {book.status == "QUEUE" && <Chip label="Reject" size="small" onClick={() => handleUpdateOrderState({ orderId: book.id, status: "REJECTED" })} />}
                    {book.status == "OPEN" && <Chip label="Filled" size="small" onClick={() => handleUpdateOrderState({ orderId: book.id, status: "FILLED" })} />}
                    {book.status == "OPEN" && <Chip label="Expired" size="small" onClick={() => handleUpdateOrderState({ orderId: book.id, status: "EXPIRED" })} />}
                    {(book.status == "OPEN" || book.status == "QUEUE") && <Chip label="Cancel" size="small" onClick={() => handleUpdateOrderState({ orderId: book.id, status: "CANCELED" })} />} */}
                </Stack>
              </TableCell>
            </TableRow>)}
            {!(filteredOrderBook?.length > 0) && !(loadingCache) && <NoRecord />}
          </TableBody>
        </Table>
        <StatusPanel show={loading || loadingCache}>
          <CircularProgress size="0.9em" sx={{ px: "0.5em" }} />
          Fetching {loadingCache ? ' cached ' : ' latest '} data ...
        </StatusPanel>
      </TableContainer>
      {error && <FormHelperText error>{String(error)}</FormHelperText>}
    </>;
  }  
)

