import { Button, ButtonProps, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import { useToggle } from "react-use";

export const ButtonWithConfirm: React.FC<ButtonProps & { text?, title?, component?}> = ({ onClick = undefined, ['title']: title = "", ['text']: text = "", ...props }) => {

  const [showConfirm, toggleConfirm] = useToggle(false)

  const Component = props.component ?? Button

  return <>
    <Dialog open={showConfirm} onClose={toggleConfirm}>
      {title && <DialogTitle>{title}</DialogTitle>}
      <DialogContent>
        {text && <DialogContentText>{text}</DialogContentText>}
      </DialogContent>
      <DialogActions>
        <Button variant="text" color="inherit" sx={{ px: "1em" }} onClick={() => toggleConfirm(false)}>Cancel</Button>
        <Button variant="text" color="info" sx={{ px: "1em" }} onClick={(e) => { onClick?.(e); toggleConfirm(false) }}>Confirm</Button>
      </DialogActions>
    </Dialog>
    <Component {...props} onClick={toggleConfirm} />
  </>
}